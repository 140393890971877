import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
  Text,
  ModalBody,
  Stack,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface ConfirmActionmodalProps {
  onClose: () => void;
  isOpen: boolean;
  title: string | ReactNode;
  confirmationMessage: string | React.ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  onConfirmLabel?: string;
  onCancelLabel?: string;
}

const ConfirmActionModal: React.FC<ConfirmActionmodalProps> = ({
  isOpen,
  onClose,
  title,
  confirmationMessage,
  onConfirm = () => undefined,
  onCancel = () => undefined,
  onConfirmLabel = "Aceptar",
  onCancelLabel = "Cancelar",
}) => {
  const initialFocusRef = React.useRef();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialFocusRef}
      preserveScrollBarGap
      isCentered
      size={"xl"}
    >
      <ModalOverlay />
      <ModalContent
        backgroundColor={"backgroundColor"}
        borderColor={"secondary.main"}
        borderWidth={"3px"}
        borderRadius={"xl"}
        p={5}
        textAlign="center"
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          {typeof confirmationMessage === "string" ? (
            <Text fontSize={"small"}>{confirmationMessage}</Text>
          ) : (
            confirmationMessage
          )}
        </ModalBody>
        <ModalFooter
          alignItems={"center"}
          justifyContent="space-around"
          as={Stack}
          direction="row"
        >
          <Button
            rounded={"full"}
            colorScheme={"primary"}
            variant="outline"
            onClick={() => {
              onCancel();
              onClose();
            }}
            mr={3}
            size="lg"
            fontSize={"md"}
            textColor="white"
            textTransform={"uppercase"}
            borderColor={"primary.main"}
            w={{ md: "full", xs: "auto" }}
          >
            {onCancelLabel}
          </Button>
          <Button
            ref={initialFocusRef}
            w={{ md: "full", xs: "auto" }}
            rounded={"full"}
            colorScheme={"primary"}
            onClick={() => {
              onConfirm();
              onClose();
            }}
            size="lg"
            fontSize={"md"}
            textTransform={"uppercase"}
          >
            {onConfirmLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmActionModal;
