import { createIcon } from "@chakra-ui/icons";

export const DownloadIcon = createIcon({
  displayName: "DownloadIcon",
  viewBox: "0 0 30 30",
  path: (
    <path
      d="M2.5 15H5V21.25H25V15H27.5V21.25C27.5 22.6375 26.3875 23.75 25 23.75H5C3.625 23.75 2.5 22.6375 2.5 21.25V15ZM15 18.75L21.9375 11.925L20.1625 10.1625L16.25 14.0625V2.5H13.75V14.0625L9.85 10.1625L8.075 11.9375L15 18.75Z"
      fill="currentColor"
    />
  ),
});
