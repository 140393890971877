import {
  Box,
  Button,
  Center,
  Circle,
  Divider,
  Flex,
  IconButton,
  Stack,
  Tag,
  TagLabel,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { seatsKey } from "../../../../config/envConf";
import { priceFormatter } from "../../../../utils/priceFormat";
import { ticketLabelFormatter } from "../../../../utils/stringFormatters";
import { SeatsObject } from "../../../Landing/EventDetail/interfaces";
import { OfficeSaleOutletContext } from "../interfaces";
import { SaleStep } from "./step";
import { SeatsioSeatingChart } from "@seatsio/seatsio-react";
import { mapToCategoriesSummary } from "../../../Landing/EventDetail/DetailLayouts/SeatsLayout";
import { DeleteIcon } from "@chakra-ui/icons";
import { formatInTimeZone } from "date-fns-tz";
import { es } from "date-fns/locale";
import {
  capitalizeFirstLetter,
  TIME_ZONE,
  formateDateAndHour,
} from "../../../../utils/dateFormatters";
import { ShoppingCartDTO } from "../../../../services/shoppingSession/dto";
import { useToaster } from "../../../../utils/useToaster";
import { useColors } from "../../../../utils/useColors";

const TicketsSelection = () => {
  let totalAmount = 0;

  const { activeFunction, eventInfo, submitSale } =
    useOutletContext<OfficeSaleOutletContext>();

  const [chartRef, setChartRef] = useState(null);

  const { successToast, errorToast, warningToast } = useToaster();

  const [selectedSeats, setSelectedSeats] = useState<SeatsObject[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const chartKey: string | null = eventInfo?.functions.find(
    (func) => func._id === activeFunction.value
  )?.eventFunctionChartKey;

  const functionData = useMemo(
    () =>
      eventInfo?.functions.find((func) => func._id === activeFunction.value),
    [activeFunction, eventInfo]
  );

  const ticketVariants = useMemo(
    () => functionData?.ticketVariants,
    [functionData]
  );

  const mappedVariants = useMemo(
    () =>
      ticketVariants?.map((variant) => ({
        category: variant.name,
        price: variant.price,
      })),
    [ticketVariants]
  );

  const getTicketsLimits = () => {
    return ticketVariants
      .filter((variant) => variant.maxQuantityPerUser)
      .map((variant) => ({
        category: variant.name,
        quantity: variant.maxQuantityPerUser,
      }));
  };

  const mappedSelectedSeats = useMemo(
    () =>
      mappedVariants && mapToCategoriesSummary(selectedSeats, mappedVariants),
    [selectedSeats, mappedVariants]
  );

  const renderCategoriesResume = useCallback(() => {
    const categoriesToBudget = ticketVariants.filter((variant) => {
      const ticketsFromSelected = selectedSeats.filter(
        (seat) => seat.category.label === variant.name
      );
      return ticketsFromSelected.length > 0 && variant;
    });
    if (categoriesToBudget.length > 0) {
      return categoriesToBudget.map((category, index, array) => {
        const tickets = selectedSeats.filter(
          (seat) => seat.category.label === category.name
        );

        const isSeat = tickets[0]?.objectType === "Seat";

        const amountOfTickets = isSeat
          ? tickets.length
          : Number(tickets[0]?.numSelected);

        const subTotal = tickets[0]?.pricing?.price * amountOfTickets;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        totalAmount = totalAmount + subTotal;

        return (
          <Stack key={index} justify={"space-between"} direction="row" px={2}>
            <Text>{category.name}</Text>
            <Text>{amountOfTickets + " x $" + tickets[0]?.pricing?.price}</Text>
          </Stack>
        );
      });
    } else return null;
  }, [selectedSeats]);

  const handleSaveTickets = async (values: any) => {
    try {
      setIsLoading(true);
      const shoppingSessionObj: ShoppingCartDTO = {
        function: {
          id: functionData._id,
          date: functionData.datetime,
          name: eventInfo.name,
          eventFunctionChartKey: chartKey,
        },
        seats: [...selectedSeats].map((seat) => ({
          id: seat.id,
          ticketVariantId: seat.category.key,
          labels: { ...seat.labels, displayName: seat.labels.displayedLabel },
          objectType:
            seat.objectType === "Seat" ? "Seat" : "GeneralAdmissionArea",
          numSelected: Number(seat.numSelected),
        })),
      };

      await submitSale(shoppingSessionObj);
      successToast(
        "Sesión de compra creada",
        "Ingrese el código de transacción de compra para finalizar la venta."
      );
    } catch (error) {
      console.error(error);
      errorToast(
        "Ocurrió un error al crear la sesión de compra",
        "Vuelva a intentar nuevamente. Si persiste, comuniquese con soporte."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const colors = useColors();
  return (
    <SaleStep
      cardProps={{
        paddingX: 7,
        marginX: 12,
        maxW: "full",
        overflow: "auto",
      }}
    >
      {chartKey && mappedVariants && (
        <Stack spacing={7} direction={{ md: "row", sm: "column" }} w="full">
          <Stack w={{ md: "50%", sm: "auto" }}>
            <Stack direction={"column"} spacing={2}>
              <Text color={"primary.main"} fontWeight={600} fontSize={18}>
                {eventInfo.name}
              </Text>
              <Divider />
              <Stack direction={"column"} spacing={3}>
                <Text fontSize={14}>
                  {activeFunction &&
                    capitalizeFirstLetter(
                      formatInTimeZone(
                        functionData.datetime,
                        TIME_ZONE,
                        "EEEE",
                        {
                          locale: es,
                        }
                      )
                    ) +
                      " " +
                      formateDateAndHour(functionData.datetime)}
                </Text>
              </Stack>
              <Stack
                direction={"row"}
                overflow="auto"
                maxW="100%"
                sx={{
                  "&::-webkit-scrollbar": {
                    height: "5px",
                    borderRadius: "10px",
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "black",
                    borderRadius: "10px",
                  },
                }}
              >
                {ticketVariants &&
                  ticketVariants.map((variant, index) => (
                    <Tag
                      size={"md"}
                      key={index + "-variant"}
                      variant="ghost"
                      minW={"min-content"}
                      ml={-2}
                    >
                      <Circle size={3} backgroundColor={variant.color} />

                      <TagLabel ml={2}>{variant.name}</TagLabel>
                    </Tag>
                  ))}
              </Stack>
            </Stack>
            <SeatsioSeatingChart
              showSectionContents={"onlyAfterZoom"}
              onRenderStarted={(chart) => {
                setChartRef(chart);
              }}
              workspaceKey={seatsKey}
              event={chartKey}
              showMinimap={true}
              showFullScreenButton={false}
              stylePreset="bubblegum"
              language={"es"}
              colorScheme={"dark"}
              tooltipInfo={(seat) => {
                const description = ticketVariants.find(
                  (cat) => cat.name === seat?.category?.label
                );

                return description && `${description}`;
              }}
              maxSelectedObjects={[...getTicketsLimits(), { total: 6 }]}
              colors={{
                colorSelected: "hsl(214, 96%, 53%)",
                colorTitle: colors.primary.main,
              }}
              cursorTooltip={{
                showActionHint: false,
                showAvailability: false,
                showCategory: false,
                showLabel: true,
                showPricing: true,
                showUnavailableNotice: true,
                stylizedLabel: true,
              }}
              pricing={mappedVariants}
              priceFormatter={(price) => {
                return "$" + priceFormatter(price as number);
              }}
              onObjectSelected={(obj) => {
                setSelectedSeats((prev) => {
                  var copyPrev = [...prev];
                  if (
                    obj?.objectType === "GeneralAdmissionArea" &&
                    prev.find((items) => items.uuid === obj?.uuid)
                  ) {
                    copyPrev[
                      prev.findIndex((items) => items.uuid === obj?.uuid)
                    ].numSelected = obj.numSelected;
                    return [...copyPrev];
                  } else {
                    return [...prev, obj];
                  }
                });
              }}
              onObjectDeselected={(obj) => {
                if (obj?.status === "reservedByToken" && !isLoading) {
                  warningToast(
                    "Asiento deseleccionado",
                    "El asiento " +
                      ticketLabelFormatter(obj) +
                      " se quitó de tus selecciones por que ya fue adquirido por otra persona"
                  );
                }

                !isLoading &&
                  setSelectedSeats((prev) => {
                    var copyPrev = [...prev];
                    if (
                      obj?.numSelected > 0 &&
                      prev.find((items) => items.uuid === obj?.uuid)
                    ) {
                      copyPrev[
                        prev.findIndex((items) => items.uuid === obj?.uuid)
                      ].numSelected = obj.numSelected;
                      return [...copyPrev];
                    } else {
                      return prev.filter(
                        (element) => element.uuid !== obj.uuid
                      );
                    }
                  });
              }}
              region="sa"
            />
          </Stack>
          <Box
            w={{ md: "50%", sm: "full" }}
            position={["unset", "unset", "sticky"]}
            // TODO: Research WTF is this
            h={"prose"}
            overflowY={"auto"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "8px",
                borderRadius: "10px",
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: `secondary.main`,
                borderRadius: "10px",
              },
            }}
          >
            <Text fontSize={16} mb={5} fontWeight="semibold">
              Asientos seleccionados:
            </Text>
            {Object.keys(mappedSelectedSeats).length === 0 ? (
              <Text fontSize={14} color={"muted.500"}>
                Una vez que hayas seleccionados tus asientos, podrás visualizar
                el detalle de los mismos aquí.
              </Text>
            ) : (
              <VStack spacing={1} alignItems="left" flexGrow={1}>
                {Object.keys(mappedSelectedSeats).map((category, index) => {
                  const ticketsArray = mappedSelectedSeats[category];
                  return (
                    <Box key={index}>
                      <Flex
                        justifyContent={"space-between"}
                        borderWidth={1}
                        borderColor="secondary.contrast"
                        py={1}
                        px={2}
                        borderRadius={5}
                        direction={["column", "row"]}
                        alignItems={"center"}
                      >
                        <Stack direction={"row"}>
                          <Circle
                            size={5}
                            backgroundColor={
                              ticketVariants.find(
                                (cat) => cat.name === category
                              ).color ?? "primary.main"
                            }
                          />
                          <Text fontSize={14}>{category}</Text>
                        </Stack>
                        <Text>
                          {"$" + priceFormatter(ticketsArray[0].pricing.price)}
                        </Text>
                      </Flex>
                      {ticketsArray.map((ticket, index) => {
                        return (
                          <Flex
                            key={index}
                            justifyContent={"space-between"}
                            py={1}
                            px={2}
                            borderRadius={5}
                            direction={["column", "row"]}
                            alignItems={"center"}
                            fontSize={16}
                          >
                            <Text noOfLines={1}>
                              {ticketLabelFormatter(ticket)}
                            </Text>
                            <IconButton
                              aria-label="Delete"
                              icon={<DeleteIcon />}
                              variant="ghost"
                              colorScheme={"primary"}
                              rounded="full"
                              onClick={() => {
                                ticket.objectType === "Seat"
                                  ? chartRef.deselectObjects([ticket.uuid])
                                  : ticket.numSelected > 1
                                  ? chartRef.deselectObjects([
                                      {
                                        id: ticket.id,
                                        amount: 1,
                                      },
                                    ])
                                  : chartRef.deselectObjects([
                                      { id: ticket.id },
                                    ]);
                              }}
                            />
                          </Flex>
                        );
                      })}
                    </Box>
                  );
                })}
                <Stack spacing={2} direction={"column"} mt={2}>
                  <Divider />
                  <Text fontSize={18} color={"secondary.contrast"} px={2}>
                    Resumen de compra:
                  </Text>
                  <Stack
                    spacing={3}
                    pr={5}
                    maxH="10vh"
                    overflowY={"auto"}
                    sx={{
                      "&::-webkit-scrollbar": {
                        width: "8px",
                        borderRadius: "10px",
                        backgroundColor: `rgba(0, 0, 0, 0.05)`,
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: `secondary.main`,
                        borderRadius: "10px",
                      },
                    }}
                  >
                    {renderCategoriesResume()}
                  </Stack>
                  <Stack spacing={3} pr={5}>
                    {[
                      {
                        title: "Subtotal",
                        price: totalAmount,
                      },
                      {
                        title: "Cargo por servicio",
                        price: Math.round(
                          totalAmount *
                            (eventInfo.serviceCharge
                              ? eventInfo.serviceCharge
                              : 0.15)
                        ),
                      },
                    ].map((item, index) => (
                      <Flex
                        justifyContent={"space-between"}
                        key={"revision-" + index}
                        direction={["column", "row"]}
                        px={2}
                      >
                        <Text>{item.title}</Text>
                        <Text alignSelf={["flex-end", "auto"]}>
                          {"$" + priceFormatter(item.price)}
                        </Text>
                      </Flex>
                    ))}
                    <Flex
                      justifyContent={"space-between"}
                      borderWidth={1}
                      borderColor="secondary.contrast"
                      py={1}
                      px={2}
                      borderRadius={5}
                      direction={["column", "row"]}
                      alignItems={"center"}
                    >
                      <Text>TOTAL</Text>
                      <Text>
                        {"$" +
                          priceFormatter(
                            totalAmount +
                              Math.round(
                                totalAmount *
                                  (eventInfo.serviceCharge
                                    ? eventInfo.serviceCharge
                                    : 0.15)
                              )
                          )}
                      </Text>
                    </Flex>

                    <Center>
                      <Button
                        mt={2}
                        colorScheme={"primary"}
                        size="md"
                        borderRadius={50}
                        w="60%"
                        mx="auto"
                        isLoading={isLoading}
                        onClick={() => {
                          handleSaveTickets(selectedSeats);
                        }}
                      >
                        SIGUIENTE
                      </Button>
                    </Center>
                  </Stack>
                </Stack>
              </VStack>
            )}
          </Box>
        </Stack>
      )}
    </SaleStep>
  );
};

export default TicketsSelection;
