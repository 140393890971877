import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { emailModalData } from "../../../../config/constants";

const ModalEmailVerification = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [params, setParams] = useSearchParams();

  const [modalInfo, setModalInfo] = useState(null);

  useEffect(() => {
    if (params.get("emailVerified")) {
      const emailDataToSet = emailModalData.find(
        (data) => data.state === params.get("emailVerified")
      );

      setModalInfo(emailDataToSet ?? emailModalData.at(-1));
      setParams({});
      onOpen();
    }
  }, [params]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent
        borderRadius={20}
        backgroundColor={"backgroundColor"}
        borderWidth={2}
        borderColor={"secondary.main"}
        p={5}
      >
        <ModalHeader textAlign={"center"} noOfLines={1}>
          {modalInfo?.title}
        </ModalHeader>
        <ModalBody textAlign={"center"}>{modalInfo?.subtitle}</ModalBody>
        <ModalFooter>
          <Button colorScheme="primary" mr={3} onClick={onClose} margin="auto">
            CONTINUAR
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalEmailVerification;
