import {
  Stack,
  FormControl,
  Input,
  FormLabel,
  Text,
  Grid,
  GridItem,
  NumberInput,
  NumberInputField,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import { Field, FormikProps, FormikProvider } from "formik";
import { useCreateEventService } from "../../../../services/createEvent/createEventService";
// Import React FilePond
import { FilePond } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";

// css
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";

import { EventStep } from "./step";
import { useAsync } from "../../../../utils/useAsync";
import { useEffect, useMemo } from "react";
import { cleanRut, formatRut } from "../../../../utils/rutFormatter";
import { Select } from "chakra-react-select";
import { StyledSelectProps } from "../../../../theme/additions/select/Select";
import { EmailInput } from "../../../../components/EmailInput";
import { OrganizerFormFields } from "../../../../services/createEvent/createEventStore";
import { ValidationState } from "../../../../utils/useEmailValidator";

export type OrganizerFormProps = {
  formik: FormikProps<OrganizerFormFields>;
  validationState: ValidationState;
};

const accountTypes = [
  {
    value: "savings_account",
    label: "Cuenta Corriente",
  },
  {
    value: "vista",
    label: "Vista",
  },
  {
    value: "rut",
    label: "RUT",
  },
];

const OrganizerForm = ({ formik, validationState }: OrganizerFormProps) => {
  const formService = useCreateEventService();

  const { setFormData } = formService.store;

  const [banksData, , banksLoading, banksExecute] = useAsync(
    formService.getBanks,
    { defaultValue: [] }
  );

  useEffect(() => {
    banksExecute();
  }, []);

  // Disabled; resets input when stepping back
  useEffect(() => {
    setFormData({
      organizer: formik.values,
    });
  }, [formik.values]);

  const mappedBankData = useMemo(
    () =>
      banksData.map((bank) => ({
        label: bank.name,
        value: bank._id,
      })),
    [banksData]
  );

  return (
    <EventStep buttonProps={{ onClick: formik.submitForm }}>
      <FormikProvider value={formik}>
        <Stack spacing={[7, 9]} p={[5, 7]}>
          <Text fontSize={{ base: "md", md: "lg" }} w="85%">
            Ingresa los datos del organizador
          </Text>
          <Grid
            templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]}
            templateRows={"repeat(3, 1fr)"}
            gap={5}
          >
            <GridItem rowSpan={1} colSpan={1}>
              <Field name={"name"}>
                {({ field, form }) => (
                  <FormControl
                    variant="floating"
                    borderRadius={4}
                    id="name"
                    isRequired
                    isInvalid={form.errors.name}
                  >
                    <Input {...field} placeholder=" " maxLength={50} />
                    <FormLabel>Nombre de la productora</FormLabel>
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    <FormHelperText>
                      Los usuarios verán este nombre al momento de comprar las
                      entradas
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
            </GridItem>

            <GridItem rowSpan={1} colSpan={1}>
              <Field name={"mail"}>
                {({ field, form }) => (
                  <FormControl
                    variant="floating"
                    borderRadius={4}
                    id="mail"
                    isRequired
                    isInvalid={form.errors.mail}
                  >
                    <EmailInput
                      validationState={validationState}
                      field={field}
                      form={form}
                      label={"Mail"}
                      inputProps={{
                        maxLength: 150,
                      }}
                      inputGroupProps={{
                        borderRadius: 4,
                      }}
                    />

                    <FormErrorMessage>{form.errors.mail}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>
            <GridItem rowSpan={1} colSpan={1}>
              <Field name={"phone"}>
                {({ field, form }) => (
                  <FormControl
                    variant="floating"
                    borderRadius={4}
                    id="phone"
                    isRequired
                    isInvalid={form.errors.phone}
                  >
                    <NumberInput {...field}>
                      <NumberInputField
                        {...field}
                        placeholder=" "
                        maxLength={20}
                      />
                      <FormLabel>Telefono</FormLabel>
                    </NumberInput>
                    <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>
            <GridItem rowSpan={1} colSpan={1}>
              <Field name={"bank"}>
                {({ field, form }) => (
                  <FormControl
                    variant="floating"
                    borderRadius={4}
                    id="bank"
                    isRequired
                    isInvalid={form.errors.bank}
                  >
                    <Select
                      {...StyledSelectProps}
                      // isSearchable={false}
                      options={mappedBankData}
                      isLoading={banksLoading}
                      isDisabled={banksLoading}
                      placeholder=" "
                      noOptionsMessage={() => "No hay resultados"}
                      loadingMessage={() => "Cargando..."}
                      {...field}
                      value={mappedBankData.find(
                        (bank) => bank.value === field.value
                      )}
                      onChange={(value: any) => {
                        formik.setFieldValue(field.name, value.value);
                      }}
                    />

                    <FormLabel>Banco</FormLabel>
                    <FormErrorMessage>{form.errors.bank}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>
            <GridItem rowSpan={1} colSpan={1}>
              <Field name={"accountType"}>
                {({ field, form }) => (
                  <FormControl
                    variant="floating"
                    borderRadius={4}
                    id="accountType"
                    isRequired
                    isInvalid={form.errors.accountType}
                  >
                    <Select
                      {...StyledSelectProps}
                      placeholder=" "
                      isSearchable={false}
                      options={accountTypes}
                      {...field}
                      value={accountTypes.find(
                        (type) => type.value === field.value
                      )}
                      onChange={(value: any) => {
                        formik.setFieldValue(field.name, value.value);
                      }}
                    />
                    <FormLabel>Tipo de cuenta</FormLabel>
                    <FormErrorMessage>
                      {form.errors.accountType}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>
            <GridItem rowSpan={1} colSpan={1}>
              <Field name={"accountNumber"}>
                {({ field, form }) => (
                  <FormControl
                    variant="floating"
                    borderRadius={4}
                    id="accountNumber"
                    isRequired
                    isInvalid={form.errors.accountNumber}
                  >
                    <Input
                      {...field}
                      onChange={(e) => {
                        if (formik.values.accountType === "rut") {
                          formik.setFieldValue(
                            field.name,
                            formatRut(cleanRut(e.target.value))
                          );
                        } else {
                          formik.setFieldValue(field.name, e.target.value);
                        }
                      }}
                      placeholder=" "
                      maxLength={formik?.values.accountType === "rut" ? 12 : 30}
                    />
                    <FormLabel>Numero de cuenta</FormLabel>
                    <FormErrorMessage>
                      {form.errors.accountNumber}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>
            <GridItem colSpan={2} rowSpan={1}>
              <FormControl variant="outline" borderRadius={4} id="exemptionPDF">
                <FormLabel fontSize={"sm"}>
                  <strong>Exención de impuestos</strong> (Si no posee exención,
                  saltee este paso y continúe con la creación de su evento)
                </FormLabel>
                <FilePond
                  id="exemptionPDF"
                  name="tax.exemption.pdf"
                  key="exemptionPDF"
                  files={
                    formik.values.exemptionPDF
                      ? [formik.values.exemptionPDF.file]
                      : []
                  }
                  server={{
                    process: async (
                      fieldName,
                      file,
                      metadata,
                      load,
                      error,
                      progress,
                      abort,
                      transfer,
                      options
                    ) => {
                      try {
                        const image = await formService.postPDF(file);
                        formik.setFieldValue("exemptionPDF", {
                          ...image,
                          file,
                        });
                        load(image.url);
                      } catch (e) {
                        error("Ocurrió un error");
                      }
                      /* store file somewhere and call `load` when done */
                    },
                  }}
                  instantUpload
                  labelMaxFileSizeExceeded="Archivo demasiado grande"
                  labelMaxFileSize="Tamaño máximo: 3MB"
                  allowImagePreview={false}
                  acceptedFileTypes={["application/pdf"]}
                  onupdatefiles={(images) => {}}
                  labelIdle='Arrastre o<span class="filepond--label-action"> seleccione el archivo desde su equipo</span>'
                  labelFileLoading="Cargando"
                  labelFileProcessing="Procesando"
                  labelFileProcessingComplete="Listo!"
                  labelFileProcessingError="Error"
                  labelTapToCancel="Cancelar"
                  labelTapToRetry="Reintentar"
                  labelTapToUndo="Deshacer"
                  labelButtonRemoveItem="Eliminar"
                  labelFileLoadError={"Error al cargar el archivo"}
                  allowFileTypeValidation={true}
                />
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </FormikProvider>
    </EventStep>
  );
};

export default OrganizerForm;
