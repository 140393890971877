export const CalendarStyles = {
  components: {
    Calendar: {
      parts: ["calendar"],

      baseStyle: {
        calendar: {
          borderWidth: "0",
          rounded: "none",
          shadow: "none",
        },
      },
    },
  },
};
