import {
  Box,
  Button,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import LogOutmodal from "../../../components/LogOutModal";

import { ProfileInfo } from "./pages/profileInfo";
import { ResetPassword } from "./pages/resetPassword";
import { TicketList } from "./pages/ticketList";
import { ProfileProps, profileScreens } from "./interfaces";
import { useProfileController } from "./profile.controller";

export const Profile: React.FC<ProfileProps> = ({
  useController = useProfileController,
}) => {
  const controller = useController();

  const isMobile = useBreakpointValue<boolean>({
    base: true,
    md: false,
  });

  return (
    <Box>
      <Tabs
        variant="unstyled"
        index={controller.tabIndex}
        onChange={controller.handleTabsChange}
      >
        <Box
          display={"flex"}
          flexDir={"row"}
          maxW={"90vw"}
          pt={20}
          justifyContent={"center"}
          margin={"auto"}
        >
          {!isMobile && (
            <TabList
              overflow={"hidden"}
              w={"md"}
              borderColor={"secondary.main"}
              borderWidth={"3px"}
              borderRadius={"xl"}
              mr={3}
              bgColor={"backgroundColor"}
              color={"backgroundContrast"}
              flexDir={"column"}
              height={"max-content"}
            >
              <Text
                borderBottomWidth={"1px"}
                borderBottomColor={"muted.main"}
                textAlign={"center"}
                fontWeight={600}
                py={6}
              >
                {controller.profileName}
              </Text>
              {profileScreens.map((profileScreen, index) => (
                <Tab
                  key={index}
                  py={4}
                  borderBottomWidth={"1px"}
                  borderBottomColor={"muted.main"}
                  _hover={{ bg: "muted.200" }}
                  _selected={{ color: "primary.contrast", bg: "primary.main" }}
                >
                  <Text w={"100%"} textAlign="left">
                    {profileScreen.label}
                  </Text>
                </Tab>
              ))}
              <Button
                onClick={controller.onLogOutOpen}
                bgColor={"backgroundColor"}
                _hover={{ bgColor: "muted.200" }}
                textAlign={"left"}
                py="7"
                borderTopRadius={0}
                borderBottomColor={"muted.main"}
              >
                <Text w={"100%"} textAlign="left">
                  Salir
                </Text>
              </Button>
            </TabList>
          )}
          <Box width={"100%"} maxW={"4xl"} ml={3}>
            <Box height={0} maxH={0} pos="absolute">
              <Input
                //SUPER FIX - prevents autofill on chrome
                type={"text"}
                height={0}
                borderWidth={0}
              />
              <Input
                //SUPER FIX - prevents autofill on chrome
                type={"password"}
                height={0}
                borderWidth={0}
              />
            </Box>
            <TabPanels h={"full"}>
              <TabPanel p={0} h={"full"}>
                <TicketList />
              </TabPanel>
              <TabPanel p={0}>
                <ProfileInfo />
              </TabPanel>
              <TabPanel p={0}>
                <ResetPassword />
              </TabPanel>
            </TabPanels>
          </Box>
        </Box>
      </Tabs>
      <LogOutmodal
        isOpen={controller.isLogOutOpen}
        onClose={controller.onLogOutClose}
      />
    </Box>
  );
};
