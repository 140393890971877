import React, { useState } from "react";
import {
  Text,
  Image,
  Stack,
  Button,
  Center,
  Skeleton,
  Box,
  Tag,
  TagLabel,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import { EventDTO } from "../../../../services/events/dto";
import { es } from "date-fns/locale";
import { NavigateFunction } from "react-router-dom";
import isValidUrl from "../../../../utils/isValidURL";
import { ticketRejected } from "../../../../resources";
import { EventsStatusesDict } from "../../../../utils/eventsStatus";
import { formatInTimeZone } from "date-fns-tz";
import { TIME_ZONE } from "../../../../utils/dateFormatters";
import { colors } from "../../../../theme/colors";
import { SettingsIcon } from "../../../../components/Icons/SettingsIcon";
import { CopyIcon, EditIcon } from "@chakra-ui/icons";
import { EditCustomIcon } from "../../../../components/Icons/EditIcon";

interface EventListCardProps {
  event: EventDTO;
  navigate: NavigateFunction;
  loading: boolean;
  onDuplicatePress: () => Promise<void>;
}

const EventListCard: React.FC<EventListCardProps> = ({
  event,
  navigate,
  loading,
  onDuplicatePress,
}) => {
  const EventStatus =
    EventsStatusesDict[event.state] ?? EventsStatusesDict["Approved"];

  const [duplicateLoading, setDuplicateLoading] = useState(false);

  const handleDuplicatePress = async () => {
    setDuplicateLoading(true);
    await onDuplicatePress();
    setDuplicateLoading(false);
  };
  return (
    <Box
      bgColor={colors.muted[800]}
      color={"secondary.contrast"}
      width={"100%"}
      height={"max-content"}
      borderRadius={"xl"}
      overflow={"hidden"}
      display={"flex"}
      flexDirection={{ base: "column", md: "row" }}
    >
      <Box w={{ base: "100%", md: "xs" }}>
        <Skeleton
          startColor="secondary.800"
          endColor="secondary.700"
          width={"100%"}
          height={"100%"}
          style={{ aspectRatio: 2.2 }}
          isLoaded={!loading}
        >
          <Image
            onClick={() => navigate(event._id + "/info/admin")}
            cursor="pointer"
            src={
              isValidUrl(event.images.card.url)
                ? event.images.card.url
                : ticketRejected
            }
            fallback={<Box height={"100%"} width={"100%"}></Box>}
            overflow={"hidden"}
            width={"100%"}
            height={"100%"}
            borderLeftRadius={[0, 0, "xl"]}
            style={{ aspectRatio: 2.2 }}
            borderTopRadius={["xl", "xl", 0]}
          />
        </Skeleton>
      </Box>

      <Stack
        onClick={() => navigate(event._id + "/info/admin")}
        cursor="pointer"
        direction={"column"}
        spacing={1}
        pl="2%"
        py={5}
        w={{ base: "full", md: "20%" }}
        flexGrow={1}
        justifyContent="center"
      >
        <Skeleton borderRadius={20} isLoaded={!loading}>
          <Text noOfLines={1} fontSize={16}>
            {event.name}
          </Text>
        </Skeleton>
        <Skeleton borderRadius={20} isLoaded={!loading}>
          <Text noOfLines={1} fontSize={20}>
            {formatInTimeZone(
              new Date(event.functions[0].datetime),
              TIME_ZONE,
              "PPP",
              {
                locale: es,
              }
            )}
          </Text>
        </Skeleton>
        <Skeleton borderRadius={20} isLoaded={!loading}>
          <Text noOfLines={1} fontSize={14}>
            {event.placeName}
          </Text>
        </Skeleton>
        <Skeleton borderRadius={20} isLoaded={!loading}>
          <Tag
            mt={4}
            size={"md"}
            variant="subtle"
            rounded={"full"}
            px={4}
            py={2}
            colorScheme={EventStatus.colorScheme}
          >
            <TagLabel>{EventStatus.label}</TagLabel>
          </Tag>
        </Skeleton>
      </Stack>
      <Stack p={5} direction="column" pr={[5, 5, "3%"]} w={"20%"}>
        <Center h="50%">
          <Skeleton borderRadius={20} isLoaded={!loading} w="full">
            <Button
              variant={"unstyled"}
              w="full"
              pt={1}
              pl={8}
              size="sm"
              h={"2.5rem"}
              overflow="hidden"
              onClick={() => navigate(event._id + "/info/admin")}
              textAlign={"left"}
              fontSize={14}
            >
              <SettingsIcon
                fontSize={24}
                position="absolute"
                left={2}
                bottom={1}
              />
              Administrar
            </Button>
          </Skeleton>
        </Center>
        <Center h="50%">
          <Skeleton borderRadius={20} isLoaded={!loading} w="full">
            <Button
              variant={"unstyled"}
              w="full"
              pt={1}
              pl={8}
              size="sm"
              h={"2.5rem"}
              overflow="hidden"
              onClick={handleDuplicatePress}
              textAlign={"left"}
              fontSize={14}
            >
              {duplicateLoading ? (
                <Spinner
                  w={5}
                  h={5}
                  position="absolute"
                  left={2}
                  bottom={2.5}
                />
              ) : (
                <CopyIcon
                  fontSize={18}
                  position="absolute"
                  left={2}
                  bottom={2.5}
                />
              )}
              Duplicar
            </Button>
          </Skeleton>
        </Center>
        <Center h="50%">
          <Skeleton borderRadius={20} isLoaded={!loading} w="full">
            <Button
              variant="unstyled"
              w="full"
              pt={1}
              pl={8}
              h={"2.5rem"}
              size="sm"
              position="relative"
              onClick={() => navigate(event._id + "/info/admin")}
              textAlign={"left"}
              fontSize={14}
            >
              <EditCustomIcon
                fontSize={18}
                position="absolute"
                left={2}
                bottom={2.5}
              />
              Editar
            </Button>
          </Skeleton>
        </Center>
      </Stack>
    </Box>
  );
};

export default EventListCard;
