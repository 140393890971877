import {
  Box,
  Button,
  Center,
  Circle,
  Divider,
  Flex,
  IconButton,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { DeleteIcon } from "../../../../components/Icons/DeleteIcon";

import { priceFormatter } from "../../../../utils/priceFormat";
import { ticketLabelFormatter } from "../../../../utils/stringFormatters";
import { EventDetailController, SeatsTicketProps } from "../interfaces";

export interface CartSummaryProps {
  functionName: string;
  loading?: boolean;
  disabled?: boolean;
  isExempt: boolean;
  tickets: { name: string; price: number; quantity: number }[];
  onPurchasePress: (data?: any) => void;
  purchaseLoading?: boolean;
  purchaseError?: string;
  serviceCharge?: number;
}

const parseErrorMessage = (message: string) => {
  if (!message) {
    return "";
  }
  switch (message) {
    case "No tickets available":
      return "No hay tickets disponibles";

    case "Bad amount tickets":
      return "Cantidad inválidad de tickets";

    case "ticketVariant not found":
      return "Ticket no encontrado";

    case "Invalid date range":
      return "Este ticket no está disponible en esta fecha";

    case "Max quantity tickets":
      return "Cantidad máxima de tickets excedida";
    case "Se excedio la cantidad de tickets por tipo de tickets posible":
      return "Se excedio la cantidad de tickets por tipo de tickets posible";
    default:
      return "Ocurrió un error";
  }
};

export const CartSummary: React.FC<CartSummaryProps> = (props) => {
  
  const subtotal =
    (props.tickets &&
      props.tickets.reduce((prev, ticket) => {
        return prev + ticket.price * ticket.quantity;
      }, 0)) ||
    0;

  const amounts = [
    {
      title: "Subtotal",
      price: subtotal,
    },
    {
      title: "Cargo por servicio",
      price: Math.round(
        subtotal * (props.serviceCharge ? props.serviceCharge : 0.15)
      ),
    },
  ];

  return (
    <Stack
      direction={"column"}
      w={{ base: "100%", md: "100%" }}
      borderColor="secondary.main"
      borderWidth={5}
      borderRadius={20}
      h={"100%"}
      justifyContent="space-between"
      p={5}
      pos="relative"
      spacing={5}
    >
      {props.loading ? (
        <Box
          display={"flex"}
          alignItems="center"
          height={"100%"}
          justifyContent={"center"}
          w="100%"
        >
          <Spinner margin={"auto"} color="backgroundContrast" />
        </Box>
      ) : (
        <>
          <Box overflow={"auto"}>
            <Text fontSize={20} color={"primary.main"}>
              {props.functionName}
            </Text>
            <VStack spacing={4} alignItems="left" mt={5}>
              {props.tickets.map(
                (ticket, index) =>
                  ticket.quantity > 0 && (
                    <Flex
                      justifyContent={"space-between"}
                      key={"resumeItem-" + index}
                      direction={["column", "row"]}
                    >
                      <Text fontSize={20} color={"backgroundContrast"}>
                        {ticket.name}
                      </Text>
                      <Box alignSelf={["flex-end", "auto"]}>
                        <Text fontSize={20} color={"backgroundContrast"}>{`${
                          ticket.quantity
                        } x $${priceFormatter(ticket.price)}`}</Text>
                      </Box>
                    </Flex>
                  )
              )}
              {amounts.map((item, index) => (
                <Flex
                  justifyContent={"space-between"}
                  key={"revision-" + index}
                  direction={["column", "row"]}
                >
                  <Text fontSize={20} color={"backgroundContrast"}>
                    {item.title}
                  </Text>
                  <Text
                    alignSelf={["flex-end", "auto"]}
                    fontSize={20}
                    color={"backgroundContrast"}
                  >
                    {"$" + priceFormatter(item.price)}
                  </Text>
                </Flex>
              ))}
            </VStack>
          </Box>
          <Stack spacing={5}>
            <Flex
              justifyContent={"space-between"}
              borderWidth={1}
              borderColor={"backgroundContrast"}
              py={1}
              px={2}
              borderRadius={5}
              direction={["column", "row"]}
              alignItems={"center"}
            >
              <Text fontSize={20} color={"backgroundContrast"}>
                TOTAL
              </Text>
              <Text fontSize={20} color={"backgroundContrast"}>
                {"$" +
                  priceFormatter(
                    subtotal +
                      Math.round(
                        subtotal *
                          (props.serviceCharge ? props.serviceCharge : 0.15)
                      )
                  )}
              </Text>
            </Flex>

            <Center>
              <Button
                colorScheme={"primary"}
                size="md"
                borderRadius={50}
                w="60%"
                mx="auto"
                onClick={props.onPurchasePress}
                isLoading={props.purchaseLoading}
                isDisabled={props.disabled}
              >
                COMPRAR
              </Button>
            </Center>
            <Text textAlign={"center"} color={"red.200"}>
              {parseErrorMessage(props.purchaseError)}
            </Text>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export interface SeatsCartSummaryProps {
  controller: EventDetailController;
  categoriesToBudget: {
    [key: string]: SeatsTicketProps[];
  };
  functionCategories?: {
    id: string;
    name: string;
    price: string;
    color?: string;
    description?: string;
    maxQuantityPerUser?: number;
    minimumQuantityPerUser?: number;
  }[];
  chartInstance: any;
  isExempt: boolean;
  serviceCharge?: number;
}

export const SeatsCartSummary: React.FC<SeatsCartSummaryProps> = (props) => {
  let totalAmount = 0;

  const renderCategoriesResume = useCallback(() => {
    if (Object.keys(props.categoriesToBudget).length > 0) {
      return Object.keys(props.categoriesToBudget).map(
        (category, index, array) => {
          const tickets = props.categoriesToBudget[category];
          const isSeat = tickets[0].objectType === "Seat";

          const amountOfTickets = isSeat
            ? tickets.length
            : tickets[0].numSelected;

          const subTotal = tickets[0].pricing.price * amountOfTickets;
          // eslint-disable-next-line react-hooks/exhaustive-deps
          totalAmount = totalAmount + subTotal;

          return (
            <Stack key={index} justify={"space-between"} direction="row" px={2}>
              <Text color={"backgroundContrast"}>{category}</Text>
              <Text color={"backgroundContrast"}>
                {amountOfTickets + " x $" + tickets[0].pricing.price}
              </Text>
            </Stack>
          );
        }
      );
    } else return null;
  }, [props.categoriesToBudget]);

  return (
    <Box
      h={"100%"}
      maxH={"full"}
      display={"flex"}
      flexDirection={"column"}
      w={{ base: "100%", md: "100%" }}
      borderColor="secondary.main"
      borderWidth={5}
      borderRadius={20}
      justifyContent="space-between"
      p={5}
      pos="relative"
    >
      <Text
        mb={3}
        fontSize={16}
        color={"backgroundContrast"}
        fontWeight="semibold"
      >
        Asientos reservados:
      </Text>
      <Box
        overflowY={"auto"}
        h="100%"
        sx={{
          "&::-webkit-scrollbar": {
            width: "8px",
            borderRadius: "10px",
            backgroundColor: `rgba(0, 0, 0, 0.05)`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: `secondary.main`,
            borderRadius: "10px",
          },
        }}
      >
        {Object.keys(props.categoriesToBudget).length === 0 ? (
          <Text fontSize={14} color={"muted.main"}>
            Una vez que hayas seleccionados tus asientos, podrás visualizar el
            detalle de los mismos aquí.
          </Text>
        ) : (
          <VStack spacing={1} alignItems="left" flexGrow={1} pr={3}>
            {Object.keys(props.categoriesToBudget).map((category, index) => {
              const ticketsArray = props.categoriesToBudget[category];
              return (
                <Box key={index}>
                  <Flex
                    justifyContent={"space-between"}
                    borderWidth={1}
                    borderColor="white"
                    py={1}
                    px={2}
                    borderRadius={5}
                    direction={["column", "row"]}
                    alignItems={"center"}
                  >
                    <Stack direction={"row"}>
                      <Circle
                        size={5}
                        backgroundColor={
                          props.functionCategories.find(
                            (cat) => cat.name === category
                          ).color ?? "primary.main"
                        }
                      />
                      <Text color={"backgroundContrast"} fontSize={14}>
                        {category}
                      </Text>
                    </Stack>
                    <Text color={"backgroundContrast"}>
                      {"$" + priceFormatter(ticketsArray[0].pricing.price)}
                    </Text>
                  </Flex>
                  {ticketsArray.map((ticket, index) => {
                    return (
                      <Flex
                        key={index}
                        justifyContent={"space-between"}
                        py={1}
                        px={2}
                        borderRadius={5}
                        direction={["column", "row"]}
                        alignItems={"center"}
                        fontSize={16}
                      >
                        <Text noOfLines={1} color={"backgroundContrast"}>
                          {ticketLabelFormatter(ticket)}
                        </Text>
                        <IconButton
                          aria-label="Delete"
                          icon={<DeleteIcon />}
                          variant="ghost"
                          colorScheme={"primary"}
                          rounded="full"
                          onClick={() => {
                            ticket.objectType === "Seat"
                              ? props.chartInstance.deselectObjects([
                                  ticket.uuid,
                                ])
                              : ticket.numSelected > 1
                              ? props.chartInstance.deselectObjects([
                                  {
                                    id: ticket.id,
                                    amount: 1,
                                  },
                                ])
                              : props.chartInstance.deselectObjects([
                                  { id: ticket.id },
                                ]);
                          }}
                        />
                      </Flex>
                    );
                  })}
                </Box>
              );
            })}
          </VStack>
        )}
      </Box>
      <Stack spacing={2} direction={"column"} mt={2}>
        <Divider />
        <Text color={"backgroundContrast"} fontSize={18} px={2}>
          Resumen de compra:
        </Text>
        <Stack
          spacing={3}
          pr={5}
          maxH="10vh"
          overflowY={"auto"}
          sx={{
            "&::-webkit-scrollbar": {
              width: "8px",
              borderRadius: "10px",
              backgroundColor: `rgba(0, 0, 0, 0.05)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `secondary.main`,
              borderRadius: "10px",
            },
          }}
        >
          {renderCategoriesResume()}
        </Stack>
        <Stack spacing={3} pr={5}>
          {[
            {
              title: "Subtotal",
              price: totalAmount,
            },
            {
              title: "Cargo por servicio",
              price: Math.round(
                totalAmount * (props.serviceCharge ? props.serviceCharge : 0.15)
              ),
            },
          ].map((item, index) => (
            <Flex
              justifyContent={"space-between"}
              key={"revision-" + index}
              direction={["column", "row"]}
              px={2}
            >
              <Text color={"backgroundContrast"}>{item.title}</Text>
              <Text
                color={"backgroundContrast"}
                alignSelf={["flex-end", "auto"]}
              >
                {"$" + priceFormatter(item.price)}
              </Text>
            </Flex>
          ))}
          <Flex
            justifyContent={"space-between"}
            borderWidth={1}
            borderColor="white"
            py={1}
            px={2}
            borderRadius={5}
            direction={["column", "row"]}
            alignItems={"center"}
          >
            <Text color={"backgroundContrast"}>TOTAL</Text>
            <Text color={"backgroundContrast"}>
              {"$" +
                priceFormatter(
                  totalAmount +
                    Math.round(
                      totalAmount *
                        (props.serviceCharge ? props.serviceCharge : 0.15)
                    )
                )}
            </Text>
          </Flex>

          <Center>
            <Button
              mt={2}
              colorScheme={"primary"}
              size="md"
              borderRadius={50}
              w="60%"
              mx="auto"
              isDisabled={Object.keys(props.categoriesToBudget).length === 0}
              isLoading={props.controller.cartSummary.purchaseLoading}
              onClick={() => {
                props.controller.cartSummary.onPurchasePress(
                  props.categoriesToBudget
                );
              }}
            >
              COMPRAR
            </Button>
          </Center>
        </Stack>
      </Stack>
    </Box>
  );
};
