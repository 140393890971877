import React, { useEffect } from "react";
import { EventAdminInfoProps } from "./interfaces";
import { useEventAdminInfoController } from "./event-admin-info.controller";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Stack,
  SimpleGrid,
  Text,
  Spinner,
  Box,
  HStack,
  IconButton,
  VStack,
  Button,
  Flex,
} from "@chakra-ui/react";
import { Outlet, useNavigate } from "react-router-dom";
import { Select } from "chakra-react-select";
import { StyledSelectProps } from "../../../theme/additions/select/Select";
import { AdminRoutes } from "../../../config/routes";
import NavigationItem from "./components/NavigationItem";

export const EventAdminInfo: React.FC<EventAdminInfoProps> = ({
  useController = useEventAdminInfoController,
}) => {
  const controller = useController();

  const navigation = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [navigation]);

  return (
    <Stack
      px={{ sm: "2%", md: "10%" }}
      mx="2%"
      py="1%"
      direction="column"
      spacing={5}
      overflow={"auto"}
    >
      <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={10} w="full">
        <Stack direction={["column", "row"]} w="full">
          {controller.eventLoading ? (
            <Spinner alignSelf={"center"} my={5} />
          ) : (
            <HStack spacing={3}>
              <IconButton
                aria-label="Call Segun"
                mt="0.2rem"
                size="xs"
                bgColor={"muted.main"}
                rounded={"full"}
                alignSelf="self-start"
                icon={<ArrowBackIcon color="black" boxSize={"1.3rem"} />}
                onClick={() => navigation("/admin")}
              />
              <VStack alignItems={"start"} spacing={1}>
                <Text fontSize={"xl"} fontWeight="medium" noOfLines={1}>
                  {controller.eventInfo?.name}
                </Text>
                <Text fontSize={"sm"} noOfLines={1}>
                  {controller.eventInfo?.placeName}
                </Text>
              </VStack>
            </HStack>
          )}
        </Stack>
        <Box w="full">
          <Select
            defaultValue={controller.eventFunctions}
            placeholder={"Seleccione la función"}
            isDisabled={controller.eventLoading}
            isLoading={controller.eventLoading}
            value={controller.activeFunction}
            onChange={controller.onFunctionSelect}
            {...StyledSelectProps}
            chakraStyles={{
              ...StyledSelectProps.chakraStyles,
              control: (provided) => ({
                ...provided,
                borderColor: "muted.500",
                borderWidth: 1,
                color: "muted.500",
                borderRadius: 4,
              }),
            }}
            options={controller.eventFunctions}
          />
        </Box>
        <Flex justifyContent={"center"} w="full">
          <Button
            variant={"outline"}
            colorScheme="primary"
            rounded={"full"}
            w={{ sm: "100%", md: "60%" }}
            isLoading={controller.reportGenerating}
            onClick={controller.generateReport}
            isDisabled={controller.activeFunction.value === "all"}
          >
            Generar reporte
          </Button>
        </Flex>
      </SimpleGrid>
      <Stack
        direction={"row"}
        bgColor={"secondary.main"}
        borderRadius={20}
        justifyContent={"space-between"}
        color={"secondary.contrast"}
        overflowX="auto"
      >
        {AdminRoutes.map((route, index) => (
          <NavigationItem
            key={"navItem-" + index}
            route={route}
            navigation={navigation}
            isActive={controller.currentInfoSection === route.path}
            eventID={controller.currentEventId}
          />
        ))}
      </Stack>

      <Outlet
        context={{
          activeFunction: controller.activeFunction,
          eventInfo: controller.eventInfo,
        }}
      />
    </Stack>
  );
};
