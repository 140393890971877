import { Box, Button, Flex, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import React, { useRef } from "react";
import Card from "../../../../components/Card";
import { StatsProps } from "./interfaces";
import { useStatsController } from "./stats.controller";
import { Chart as ChartJS } from "chart.js/auto";
import { ArcElement, Tooltip, Legend } from "chart.js";
import { Chart } from "react-chartjs-2";
import CustomTable from "../../../../components/Table";
import StatCard from "../components/StatCard";
import "./styles.css";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import { useColors } from "../../../../utils/useColors";
ChartJS.register(ArcElement, Tooltip, Legend);

const columns = [
  {
    Header: "Tickets",
    accessor: "name",
    style: {
      color: "primary.main",
    },
    isCentered: true,
  },
  {
    Header: "Stock",
    accessor: "stock",
    isCentered: true,
  },
  {
    Header: "Vendidos",
    accessor: "sold",
    isCentered: true,
  },
  {
    Header: "Reservados",
    accessor: "reserved",
    isCentered: true,
  },
  {
    Header: "Disponibles",
    accessor: "availables",
    isCentered: true,
  },
  {
    Header: "Cortesías",
    accessor: "invitations",
    isCentered: true,
  },
];

export const EventStats: React.FC<StatsProps> = ({
  useController = useStatsController,
}) => {
  const controller = useController();
  const sellsChartRef = useRef(null);

  const colors = useColors();

  return (
    <Stack spacing={5} color={"secondary.contrast"}>
      <Card>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignContent={"center"}
        >
          <Text my="auto" fontSize={"lg"}>
            Venta en boletería
          </Text>
          <Button
            colorScheme={"primary"}
            variant="ghost"
            rightIcon={
              <FiArrowRight color={colors.primary.main} size="1.2rem" />
            }
            as={Link}
            to={`/admin/${controller.eventId}/sale`}
            disabled={!controller.eventInfo?.hasSeats}
          >
            Ir a Venta
          </Button>
        </Stack>
      </Card>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px">
        <StatCard {...controller.soldTickets} />
        <StatCard {...controller.totalAttendants} />
        <StatCard {...controller.dailySales} />
        {/* <StatCard {...controller.totalSales} /> */}
        <StatCard {...controller.fixedTotalSales} />
      </SimpleGrid>
      <Flex flexDirection={["column", "row"]} gap={7}>
        <Card>
          <Stack spacing={4} maxW="full">
            <Text textAlign={"center"}>Tipo de Tickets vendidos</Text>
            {controller.ticketSellingTypeData.datasets[0].data.some(
              (value) => value > 0
            ) ? (
              <Stack
                direction={"row"}
                justifyContent={"space-evenly"}
                maxW="full"
              >
                <Chart
                  type="pie"
                  ref={sellsChartRef}
                  id="sellsChart"
                  data={controller.ticketSellingTypeData}
                  options={{
                    borderColor: "transparent",
                    responsive: true,
                    aspectRatio: 7 / 3,
                    plugins: {
                      legend: {
                        position: "right",
                        align: "center",
                        display: true,
                        labels: {
                          usePointStyle: true,
                          boxWidth: 20,
                          color: colors.secondary.contrast,
                        },
                      },
                    },
                  }}
                />
              </Stack>
            ) : (
              <>
                <Box color={"muted.500"} pt={20} pb={20} textAlign="center">
                  Todavia no hay ventas
                </Box>
              </>
            )}
          </Stack>
        </Card>

        <Card>
          <Stack spacing={4}>
            <Text textAlign={"center"}>Tipos de pago</Text>
            {controller.paymentMethodsData.datasets[0].data.some(
              (value) => value > 0
            ) ? (
              <Chart
                type="bar"
                id="paymentsChart"
                data={controller.paymentMethodsData}
                options={{
                  borderColor: "transparent",
                  responsive: true,
                  aspectRatio: 7 / 3,
                  scales: {
                    y: {
                      ticks: { color: colors.secondary.contrast },
                      grid: {
                        color: colors.muted[500],
                      },
                    },
                    x: {
                      ticks: { color: colors.secondary.contrast },
                      grid: {
                        color: colors.muted[500],
                      },
                    },
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            ) : (
              <>
                <Box color={"muted.500"} pt={20} pb={20} textAlign="center">
                  Todavia no hay ventas
                </Box>
                <Text></Text>
              </>
            )}
          </Stack>
        </Card>
      </Flex>
      <Card py={7}>
        <CustomTable
          columns={columns}
          data={controller.ticketSalesTable.data}
          loading={controller.ticketSalesTable.loading}
          title="Disponibilidad de Tickets"
          searchProps={{
            enabled: false,
          }}
          paginatorOptions={{
            enabled: false,
            type: "advanced",
          }}
        />
      </Card>
    </Stack>
  );
};
