import { ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import AuthProvider from "./providers/authContext";
import { AxiosProvider } from "./providers/axiosContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleClientId } from "./config/envConf";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <div>
    <ColorModeScript initialColorMode="dark" />
    <AuthProvider>
      <AxiosProvider>
        <GoogleOAuthProvider clientId={googleClientId}>
          <App />
        </GoogleOAuthProvider>
      </AxiosProvider>
    </AuthProvider>
  </div>
);
