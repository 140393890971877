import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Input,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { Field, FormikProvider, useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { EmailInput } from "../../../../../components/EmailInput";
import { useInvitationsService } from "../../../../../services/Admin/invitations/invitations.service";
import { useReservationsService } from "../../../../../services/Admin/reservations/reservations.service";
import { TicketVariantDTO } from "../../../../../services/events/dto";
import { StyledSelectProps } from "../../../../../theme/additions/select/Select";
import { useAsync } from "../../../../../utils/useAsync";
import { useEmailValidator } from "../../../../../utils/useEmailValidator";
import { useToaster } from "../../../../../utils/useToaster";

type Props = {
  variants: TicketVariantDTO[];
  variantsLoading: boolean;
  variantsError: any;
  variantsExecute: () => void;
  activeFunctionId: string;
};

type SingleInvitationFields = {
  name: string;
  mail: string;
  variant: string;
  quantity?: number;
  reserved?: number;
};

export const SingleInvitation = (props: Props) => {
  const reservationsService = useReservationsService();

  const [reservations, , , reservationsExecute] = useAsync(
    reservationsService.getRegularReservations,
    {
      defaultValue: { ticketReserved: [] },
    }
  );

  useEffect(() => {
    if (props.activeFunctionId) {
      reservationsExecute(props.activeFunctionId);
    }
  }, [props.activeFunctionId]);

  const mappedVariants = useMemo(
    () =>
      props.variants
        .filter((variant) => variant.available > 0 && !variant.isExpired)
        .map((variant) => ({
          value: variant._id,
          label: variant.name,
          available: variant.available,
          reserved:
            reservations.ticketReserved.find(
              (v) => v.ticketVariant === variant._id
            )?.quantity ?? 0,
        })),
    [props.variants, reservations]
  );

  const { validateEmail, validationState } = useEmailValidator();

  const SingleInvitationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .required("Debe ingresar un Nombre")
          .max(50, "El nombre es muy largo"),
        mail: Yup.string()
          .max(150, "El mail es muy largo")
          .required("Debe ingresar un mail")
          .test("validation", "El email ingresado no es válido", validateEmail),
        variant: Yup.string().required("Debe elegir una categoría"),
        quantity: Yup.number()
          .min(0, "Cantidad inválida")
          .test(
            "format",
            "No hay suficientes entradas de esta variante",
            function (quantity) {
              if (this.parent.variant && quantity) {
                const selectedVariant = mappedVariants.find(
                  (variant) => variant.value === this.parent.variant
                );
                if (selectedVariant) {
                  return selectedVariant.available >= quantity;
                }
              }
              return true;
            }
          ),
        reserved: Yup.number()
          .min(0, "Cantidad inválida")
          .test(
            "format",
            "No hay suficientes entradas de esta variante",
            function (reserved) {
              if (this.parent.variant && reserved) {
                const selectedVariant = mappedVariants.find(
                  (variant) => variant.value === this.parent.variant
                );
                if (selectedVariant) {
                  return selectedVariant.reserved >= reserved;
                }
              }
              return true;
            }
          ),
      }),
    [mappedVariants, validateEmail]
  );

  const { successToast, errorToast } = useToaster();

  const [invitationLoading, setInvitationLoading] = useState(false);
  const invitationsService = useInvitationsService();
  const handleSubmit = async (values: SingleInvitationFields) => {
    try {
      setInvitationLoading(true);
      await invitationsService.createRegularInvitation(props.activeFunctionId, {
        name: values.name,
        mail: values.mail,
        tickets: [
          {
            ticketVariant: values.variant,
            quantity: values.quantity,
            reserved: values.reserved,
          },
        ],
      });

      successToast(
        "Cortesía enviada con éxito",
        `Se envió la cortesía al mail del usuario deseado`
      );
    } catch {
      errorToast("Ocurrió un error", `Lo sentimos. Inténtalo mas tarde`);
    } finally {
      setInvitationLoading(false);
      props.variantsExecute();
      if (props.activeFunctionId) {
        reservationsExecute(props.activeFunctionId);
      }
    }
  };

  const formik = useFormik<SingleInvitationFields>({
    initialValues: {
      name: "",
      mail: "",
      variant: "",
      quantity: 0,
      reserved: 0,
    },
    validationSchema: SingleInvitationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const pickedVariant = useMemo(
    () =>
      mappedVariants.find((variant) => variant.value === formik.values.variant),
    [formik.values, mappedVariants]
  );

  return (
    <FormikProvider value={formik}>
      <Stack spacing={[7, 9]} p={[5, 7]}>
        <Grid
          templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]}
          columnGap={20}
          rowGap={10}
        >
          <GridItem rowSpan={2} colSpan={1}>
            <Stack spacing={8}>
              <Stack spacing={5}>
                <Text>Ticket a enviar:</Text>

                <Field name={"variant"}>
                  {({ field, form }) => (
                    <FormControl
                      variant="floating"
                      borderRadius={4}
                      colorScheme={"secondary"}
                      id="variant"
                      isRequired
                      isInvalid={form.errors.variant}
                    >
                      <Select
                        {...StyledSelectProps}
                        // isSearchable={false}
                        options={mappedVariants}
                        isLoading={props.variantsLoading}
                        isDisabled={props.variantsLoading}
                        placeholder=" "
                        noOptionsMessage={() => "No hay resultados"}
                        loadingMessage={() => "Cargando..."}
                        {...field}
                        value={mappedVariants.find(
                          (option) => option.value === field.value
                        )}
                        onChange={(value: any) => {
                          formik.setFieldValue(field.name, value.value);
                        }}
                      />

                      <FormLabel>Tipo de ticket</FormLabel>
                      <FormErrorMessage>{form.errors.variant}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Stack>
              <Stack spacing={5}>
                <Text>Cantidad a enviar:</Text>
                <Field name={"quantity"}>
                  {({ field, form }) => (
                    <FormControl
                      variant="floating"
                      colorScheme={"secondary"}
                      borderRadius={4}
                      id="quantity"
                      isRequired
                      isInvalid={form.errors.quantity}
                    >
                      <NumberInput {...field}>
                        <NumberInputField {...field} placeholder=" " />
                        <FormLabel>En venta</FormLabel>
                      </NumberInput>
                      {pickedVariant && (
                        <FormHelperText>
                          Tickets disponibles en venta:
                          <b>{" " + pickedVariant.available}</b>
                        </FormHelperText>
                      )}
                      <FormErrorMessage>
                        {form.errors.quantity}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Stack>
              <Field name={"reserved"}>
                {({ field, form }) => (
                  <FormControl
                    variant="floating"
                    colorScheme={"secondary"}
                    borderRadius={4}
                    id="reserved"
                    isRequired
                    isInvalid={form.errors.reserved}
                  >
                    <NumberInput {...field}>
                      <NumberInputField {...field} placeholder=" " />
                      <FormLabel>Reservadas</FormLabel>
                    </NumberInput>
                    {pickedVariant && (
                      <FormHelperText>
                        Tickets reservados:
                        <b>{" " + pickedVariant.reserved}</b>
                      </FormHelperText>
                    )}
                    <FormErrorMessage>{form.errors.reserved}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>
          </GridItem>
          <GridItem rowSpan={2} colSpan={1}>
            <Stack spacing={8}>
              <Stack spacing={5}>
                <Text>Datos del receptor:</Text>

                <Field name={"name"}>
                  {({ field, form }) => (
                    <FormControl
                      variant="floating"
                      colorScheme={"secondary"}
                      borderRadius={4}
                      id="name"
                      isRequired
                      isInvalid={form.errors.name}
                    >
                      <Input {...field} placeholder=" " maxLength={50} />
                      <FormLabel>Nombre</FormLabel>
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Stack>
              <Field name={"mail"}>
                {({ field, form }) => (
                  <FormControl
                    variant="floating"
                    colorScheme={"secondary"}
                    borderRadius={4}
                    id="mail"
                    isRequired
                    isInvalid={form.errors.mail}
                  >
                    <EmailInput
                      field={field}
                      form={form}
                      label={"Mail"}
                      validationState={validationState}
                      inputProps={{
                        maxLength: 150,
                      }}
                    />

                    <FormErrorMessage>{form.errors.mail}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>
          </GridItem>
        </Grid>
      </Stack>
      <Button
        borderRadius={"full"}
        isLoading={invitationLoading || formik.isValidating}
        disabled={
          (formik.values.quantity < 1 && formik.values.reserved < 1) ||
          invitationLoading ||
          formik.isValidating
        }
        colorScheme={"primary"}
        textAlign={"center"}
        alignSelf={"center"}
        maxW={"xs"}
        w={"100%"}
        my={5}
        px={10}
        onClick={formik.submitForm}
      >
        INVITAR
      </Button>
    </FormikProvider>
  );
};
