import { Box, Text } from "@chakra-ui/react";
import ProfileDataForm from "../../../../components/ProfileDataFormModal/ProfileDataForm";

export type ProfileInfoProps = {};

export const ProfileInfo = (props: ProfileInfoProps) => {
  return (
    <Box
      borderColor={"secondary.main"}
      borderWidth={"3px"}
      borderRadius={"xl"}
      padding={5}
    >
      <Text
        color={"backgroundContrast"}
        mt={3}
        mb={8}
        fontSize={22}
        fontWeight={500}
      >
        Editá tus datos
      </Text>
      <ProfileDataForm submitLabel="Guardar" />
    </Box>
  );
};
