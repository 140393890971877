import { isSameDay, isValid, secondsToMinutes } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { es } from "date-fns/locale";

export const TIME_ZONE = "America/Santiago";

export const formatSingleDate = (date: Date) => {
  return formatInTimeZone(date, TIME_ZONE, "d MMM", {
    locale: es,
  }).toUpperCase();
};

export const formatFullDate = (date: Date) => {
  return formatInTimeZone(date, TIME_ZONE, "dd/MM/Y", {
    locale: es,
  }).toUpperCase();
};

export const formatFullDateText = (date: Date) => {
  const dateValues = formatInTimeZone(date, TIME_ZONE, "dd MMMM Y", {
    locale: es,
  })
    .split(" ")
    .map((value, index) =>
      index === 1 ? value.at(0).toUpperCase() + value.substring(1) : value
    );
  return dateValues.join(" de ");
};

export const formatSingleHour = (date: Date) => {
  return formatInTimeZone(date, TIME_ZONE, "HH:mm", { locale: es }) + "hs";
};

export const formateDateAndHour = (date: Date) => {
  return (
    formatInTimeZone(date, TIME_ZONE, "d", { locale: es }) +
    " de " +
    capitalizeFirstLetter(
      formatInTimeZone(date, TIME_ZONE, "MMMM", { locale: es })
    ) +
    ", " +
    formatSingleHour(date)
  );
};

export const formateDateRange = (from: Date, to: Date) => {
  if (isSameDay(from, to)) {
    return isValid(from) ? formatSingleDate(from) : "";
  } else {
    return isValid(from) && isValid(to)
      ? formatSingleDate(from) + " - " + formatSingleDate(to)
      : "";
  }
};

export const secondsToMinutesString = (seconds: number) => {
  const minutes = secondsToMinutes(seconds);
  const minutesString =
    minutes < 10 ? "0" + minutes.toString() : minutes.toString();

  const remainingSeconds = seconds % 60;
  const secondsString =
    remainingSeconds < 10
      ? "0" + remainingSeconds.toString()
      : remainingSeconds.toString();

  return minutesString + ":" + secondsString;
};

export const parseTime = (timeString: string) => {
  const [hours, minutes] = timeString
    .split(":")
    .map((value) => parseInt(value));
  return { hours, minutes };
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatQueueDateTime = (date: Date) => {
  return (
    formatInTimeZone(new Date(date), TIME_ZONE, "dd/MM/yyyy. HH:mm") + "hs"
  );
};
