import { useDisclosure, useInterval } from "@chakra-ui/react";
import { addHours, compareAsc, isAfter, isBefore, min } from "date-fns";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../providers/authContext";
import { EventDTO, TicketVariantDTO } from "../../../services/events/dto";
import { useEventsService } from "../../../services/events/eventsService";
import { SeatsObjectProps } from "../../../services/shoppingSession/dto";
import { useShoppingSessionService } from "../../../services/shoppingSession/shoppingSessionService";
import {
  formateDateAndHour,
  formatSingleDate,
  formatSingleHour,
  secondsToMinutesString,
} from "../../../utils/dateFormatters";
import { EventStatus, EventStatusMeta } from "../../../utils/eventsStatus";
import { priceFormatter } from "../../../utils/priceFormat";
import { useAsync } from "../../../utils/useAsync";
import { CartSummaryProps } from "./components/cart-summary";
import { TicketCart, TicketSelectorProps } from "./components/ticket-selector";
import { EventDetailController, SeatsTicketProps } from "./interfaces";
import { sleep } from "../../../utils/sleep";

const mapEventDTO = (event) => {
  if (event) {
    return {
      displayName: event.name,
      imageURL: event.images?.detail.url,
      description: event.description,
      location: event.placeName,
      organizer: event.meta.manager?.name,
      address: event.placeName,
      mapImageURL: event.images?.place?.url,
      /*
      eventChartKey:
        event._id === "63c93a7fc9c495148a3252dd"
          ? "5f4cf502-70d8-40ee-b223-29a66f0561f2"
          : null,
          */
    };
  } else {
    return {
      imageURL: "",
      displayName: "",
      description: "",
      location: "",
      organizer: "",
      address: "",
    };
  }
};

const mapSeatsData = (
  data: {
    [key: string]: SeatsTicketProps[];
  },
  variants: TicketVariantDTO[]
): SeatsObjectProps[] => {
  const categories: string[] = Object.keys(data);

  const categoriesWithID: {
    id: string;
    name: string;
  }[] = variants
    .filter((e) => categories.includes(e.name))
    .map((e) => ({
      id: e._id,
      name: e.name,
    }));

  const mappedData: SeatsObjectProps[] = Object.values(data)
    .flat()
    .map((element) => ({
      ticketVariantId: categoriesWithID.find(
        (e) => e.name === element.category.label
      ).id,
      id: element.id,
      objectType: element.objectType,
      labels: { ...element.labels, displayName: element.labels.displayedLabel },
      numSelected: element?.numSelected,
    }));
  return mappedData;
};

export const useEventDetailController = (): EventDetailController => {
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const modalController = useDisclosure();

  const shoppingSessionService = useShoppingSessionService();

  const [currentChartKey, setCurrentChartKey] = useState<string>(null);

  const {
    loading: shoppingSessionLoading,
    error: shoppingSessionError,
    execute: shoppingSessionExecute,
  } = shoppingSessionService.storage;

  const [ticketCart, setTicketCart] = useState<TicketCart>({
    functionIndex: 0,
    tickets: [],
  });

  const [cartSummary, setCartSummary] = useState<CartSummaryProps>({
    functionName: "",
    tickets: [],
    loading: true,
    disabled: true,
    onPurchasePress: () => {},
    isExempt: false,
  });

  const eventsService = useEventsService();

  const [turnData, turnError, turnLoading, turnExecute] = useAsync(
    eventsService.getTurn
  );

  useEffect(() => {
    turnExecute();
  }, []);

  const event = useMemo(() => turnData?.event, [turnData]);

  const selectedFunction = useMemo(() => turnData?.function, [turnData]);

  const ticketVariants = useMemo(() => {
    return turnData?.function.ticketVariants as any as TicketVariantDTO[];
  }, [turnData]);

  const [seconds, setSeconds] = useState(1200);

  useInterval(
    () => setSeconds((seconds) => seconds - 1),
    seconds > 0 ? 1000 : null
  );

  useEffect(() => {
    if (seconds <= 0) {
      window.location.replace("/");
    }
  }, [seconds]);

  useEffect(() => {
    if (turnData?.turn) {
      setSeconds(Math.floor(turnData.turn.leftTime));
    }
  }, [turnData]);

  const turnTimer = useMemo(() => {
    return secondsToMinutesString(seconds);
  }, [seconds]);

  const mappedEvent = useMemo(() => mapEventDTO(event), [event]);

  const mappedEventDays: TicketSelectorProps = useMemo(() => {
    if (!event || !ticketVariants) {
      return {
        days: [],
        setTicketCart,
      };
    }

    const mappedFunctions: {
      id: string;
      index: number;
      date: Date;
      hour: string;
      tickets: {
        id: string;
        name: string;
        price: string;
        color?: string;
        description?: string;
        maxQuantityPerUser?: number;
        availables?: number;
        soldOut?: boolean;
      }[];
    }[] = event.functions
      .filter((fun) => isAfter(new Date(fun.datetime), new Date()))
      .map((fun, index) => ({
        id: fun._id,
        index,
        date: fun.datetime,
        hour: formatSingleHour(new Date(fun.datetime)),
        tickets: ticketVariants
          ?.filter(
            (ticket) =>
              isAfter(new Date(), new Date(ticket.sellStartDatetime)) &&
              isBefore(new Date(), new Date(ticket.sellFinishDatetime))
          )
          .map((ticket) => ({
            name: ticket.name,
            price: "$" + priceFormatter(ticket.price),
            id: ticket._id,
            color: ticket.color,
            description: ticket.description,
            maxQuantityPerUser: ticket.maxQuantityPerUser,
            minimumQuantityPerUser: ticket.minimumQuantityPerUser,
            availables: ticket.available,
            soldOut: ticket.available <= 0,
          })),
      }));

    let days: { day: string; date: Date; functions: any[] }[] = [];

    mappedFunctions
      ?.filter((fun) => fun.tickets && fun.tickets.length > 0)
      .map((fun) => {
        const functionDate = new Date(fun.date);

        const functionDay = days.findIndex(
          (day) => compareAsc(day.date, functionDate) === 0
        );
        if (functionDay !== -1) {
          days[functionDay].functions.push(fun);
        } else {
          days.push({
            date: functionDate,
            day: formatSingleDate(functionDate),
            functions: [fun],
          });
        }
        return fun;
      });

    return { days, setTicketCart };
  }, [ticketVariants, event]);

  const handlePurchase = useCallback(() => {
    if (!authContext.authState.userId) {
      modalController.onOpen();
    } else {
      shoppingSessionExecute(
        () =>
          shoppingSessionService.createShoppingSession({
            function: {
              id: selectedFunction._id,
              date: ticketCart.functionDate,
              name: event?.name,
            },
            ticketsBloqued: ticketCart.tickets
              .filter((t) => t.quantity > 0)
              .map((t) => ({
                quantity: t.quantity,
                ticketVariant: t.id,
              })),
          }),
        () => {
          navigate(`/events/${event._id}/payment`);
        }
      );
    }
  }, [
    authContext.authState.userId,
    shoppingSessionExecute,
    ticketCart.functionId,
    ticketCart.tickets,
    selectedFunction,
    event,
  ]);

  useEffect(
    () => {
      if (
        event &&
        selectedFunction &&
        ticketVariants &&
        ticketCart &&
        ticketCart.functionIndex !== undefined
      ) {
        if (!!selectedFunction?.eventFunctionChartKey) {
          setCurrentChartKey(selectedFunction.eventFunctionChartKey);
        } else {
          setCurrentChartKey(null);
        }
        setCartSummary({
          loading: false,
          functionName: formateDateAndHour(new Date(selectedFunction.datetime)),
          tickets: ticketVariants
            .filter(
              (ticket) =>
                isAfter(new Date(), new Date(ticket.sellStartDatetime)) &&
                isBefore(new Date(), new Date(ticket.sellFinishDatetime))
            )
            .map((ticket, index) => {
              const cartTicket = ticketCart.tickets[index];
              return {
                ...ticket,
                ...cartTicket,
              };
            }),
          disabled: !ticketCart.tickets.some((ticket) => ticket.quantity > 0),
          onPurchasePress: !!selectedFunction?.eventFunctionChartKey
            ? handleSeatsPurchase
            : handlePurchase,
          purchaseLoading: shoppingSessionLoading,
          purchaseError: shoppingSessionError?.response?.data?.message,
          isExempt: event.isExempt,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      event,
      shoppingSessionLoading,
      shoppingSessionError,
      ticketCart,
      ticketVariants,
      handlePurchase,
    ]
  );

  const [locationLoading, setLocationLoading] = useState(false);
  const onLocationButtonPress = async () => {
    if (event.place) {
      setLocationLoading(true);
      try {
        const place = await eventsService.getPlace(event.place);
        if (place?.urlMaps) {
          window.open(place.urlMaps, "_blank");
        }
      } catch {}
      setLocationLoading(false);
    }
    return;
  };

  const handleSeatsPurchase = (data: any) => {
    if (!authContext.authState.userId) {
      modalController.onOpen();
    } else {
      shoppingSessionExecute(
        () =>
          shoppingSessionService.createShoppingSession({
            function: {
              id: ticketCart.functionId,
              date: ticketCart.functionDate,
              name: event?.name,
              eventFunctionChartKey: currentChartKey,
            },
            seats: mapSeatsData(data, ticketVariants),
          }),
        () => {
          navigate("payment");
        }
      );
    }
  };

  const {
    eventStatus,
    eventStatusMeta,
  }: { eventStatus: EventStatus; eventStatusMeta: EventStatusMeta } =
    useMemo(() => {
      if (event && ticketVariants) {
        const today = new Date();
        const eventActive = event.functions.some(
          (fun) => fun.endDatetime && isAfter(new Date(fun.endDatetime), today)
        );
        if (!eventActive) {
          return {
            eventStatus: "Finished",
            eventStatusMeta: { sellStartDate: new Date() },
          };
        } else {
          const hasAvailableTickets = Object.values(ticketVariants).some(
            (variant) => variant.available > 0
          );
          const hasUnexpiredTickets = Object.values(ticketVariants).some(
            (variant) =>
              !variant.sellFinishDatetime ||
              isAfter(new Date(variant.sellFinishDatetime), today)
          );

          const { sellStartDate, sellStarted } = Object.values(
            ticketVariants
          ).reduce(
            (prev, functionVariants) => {
              const { functionSellStartDate, functionSellStarted } = {
                functionSellStartDate: min([
                  new Date(functionVariants.sellStartDatetime),
                  prev.sellStartDate,
                ]),
                functionSellStarted:
                  prev.sellStarted ||
                  isBefore(new Date(functionVariants.sellStartDatetime), today),
              };
              return {
                sellStartDate: min([prev.sellStartDate, functionSellStartDate]),
                sellStarted: prev.sellStarted || functionSellStarted,
              };
            },

            { sellStartDate: new Date(8640000000000000), sellStarted: false }
          );

          if (!hasUnexpiredTickets) {
            return {
              eventStatus: "Finished",
              eventStatusMeta: { sellStartDate },
            };
          } else {
            if (!hasAvailableTickets) {
              return {
                eventStatus: "SoldOut",
                eventStatusMeta: { sellStartDate },
              };
            } else {
              if (sellStarted) {
                return {
                  eventStatus: "Published",
                  eventStatusMeta: { sellStartDate },
                };
              } else {
                return {
                  eventStatus: "Upcoming",
                  eventStatusMeta: { sellStartDate },
                };
              }
            }
          }
        }
      } else {
        return {
          eventStatus: "Published",
          eventStatusMeta: { sellStartDate: new Date() },
        };
      }
    }, [event, ticketVariants]);

  return {
    turnError,
    turnLoading,
    turnTimer,

    ...mappedEvent,
    onLocationButtonPress,
    locationLoading,
    cartSummary,
    setCartSummary,
    eventFunctions: mappedEventDays,
    ticketVariantsLoading: turnLoading,
    eventLoading: turnLoading,
    currentChartKey,
    modalController,
    eventStatus,
    eventStatusMeta,

    functions: turnData?.function ? [turnData.function as any] : [],
    ticketVariants: ticketVariants,
    seatsImage: event?.chartThumbnail,
  };
};
