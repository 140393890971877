import React from "react";
import { EventListProps } from "./interfaces";
import { useEventListController } from "./event-list.controller";
import { Box, Link, Stack, Text } from "@chakra-ui/react";
import EventListCard from "./components/eventListCard";
import { useNavigate } from "react-router-dom";

export const EventList: React.FC<EventListProps> = ({
  useController = useEventListController,
}) => {
  const controller = useController();
  const navigate = useNavigate();
  return controller.userEventList.length > 0 ? (
    <Stack
      px={{ sm: "2%", md: "10%" }}
      mx="4%"
      py="2%"
      direction="column"
      spacing={5}
      overflow={"auto"}
    >
      {controller.userEventList?.map((event, index) => (
        <Box key={"event-item-" + index}>
          <EventListCard
            event={event}
            navigate={navigate}
            loading={controller.loadingList}
            onDuplicatePress={async () =>
              await controller.onEventDuplicate(event)
            }
          />
        </Box>
      ))}
    </Stack>
  ) : (
    <Box
      px={{ sm: "2%", md: "10%" }}
      mx={"auto"}
      w={["96%", "96%", "max-content"]}
      my={{ sm: 5, md: 20 }}
      py={{ sm: "2%", md: "8%" }}
      borderColor={"secondary.main"}
      borderWidth={"3px"}
      borderRadius={"xl"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      p={5}
    >
      <Text textAlign={"center"} fontSize={36} fontWeight={700}>
        Ups!
      </Text>
      <Text textAlign={"center"} fontSize={16} fontWeight={400}>
        No administras ningún evento.
      </Text>
      <Text
        noOfLines={[0, 0, 1]}
        textAlign={"center"}
        fontSize={16}
        fontWeight={400}
      >
        Una vez que administres uno, podrás verlo y darle seguimiento aquí
        mismo.
      </Text>
      <Text textAlign={"center"} fontSize={16} fontWeight={400}>
        ¡Anímate a crear tu primer evento y compartirlo con la comunidad!
      </Text>
      <Link textAlign={"center"} color={"primary.main"} href="/create-event">
        Crear evento
      </Link>
    </Box>
  );
};
