import {
  Stack,
  SimpleGrid,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Button,
  Center,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { Field, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import * as Yup from "yup";
import { useProfileService } from "../../../services/profile/profileService";
import { useAsync } from "../../../utils/useAsync";
import { useColors } from "../../../utils/useColors";

interface ResetPasswordFormProps {
  onSubmitDone?: () => void;

  submitLabel?: string;
}

const ResetPasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Este campo es requerido"),
  newPassword: Yup.string().required("Este campo es requerido"),
  repeatNewPassword: Yup.string().required("Este campo es requerido"),
});

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  onSubmitDone = null,
  submitLabel,
}) => {
  const profileService = useProfileService();

  const [, , loading, execute] = useAsync(profileService.changePassword);
  const handleSubmit = async (values) => {
    execute(values.oldPassword, values.newPassword);
    // execute(() => profileService.updateProfile(values), onSubmitDone);
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      repeatNewPassword: "",
    },
    validationSchema: ResetPasswordSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const [showNewPass, setShowNewPass] = useState(false);

  const [showOldPass, setShowOldPass] = useState(false);

  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const colors = useColors();

  return (
    <FormikProvider value={formik}>
      <Stack spacing={5}>
        <SimpleGrid columns={1} spacing={5}>
          <Field name="oldPassword">
            {({ field, form }) => (
              <FormControl
                variant="outline"
                borderRadius={4}
                id="oldPassword"
                isInvalid={form.errors.oldPassword}
                isRequired
              >
                <FormLabel>Clave anterior</FormLabel>
                <InputGroup variant={"outline"}>
                  <Input
                    {...field}
                    borderRadius={4}
                    variant={"outline"}
                    type={showOldPass ? "text" : "password"}
                    placeholder="Contraseña"
                    _autofill={{
                      boxShadow: "transparent",
                      transition: "background-color 5000s ease-in-out 0s",
                    }}
                  />
                  <InputRightElement
                    _hover={{
                      cursor: "pointer",
                    }}
                    children={
                      setShowOldPass ? (
                        <FaEye color={colors?.backgroundContrast} />
                      ) : (
                        <FaEyeSlash color={colors?.backgroundContrast} />
                      )
                    }
                    onClick={() => setShowOldPass(!showOldPass)}
                  />
                </InputGroup>
                <FormErrorMessage>{form.errors.oldPassword}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="newPassword">
            {({ field, form }) => (
              <FormControl
                variant="outline"
                borderRadius={4}
                id="newPassword"
                isInvalid={form.errors.newPassword}
                isRequired
              >
                <FormLabel>Clave nueva</FormLabel>

                <InputGroup variant={"outline"}>
                  <Input
                    {...field}
                    borderRadius={4}
                    variant={"outline"}
                    type={showNewPass ? "text" : "password"}
                    placeholder="Contraseña"
                    _autofill={{
                      boxShadow: "transparent",
                      transition: "background-color 5000s ease-in-out 0s",
                    }}
                  />
                  <InputRightElement
                    _hover={{
                      cursor: "pointer",
                    }}
                    children={
                      setShowNewPass ? (
                        <FaEye color={colors?.backgroundContrast} />
                      ) : (
                        <FaEyeSlash color={colors?.backgroundContrast} />
                      )
                    }
                    onClick={() => setShowNewPass(!showNewPass)}
                  />
                </InputGroup>
                <FormErrorMessage>{form.errors.newPassword}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="repeatNewPassword">
            {({ field, form }) => (
              <FormControl
                variant="outline"
                borderRadius={4}
                id="repeatNewPassword"
                isInvalid={form.errors.repeatNewPassword}
                isRequired
              >
                <FormLabel>Confirmar clave nueva</FormLabel>

                <InputGroup variant={"outline"}>
                  <Input
                    {...field}
                    borderRadius={4}
                    variant={"outline"}
                    type={showConfirmPass ? "text" : "password"}
                    placeholder="Contraseña"
                    _autofill={{
                      boxShadow: "transparent",
                      transition: "background-color 5000s ease-in-out 0s",
                    }}
                  />
                  <InputRightElement
                    _hover={{
                      cursor: "pointer",
                    }}
                    children={
                      setShowConfirmPass ? (
                        <FaEye color={colors?.backgroundContrast} />
                      ) : (
                        <FaEyeSlash color={colors?.backgroundContrast} />
                      )
                    }
                    onClick={() => setShowConfirmPass(!showConfirmPass)}
                  />
                </InputGroup>
                <FormErrorMessage>
                  {form.errors.repeatNewPassword}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </SimpleGrid>
        <Center>
          <Button
            colorScheme={"primary"}
            borderRadius="full"
            isLoading={loading}
            w={{ xs: "fit-content", md: "30%" }}
            onClick={() => {
              formik.validateForm().then((res) => {
                Object.keys(res).length === 0 && formik.submitForm();
              });
            }}
          >
            {submitLabel ?? "Continuar"}
          </Button>
        </Center>
      </Stack>
    </FormikProvider>
  );
};

export default ResetPasswordForm;
