export const getDiffPercentage = (
  prev?: string | number,
  current?: string | number
) => {
  if ((!prev && prev !== 0) || (!current && current !== 0)) {
    return;
  }
  const prevValue = parseInt(prev.toString());
  const currentValue = parseInt(current.toString());

  if (!prevValue || (!currentValue && currentValue !== 0)) {
    return;
  }

  const diff = ((currentValue - prevValue) / prevValue) * 100;

  return {
    value: `${diff < 0 ? "" : "+"}${diff.toFixed(2)}%`,
    color: diff === 0 ? "muted.main" : diff > 0 ? "primary.main" : "red.main",
  };
};
