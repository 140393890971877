import React, { useMemo } from "react";
import {
  Button,
  Center,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Field, FormikProvider, useFormik } from "formik";
import { useOutletContext } from "react-router-dom";
import { useTicketsSaleService } from "../../../../services/ticketsSale/ticketsSaleService";
import { formatRut, cleanRut } from "../../../../utils/rutFormatter";
import { StepperFormOutletContext } from "../../../Landing/event-stepper-form/interfaces";
import { SaleStep } from "./step";
import { SaleClientFields } from "../../../../services/ticketsSale/ticketsSaleStore";
import * as Yup from "yup";
import { useEmailValidator } from "../../../../utils/useEmailValidator";
import { EmailInput } from "../../../../components/EmailInput";

const ClientForm = () => {
  const {
    validateEmail: validateUserEmail,
    validationState: userValidationState,
  } = useEmailValidator();

  const {
    validateEmail: validateBillingEmail,
    validationState: billingValidationState,
  } = useEmailValidator();

  const ClientFormSchema = useMemo(
    () =>
      Yup.object().shape({
        firstname: Yup.string().required("Debe ingresar un nombre"),
        lastname: Yup.string().required("Debe ingresar un apellido"),
        idNumber: Yup.string().required("Debe ingresar un RUT"),
        phone: Yup.string().required("Debe ingresar un teléfono"),
        city: Yup.string().required("Debe ingresar una ciudad"),
        district: Yup.string().required("Debe ingresar un distrito"),
        address: Yup.string().required("Debe ingresar una dirección"),
        email: Yup.string()
          .email("Correo invalido")
          .required("Debe ingresar un correo")
          .test(
            "validation",
            "El email ingresado no es válido",
            validateUserEmail
          ),
        clientDataBilling: Yup.string(),
        billingData: Yup.object().when("clientDataBilling", {
          is: "false",
          then: Yup.object().shape({
            fullname: Yup.string().required(
              "Debe ingresar un nombre y apellido"
            ),
            idNumber: Yup.string().required("Debe ingresar un RUT"),
            city: Yup.string().required("Debe ingresar una ciudad"),
            district: Yup.string().required("Debe ingresar un distrito"),
            address: Yup.string().required("Debe ingresar una dirección"),
            email: Yup.string()
              .email("Correo invalido")
              .required("Debe ingresar un correo")
              .test(
                "validation",
                "El email ingresado no es válido",
                validateBillingEmail
              ),
          }),
        }),
      }),
    [validateUserEmail, validateBillingEmail]
  );
  const formService = useTicketsSaleService();

  const columnsResponsive = useBreakpointValue({
    xs: 1,
    md: 2,
  });

  const { setFormData, formData } = formService.store;

  const { onNextStepPress } = useOutletContext<StepperFormOutletContext>();
  const handleSubmit = (values: any) => {
    let profileData = values;

    if (values.clientDataBilling === "true") {
      profileData.billingData = {
        fullname: values.firstname + " " + values.lastname,
        idNumber: values.idNumber,
        city: values.city,
        district: values.district,
        address: values.address,
        email: values.email,
      };
    }

    setFormData({
      profile: profileData,
    });

    onNextStepPress();
  };

  const handleBlur = (field: string) => {
    formik.validateField(field);
  };

  const formik = useFormik<SaleClientFields>({
    initialValues: {
      firstname: formData?.profile?.firstname || "",
      lastname: formData?.profile?.lastname || "",
      idNumber: formData?.profile?.idNumber || "",
      phone: formData?.profile?.phone || "",
      city: formData?.profile?.city || "",
      district: formData?.profile?.district || "",
      address: formData?.profile?.address || "",
      email: formData?.profile?.email || "",
      clientDataBilling: formData?.profile?.clientDataBilling || "true",
      billingData: {
        fullname: formData?.profile?.billingData.fullname || "",
        idNumber: formData?.profile?.billingData.idNumber || "",
        city: formData?.profile?.billingData.city || "",
        district: formData?.profile?.billingData.district || "",
        address: formData?.profile?.billingData.address || "",
        email: formData?.profile?.billingData.email || "",
      },
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ClientFormSchema,
    onSubmit: handleSubmit,
  });

  return (
    <SaleStep>
      <FormikProvider value={formik}>
        <Stack direction={"column"} spacing={10}>
          <Text fontWeight={"bold"} fontSize="lg">
            Ingrese los datos del cliente
          </Text>
          <SimpleGrid columns={columnsResponsive} spacing={10}>
            <Field name="firstname">
              {({ field, form }) => (
                <FormControl
                  variant="floatingGray"
                  borderRadius={4}
                  id="firstname"
                  isInvalid={form.errors.firstname}
                  isRequired
                >
                  <Input {...field} placeholder=" " max={50} onBlur={() => handleBlur("firstname")} />
                  {/* It is important that the Label comes after the Control due to css selectors */}
                  <FormLabel>Nombre</FormLabel>
                  <FormErrorMessage>{form.errors.firstname}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="lastname">
              {({ field, form }) => (
                <FormControl
                  variant="floatingGray"
                  borderRadius={4}
                  id="lastname"
                  isInvalid={form.errors.lastname}
                  isRequired
                >
                  <Input {...field} placeholder=" " max={50} onBlur={() => handleBlur("lastname")} />
                  {/* It is important that the Label comes after the Control due to css selectors */}
                  <FormLabel>Apellido</FormLabel>
                  <FormErrorMessage>{form.errors.lastname}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={"email"}>
              {({ field, form }) => (
                <FormControl
                  variant="floatingGray"
                  borderRadius={4}
                  id="email"
                  isRequired
                  isInvalid={form.errors.email}
                >
                  <EmailInput
                    field={field}
                    form={form}
                    label={"Email"}
                    validationState={userValidationState}
                    inputProps={{
                      maxLength: 150,
                    }}
                    inputGroupProps={{
                      borderRadius: 4,
                    }}
                    onBlur={() => handleBlur("email")}
                  />

                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="idNumber">
              {({ field, form }) => (
                <FormControl
                  variant="floatingGray"
                  borderRadius={4}
                  id="idNumber"
                  isInvalid={form.errors.idNumber}
                  isRequired
                >
                  <Input
                    {...field}
                    onChange={(e) => {
                      formik.setFieldValue(
                        field.name,
                        formatRut(cleanRut(e.target.value))
                      );
                    }}
                    placeholder=" "
                    maxLength={12}
                    onBlur={() => handleBlur("idNumber")}
                  />
                  {/* It is important that the Label comes after the Control due to css selectors */}
                  <FormLabel>RUT</FormLabel>
                  <FormErrorMessage>{form.errors.idNumber}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="city">
              {({ field, form }) => (
                <FormControl
                  variant="floatingGray"
                  borderRadius={4}
                  id="city"
                  isInvalid={form.errors.city}
                  isRequired
                >
                  <Input {...field} placeholder=" " max={50} onBlur={() => handleBlur("city")}/>
                  {/* It is important that the Label comes after the Control due to css selectors */}
                  <FormLabel>Ciudad</FormLabel>
                  <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="district">
              {({ field, form }) => (
                <FormControl
                  variant="floatingGray"
                  borderRadius={4}
                  id="district"
                  isInvalid={form.errors.district}
                  isRequired
                >
                  <Input {...field} placeholder=" " max={50} onBlur={() => handleBlur("district")} />
                  {/* It is important that the Label comes after the Control due to css selectors */}
                  <FormLabel>Comuna</FormLabel>
                  <FormErrorMessage>{form.errors.district}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="address">
              {({ field, form }) => (
                <FormControl
                  variant="floatingGray"
                  borderRadius={4}
                  id="address"
                  isInvalid={form.errors.address}
                  isRequired
                >
                  <Input {...field} placeholder=" " max={50} onBlur={() => handleBlur("address")} />
                  {/* It is important that the Label comes after the Control due to css selectors */}
                  <FormLabel>Dirección</FormLabel>
                  <FormErrorMessage>{form.errors.address}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="phone">
              {({ field, form }) => (
                <FormControl
                  variant="floatingGray"
                  borderRadius={4}
                  id="phone"
                  isRequired
                  isInvalid={form.errors.phone}
                >
                  <Input {...field} placeholder=" " max={25} />
                  {/* It is important that the Label comes after the Control due to css selectors */}
                  <FormLabel>Telefono</FormLabel>
                  <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </SimpleGrid>
          <Field name="clientDataBilling">
            {({ field, form }) => {
              const valueState: boolean = field.value === "true";
              return (
                <Checkbox
                  w={"auto"}
                  colorScheme={"muted"}
                  isChecked={valueState}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "clientDataBilling",
                      e.target.checked ? "true" : "false"
                    )
                  }
                >
                  <Text noOfLines={1}>
                    Mis datos personales y de facturación coinciden
                  </Text>
                </Checkbox>
              );
            }}
          </Field>
          {formik.values.clientDataBilling === "false" && (
            <SimpleGrid columns={columnsResponsive} spacing={10}>
              <Field name="billingData.fullname">
                {({ field, form }) => (
                  <FormControl
                    variant="floatingGray"
                    borderRadius={4}
                    id="billingFullname"
                    isInvalid={form.errors.billingData?.fullname}
                    isRequired
                  >
                    <Input {...field} placeholder=" " max={50} />
                    {/* It is important that the Label comes after the Control due to css selectors */}
                    <FormLabel>Nombre completo</FormLabel>
                    <FormErrorMessage>
                      {form.errors.billingData?.fullname}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"billingData.email"}>
                {({ field, form }) => (
                  <FormControl
                    variant="floatingGray"
                    borderRadius={4}
                    id="billingMail"
                    isRequired
                    isInvalid={form.errors.billingData?.email}
                  >
                    <EmailInput
                      field={field}
                      form={form}
                      label={"Email"}
                      validationState={billingValidationState}
                      inputProps={{
                        maxLength: 150,
                      }}
                      inputGroupProps={{
                        borderRadius: 4,
                      }}
                    />

                    <FormErrorMessage>
                      {form.errors.billingData?.email}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="billingData.idNumber">
                {({ field, form }) => (
                  <FormControl
                    variant="floatingGray"
                    borderRadius={4}
                    id="billingIdNumber"
                    isInvalid={form.errors.billingData?.idNumber}
                    isRequired
                  >
                    <Input
                      {...field}
                      onChange={(e) => {
                        formik.setFieldValue(
                          field.name,
                          formatRut(cleanRut(e.target.value))
                        );
                      }}
                      placeholder=" "
                      maxLength={12}
                    />
                    {/* It is important that the Label comes after the Control due to css selectors */}
                    <FormLabel>RUT</FormLabel>
                    <FormErrorMessage>
                      {form.errors.billingData?.idNumber}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="billingData.city">
                {({ field, form }) => (
                  <FormControl
                    variant="floatingGray"
                    borderRadius={4}
                    id="billingCity"
                    isInvalid={form.errors.billingData?.city}
                    isRequired
                  >
                    <Input {...field} placeholder=" " max={50} />
                    {/* It is important that the Label comes after the Control due to css selectors */}
                    <FormLabel>Ciudad</FormLabel>
                    <FormErrorMessage>
                      {form.errors.billingData?.city}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="billingData.district">
                {({ field, form }) => (
                  <FormControl
                    variant="floatingGray"
                    borderRadius={4}
                    id="billingDistrict"
                    isInvalid={form.errors.billingData?.district}
                    isRequired
                  >
                    <Input {...field} placeholder=" " max={50} />
                    {/* It is important that the Label comes after the Control due to css selectors */}
                    <FormLabel>Comuna</FormLabel>
                    <FormErrorMessage>
                      {form.errors.billingData?.district}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="billingData.address">
                {({ field, form }) => (
                  <FormControl
                    variant="floatingGray"
                    borderRadius={4}
                    id="billingAddress"
                    isInvalid={form.errors.billingData?.address}
                    isRequired
                  >
                    <Input {...field} placeholder=" " max={50} />
                    {/* It is important that the Label comes after the Control due to css selectors */}
                    <FormLabel>Dirección</FormLabel>
                    <FormErrorMessage>
                      {form.errors.billingData?.address}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="billingData.phone">
                {({ field, form }) => (
                  <FormControl
                    variant="floatingGray"
                    borderRadius={4}
                    isRequired
                    id="billingPhone"
                    isInvalid={form.errors.billingData?.phone}
                  >
                    <Input {...field} placeholder=" " max={25} />
                    {/* It is important that the Label comes after the Control due to css selectors */}
                    <FormLabel>Telefono</FormLabel>
                    <FormErrorMessage>
                      {form.errors.billingData?.phone}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </SimpleGrid>
          )}
          <Center>
            <Button
              colorScheme={"primary"}
              borderRadius="full"
              w={{ xs: "fit-content", md: "30%" }}
              disabled={formik.isValidating}
              isLoading={formik.isValidating}
              onClick={() => {
                formik.submitForm();
              }}
            >
              {"Continuar"}
            </Button>
          </Center>
        </Stack>
      </FormikProvider>
    </SaleStep>
  );
};

export default ClientForm;
