import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { AuthContext } from "../../providers/authContext";

const LogOutmodal = ({ isOpen, onClose }) => {
  const authContext = useContext(AuthContext);

  const initialFocusRef = React.useRef();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialFocusRef}
      preserveScrollBarGap
      isCentered
    >
      <ModalOverlay />
      <ModalContent backgroundColor={"backgroundColor"}>
        <ModalHeader>Seguro desea cerrar sesión ?</ModalHeader>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose} mr={3}>
            Cancelar
          </Button>
          <Button
            ref={initialFocusRef}
            colorScheme="red"
            onClick={() => {
              authContext.logout();
              onClose();
            }}
          >
            Cerrar sesión
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LogOutmodal;
