/* eslint-disable react-hooks/exhaustive-deps */
import {
  Stack,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Button,
  Center,
  useDisclosure,
  Box,
  Flex,
  useOutsideClick,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Badge,
  NumberInput,
  NumberInputField,
  Textarea,
  Skeleton,
  Image,
  Divider,
  FormHelperText,
} from "@chakra-ui/react";
import {
  CalendarControls,
  CalendarDays,
  CalendarMonth,
  CalendarMonthName,
  CalendarMonths,
  CalendarNextButton,
  CalendarPrevButton,
  CalendarValues,
  CalendarWeek,
} from "@uselessdev/datepicker";
import { add, format, isAfter, isBefore, isValid, parse } from "date-fns";
import {
  Field,
  FieldArray,
  FormikProvider,
  FieldArrayRenderProps,
  FormikProps,
} from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { RiPencilFill } from "react-icons/ri";
import ThemedCalendar from "../../../../components/CalendarPicker/themedCalendar";
import ConfirmActionModal from "../../../../components/ConfirmActionModal";
import {
  TicketFormFields,
  useEventFormStore,
} from "../../../../services/createEvent/createEventStore";
import { parsePrice, priceFormatter } from "../../../../utils/priceFormat";
import { EventStep } from "./step";

export interface TicketsFormProps {
  formik: FormikProps<TicketFormFields>;
}

const TicketsForm = ({ formik }: TicketsFormProps) => {
  const { setFormData, formData, setEvent } = useEventFormStore();

  const maxDate = useMemo(
    () =>
      formData?.functions
        ? formData.functions.functions.reduce(
            (prev, fun) =>
              isAfter(prev, new Date(fun.closingDate.value))
                ? prev
                : new Date(fun.closingDate.value),
            new Date()
          )
        : undefined,
    [formData?.functions]
  );

  const [selectedTicket, setSelectedTicket] = useState(0);

  const [dates, setDates] = useState<CalendarValues>({});

  const {
    isOpen: isCalendarOpen,
    onOpen: onCalendarOpen,
    onClose: onCalendarClose,
  } = useDisclosure();

  const {
    isOpen: isModalConfirmOpen,
    onOpen: onModalConfirmOpen,
    onClose: onModalConfirmClose,
  } = useDisclosure();

  const initialRef = useRef(null);
  const calendarRef = useRef(null);
  const startInputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);

  const handleSelectDate = (dates: CalendarValues) => {
    setDates(dates);

    formik.setFieldValue(
      `tickets.${selectedTicket}.openingDate`,
      isValid(dates.start)
        ? {
            value: format(dates.start as Date, "MM/dd/yyyy"),
            label: format(dates.start as Date, "dd/MM/yyyy"),
          }
        : { value: "", label: "" }
    );

    formik.setFieldValue(
      `tickets.${selectedTicket}.closingDate`,
      isValid(dates.end)
        ? {
            value: format(dates.end as Date, "MM/dd/yyyy"),
            label: format(dates.end as Date, "dd/MM/yyyy"),
          }
        : { value: "", label: "" }
    );
  };

  const match = (value?: string) =>
    value && value.match(/(\d{2})\/(\d{2})\/(\d{4})/);

  const handleInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(`tickets.${selectedTicket}.${target.name}`, {
      label: target.value,
      value: format(
        parse(target.value, "dd/MM/yyyy", new Date()),
        "MM/dd/yyyy"
      ),
    });

    if (
      target.name === "openingDate" &&
      match(target.value) &&
      endInputRef.current
    ) {
      endInputRef.current.focus();
    }
  };

  useOutsideClick({
    ref: calendarRef,
    handler: onCalendarClose,
    enabled: isCalendarOpen,
  });

  useEffect(() => {
    const { openingDate, closingDate } = formik.values.tickets[selectedTicket];
    if (match(openingDate.value)) {
      const startDate = new Date(openingDate.value);
      const isValidStartDate = isValid(startDate);
      const isAfterEndDate =
        closingDate && isAfter(startDate, new Date(closingDate.value));
      if (isValidStartDate && isAfterEndDate) {
        formik.setFieldValue(`tickets.${selectedTicket}.closingDate`, {
          value: "",
          label: "",
        });

        return setDates({ end: undefined, start: startDate });
      }
      return setDates({ ...dates, start: startDate });
    }
  }, [formik.values.tickets[selectedTicket].openingDate]);

  useEffect(() => {
    const { openingDate, closingDate } = formik.values.tickets[selectedTicket];
    if (match(closingDate.value)) {
      const endDate = new Date(closingDate.value);
      const isValidEndDate = isValid(endDate);
      const isBeforeStartDate =
        openingDate && isBefore(endDate, new Date(openingDate.value));

      if (isValidEndDate && isBeforeStartDate) {
        formik.setFieldValue(`tickets.${selectedTicket}.openingDate`, {
          value: "",
          label: "",
        });

        startInputRef.current?.focus();

        return setDates({ start: undefined, end: endDate });
      }

      // onCalendarClose();
      return setDates({ ...dates, end: endDate });
    }
  }, [formik.values.tickets[selectedTicket].closingDate]);

  useEffect(() => {
    const { openingDate, closingDate } = formik.values.tickets[selectedTicket];

    let newOpeningDate = undefined;
    let newClosingDate = undefined;

    if (match(closingDate.value)) {
      const date = new Date(closingDate.value);
      newClosingDate = isValid(date) ? date : undefined;
    }

    if (match(openingDate.value)) {
      const date = new Date(openingDate.value);
      newOpeningDate = isValid(date) ? date : undefined;
    }

    setDates({ start: newOpeningDate, end: newClosingDate });
  }, [selectedTicket]);

  useEffect(() => {
    const tickets = formik.values.tickets;

    const min = tickets.reduce((prev, ticket) => {
      const price = ticket.price
        ? parseInt(parsePrice(ticket.price))
        : undefined;
      if (!prev && price) {
        return price;
      } else if (!prev && !price) {
        return undefined;
      } else {
        return prev < price ? prev : price;
      }
    }, undefined as number | undefined);
    if (min) {
      setEvent({ price: priceFormatter(min) });
    }
  }, [formik.values]);

  const [mapImageLoaded, setMapImageLoaded] = useState(false);

  let boundArrayHelpers: FieldArrayRenderProps | null;

  const bindArrayHelpers = (arrayHelpers: FieldArrayRenderProps) => {
    boundArrayHelpers = arrayHelpers;
  };

  useEffect(() => {
    setFormData({
      tickets: formik.values,
    });
  }, [formik.values]);

  const loading = false;

  return (
    <EventStep
      buttonProps={{
        onClick: formik.submitForm,
        isLoading: loading,
      }}
      preview={
        formData?.images?.place?.url && (
          <Box
            width={"sm"}
            p={5}
            backgroundColor={"secondary.main"}
            borderRadius={20}
          >
            <Text mb={5} textAlign={"center"} fontSize={24} fontWeight={600}>
              Mapa de ubicaciones
            </Text>
            <Skeleton
              startColor="secondary.800"
              endColor="secondary.700"
              width={"100%"}
              // style={{ aspectRatio: 0.64 }}
              borderRadius={20}
              overflow={"hidden"}
              isLoaded={mapImageLoaded}
            >
              <Image
                src={formData?.images?.place.url}
                onLoad={() => setMapImageLoaded(true)}
                onError={() => setMapImageLoaded(true)}
                fallback={
                  <Box
                    width={"100%"}
                    overflow={"hidden"}
                    borderRadius={20}
                    // style={{ aspectRatio: 0.64 }}
                  ></Box>
                }
                width={"100%"}
                // style={{ aspectRatio: 0.64 }}
                overflow={"hidden"}
                borderRadius={20}
              />
            </Skeleton>
          </Box>
        )
      }
      label="FINALIZAR"
    >
      <FormikProvider value={formik}>
        <FieldArray name="tickets">
          {(props) => {
            const { push } = props;
            bindArrayHelpers(props);
            return (
              <Stack spacing={8}>
                <Text fontSize={{ base: "md", md: "lg" }} w="85%">
                  Tickets
                </Text>

                <Flex wrap={"wrap"}>
                  {formik.values &&
                    formik.values.tickets.map((ticket, index) => (
                      <Badge
                        mr={2}
                        mb={2}
                        key={index}
                        p={2}
                        display={"flex"}
                        width={"max-content"}
                        alignItems={"center"}
                        colorScheme={
                          selectedTicket === index ? "primary" : "muted"
                        }
                        bgColor={
                          selectedTicket === index
                            ? "backgroundColor"
                            : "muted.main"
                        }
                        color={
                          selectedTicket === index ? "primary.main" : "black"
                        }
                        variant={selectedTicket === index ? "outline" : "solid"}
                        borderRadius={"xl"}
                        cursor={"pointer"}
                        onClick={() => setSelectedTicket(index)}
                      >
                        <Box mr={2} maxW={"xl"} overflow={"hidden"}>
                          {ticket.name}
                        </Box>
                        <RiPencilFill
                          size={18}
                          color={
                            selectedTicket === index ? "primary.main" : "black"
                          }
                        />
                      </Badge>
                    ))}
                </Flex>
                <Flex wrap={"wrap"} justifyContent={"space-between"}>
                  <Box w={["100%", "100%", "70%"]} my={2}>
                    <Field name={`tickets.${selectedTicket}.name`}>
                      {({ field, form }) => (
                        <FormControl
                          variant="floating"
                          borderRadius={4}
                          id={`tickets.${selectedTicket}.name`}
                          isInvalid={
                            form.errors.tickets &&
                            form.errors.tickets[selectedTicket] &&
                            form.errors.tickets[selectedTicket].name
                          }
                          isRequired
                        >
                          <Input
                            textTransform="uppercase"
                            {...field}
                            placeholder=" "
                            maxLength={50}
                          />

                          <FormLabel>Nombre</FormLabel>
                          <FormErrorMessage>
                            {form.errors.tickets &&
                              form.errors.tickets[selectedTicket] &&
                              form.errors.tickets[selectedTicket].name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box w={["100%", "100%", "26%"]} my={2}>
                    <Field name={`tickets.${selectedTicket}.color`}>
                      {({ field, form }) => (
                        <FormControl
                          variant="floating"
                          borderRadius={4}
                          id={`tickets.${selectedTicket}.color`}
                          isInvalid={
                            form.errors.tickets &&
                            form.errors.tickets[selectedTicket] &&
                            form.errors.tickets[selectedTicket].color
                          }
                        >
                          <Input {...field} type={"color"} pt={1} />
                          <FormLabel>Color</FormLabel>

                          <FormErrorMessage>
                            {form.errors.tickets &&
                              form.errors.tickets[selectedTicket] &&
                              form.errors.tickets[selectedTicket].color}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box w={"100%"} my={2}>
                    <Field name={`tickets.${selectedTicket}.description`}>
                      {({ field, form }) => (
                        <FormControl
                          variant="floating"
                          borderRadius={4}
                          id={`tickets.${selectedTicket}.description`}
                          isInvalid={
                            form.errors.tickets &&
                            form.errors.tickets[selectedTicket] &&
                            form.errors.tickets[selectedTicket].description
                          }
                        >
                          <Textarea
                            placeholder=" "
                            {...field}
                            maxLength={100}
                          />

                          <FormLabel>Descripción</FormLabel>
                          <FormErrorMessage>
                            {form.errors.tickets &&
                              form.errors.tickets[selectedTicket] &&
                              form.errors.tickets[selectedTicket].description}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box w={["100%", "100%", "20%"]} my={2}>
                    <Field name={`tickets.${selectedTicket}.amount`}>
                      {({ field, form }) => (
                        <FormControl
                          variant="floating"
                          borderRadius={4}
                          id={`tickets.${selectedTicket}.amount`}
                          isInvalid={
                            form.errors.tickets &&
                            form.errors.tickets[selectedTicket] &&
                            form.errors.tickets[selectedTicket].amount
                          }
                          isRequired
                        >
                          <NumberInput
                            {...field}
                            onChange={(valueString) => {
                              let newValue = parseInt(parsePrice(valueString));
                              if (newValue > 99999) {
                                newValue = 99999;
                              } else if (newValue < 1) {
                                newValue = 1;
                              }
                              formik.setFieldValue(
                                `tickets.${selectedTicket}.amount`,
                                newValue.toString()
                              );
                            }}
                            format={(value) =>
                              priceFormatter(
                                parseFloat(parsePrice(value as string))
                              )
                            }
                          >
                            <NumberInputField {...field} placeholder=" " />
                            <FormLabel>Cantidad</FormLabel>
                            <FormHelperText>
                              Cantidad disponible para la venta. Podrás
                              modificarla luego
                            </FormHelperText>
                          </NumberInput>

                          <FormErrorMessage>
                            {form.errors.tickets &&
                              form.errors.tickets[selectedTicket] &&
                              form.errors.tickets[selectedTicket].amount}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box w={["100%", "100%", "20%"]} my={2}>
                    <Field
                      name={`tickets.${selectedTicket}.maxQuantityPerUser`}
                    >
                      {({ field, form }) => (
                        <FormControl
                          variant="floating"
                          borderRadius={4}
                          id={`tickets.${selectedTicket}.maxQuantityPerUser`}
                          isInvalid={
                            form.errors.tickets &&
                            form.errors.tickets[selectedTicket] &&
                            form.errors.tickets[selectedTicket]
                              .maxQuantityPerUser
                          }
                        >
                          <NumberInput {...field} min={1}>
                            <NumberInputField placeholder=" " {...field} />
                            <FormLabel>Máximo</FormLabel>
                          </NumberInput>

                          <FormErrorMessage>
                            {form.errors.tickets &&
                              form.errors.tickets[selectedTicket] &&
                              form.errors.tickets[selectedTicket]
                                .maxQuantityPerUser}
                          </FormErrorMessage>
                          <FormHelperText>
                            Los usuarios no podrán exceder esta cantidad de
                            tickets en su compra
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box w={["100%", "100%", "20%"]} my={2}>
                    <Field
                      name={`tickets.${selectedTicket}.minimumQuantityPerUser`}
                    >
                      {({ field, form }) => (
                        <FormControl
                          variant="floating"
                          borderRadius={4}
                          id={`tickets.${selectedTicket}.minimumQuantityPerUser`}
                          isInvalid={
                            form.errors.tickets &&
                            form.errors.tickets[selectedTicket] &&
                            form.errors.tickets[selectedTicket]
                              .minimumQuantityPerUser
                          }
                        >
                          <NumberInput
                            {...field}
                            min={1}
                            max={
                              formik.values.tickets[selectedTicket]
                                .maxQuantityPerUser
                            }
                          >
                            <NumberInputField placeholder=" " {...field} />
                            <FormLabel>Mínimo</FormLabel>
                          </NumberInput>

                          <FormErrorMessage>
                            {form.errors.tickets &&
                              form.errors.tickets[selectedTicket] &&
                              form.errors.tickets[selectedTicket]
                                .minimumQuantityPerUser}
                          </FormErrorMessage>
                          <FormHelperText>
                            Los usuarios deberán comprar esta cantidad de
                            tickets como mínimo
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box w={["100%", "100%", "20%"]} my={2}>
                    <Field name={`tickets.${selectedTicket}.price`}>
                      {({ field, form }) => (
                        <FormControl
                          variant="floating"
                          borderRadius={4}
                          id={`tickets.${selectedTicket}.price`}
                          isInvalid={
                            form.errors.tickets &&
                            form.errors.tickets[selectedTicket] &&
                            form.errors.tickets[selectedTicket].price
                          }
                          isRequired
                        >
                          <NumberInput
                            {...field}
                            onChange={(valueString) => {
                              let newValue = parseInt(parsePrice(valueString));
                              if (newValue > 10000000) {
                                newValue = 10000000;
                              } else if (newValue < 1) {
                                newValue = 1;
                              }
                              formik.setFieldValue(
                                `tickets.${selectedTicket}.price`,
                                newValue.toString()
                              );
                            }}
                            format={(value) =>
                              priceFormatter(
                                parseFloat(parsePrice(value as string))
                              )
                            }
                          >
                            <NumberInputField placeholder=" " {...field} />
                            <FormLabel>Precio</FormLabel>
                            <FormHelperText>
                              Precio del ticket. No incluye el costo de
                              servicio.
                            </FormHelperText>
                          </NumberInput>

                          <FormErrorMessage>
                            {form.errors.tickets &&
                              form.errors.tickets[selectedTicket] &&
                              form.errors.tickets[selectedTicket].price}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>

                  <Divider my={5} />
                  <Box w={["100%", "100%", "48%"]}>
                    <Popover
                      placement="bottom"
                      isOpen={isCalendarOpen}
                      onClose={onCalendarClose}
                      initialFocusRef={initialRef}
                    >
                      <PopoverTrigger>
                        <Flex
                          direction={"column"}
                          onClick={onCalendarOpen}
                          ref={initialRef}
                        >
                          <Box my={2}>
                            <Field
                              name={`tickets.${selectedTicket}.openingDate.label`}
                            >
                              {({ field, form }) => (
                                <FormControl
                                  variant="floating"
                                  borderRadius={4}
                                  id={`tickets.${selectedTicket}.openingDate.label`}
                                  isInvalid={
                                    form.errors.tickets &&
                                    form.errors.tickets[selectedTicket] &&
                                    form.errors.tickets[selectedTicket]
                                      .openingDate
                                  }
                                  isRequired
                                >
                                  <Input
                                    {...field}
                                    name={`openingDate`}
                                    placeholder="dd/MM/yyyy"
                                    onChange={handleInputChange}
                                    ref={startInputRef}
                                  />
                                  <FormLabel>Apertura de venta</FormLabel>
                                  <FormErrorMessage>
                                    {form.errors.tickets &&
                                    form.errors.tickets[selectedTicket]
                                      ? form.errors.tickets[selectedTicket]
                                          .openingDate?.label
                                      : ""}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                          </Box>
                          <Box my={2}>
                            <Field
                              name={`tickets.${selectedTicket}.closingDate.label`}
                            >
                              {({ field, form }) => (
                                <FormControl
                                  variant="floating"
                                  borderRadius={4}
                                  id={`tickets.${selectedTicket}.closingDate.label`}
                                  isInvalid={
                                    form.errors.tickets &&
                                    form.errors.tickets[selectedTicket] &&
                                    form.errors.tickets[selectedTicket]
                                      .openingDate
                                  }
                                  isRequired
                                >
                                  <Input
                                    {...field}
                                    name={`closingDate`}
                                    placeholder="dd/MM/yyyy"
                                    onChange={handleInputChange}
                                    ref={endInputRef}
                                  />
                                  <FormLabel>Cierre de venta</FormLabel>
                                  <FormErrorMessage>
                                    {form.errors.tickets &&
                                    form.errors.tickets[selectedTicket]
                                      ? form.errors.tickets[selectedTicket]
                                          .closingDate?.label
                                      : ""}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                          </Box>
                        </Flex>
                      </PopoverTrigger>

                      <PopoverContent
                        p={0}
                        ref={calendarRef}
                        bgColor={"backgroundColor"}
                      >
                        <ThemedCalendar
                          disablePastDates={add(new Date(), { days: -1 })}
                          disableFutureDates={maxDate}
                          allowSelectSameDay
                          value={dates}
                          onSelectDate={handleSelectDate}
                        >
                          <PopoverBody w={"xs"} p={0}>
                            <CalendarControls>
                              <CalendarPrevButton />
                              <CalendarNextButton />
                            </CalendarControls>

                            <CalendarMonths>
                              <CalendarMonth>
                                <CalendarMonthName />
                                <CalendarWeek />
                                <CalendarDays />
                              </CalendarMonth>
                            </CalendarMonths>
                          </PopoverBody>
                        </ThemedCalendar>
                      </PopoverContent>
                    </Popover>
                  </Box>
                  <Box
                    w={["100%", "100%", "48%"]}
                    display={"flex"}
                    flexDir={"column"}
                  >
                    <Box w={"100%"} my={2}>
                      <Field name={`tickets.${selectedTicket}.openingHour`}>
                        {({ field, form }) => (
                          <FormControl
                            variant="floating"
                            borderRadius={4}
                            id={`tickets.${selectedTicket}.openingHour`}
                            isInvalid={
                              form.errors.tickets &&
                              form.errors.tickets[selectedTicket] &&
                              form.errors.tickets[selectedTicket].openingHour
                            }
                            isRequired
                          >
                            <Input
                              {...field}
                              type={"time"}
                              placeholder="00:00"
                            />

                            <FormLabel>Horario de apertura</FormLabel>

                            <FormErrorMessage>
                              {form.errors.tickets &&
                                form.errors.tickets[selectedTicket] &&
                                form.errors.tickets[selectedTicket].openingHour}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    <Box w={"100%"} my={2}>
                      <Field name={`tickets.${selectedTicket}.closingHour`}>
                        {({ field, form }) => (
                          <FormControl
                            variant="floating"
                            borderRadius={4}
                            id={`tickets.${selectedTicket}.closingHour`}
                            isInvalid={
                              form.errors.tickets &&
                              form.errors.tickets[selectedTicket] &&
                              form.errors.tickets[selectedTicket].closingHour
                            }
                            isRequired
                          >
                            <Input
                              type={"time"}
                              {...field}
                              placeholder="00:00"
                            />
                            <FormLabel>Horario de cierre</FormLabel>
                            <FormErrorMessage>
                              {form.errors.tickets &&
                                form.errors.tickets[selectedTicket] &&
                                form.errors.tickets[selectedTicket].closingHour}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                  </Box>
                  <Box w={"100%"}>
                    <Text color={"muted.main"}>
                      Este tipo de ticket solo estará disponible desde la fecha
                      de apertura hasta la fecha de cierre
                    </Text>
                  </Box>
                </Flex>

                <Center>
                  <Stack direction={["column", "column", "row"]} spacing={5}>
                    {formik.values.tickets.length > 1 && (
                      <Button
                        colorScheme={"red"}
                        borderRadius="full"
                        variant={"outline"}
                        isLoading={false}
                        w={{ xs: "fit-content", md: "30%" }}
                        onClick={onModalConfirmOpen}
                      >
                        {"Eliminar ticket"}
                      </Button>
                    )}
                    <Button
                      colorScheme={"primary"}
                      borderRadius="full"
                      variant={"outline"}
                      isLoading={false}
                      w={{ xs: "fit-content", md: "30%" }}
                      onClick={() => {
                        const length = formik.values.tickets.length;
                        push({
                          name: "",
                          amount: "",
                          maxQuantityPerUser: "",
                          minimumQuantityPerUser: "",
                          price: 0,
                          description: "",
                          color: "primary.main",
                          absorbed: false,
                          openingDate: "",
                          closingDate: "",
                          openingHour: "00:00",
                          closingHour: "00:00",
                        });
                        setSelectedTicket(length);
                      }}
                    >
                      {"Añadir otro ticket"}
                    </Button>
                  </Stack>
                </Center>
              </Stack>
            );
          }}
        </FieldArray>
      </FormikProvider>
      <ConfirmActionModal
        title={
          <Text>
            ¿Deseas eliminar tipo de ticket
            {formik.values.tickets[selectedTicket]?.name ? (
              <strong style={{ color: "primary.main" }}>
                {" " + formik.values.tickets[selectedTicket].name}
              </strong>
            ) : (
              ""
            )}
            ?
          </Text>
        }
        confirmationMessage="Al eliminar este tipo de entrada se borrarán todos los tickets asociados a ella"
        isOpen={isModalConfirmOpen}
        onClose={onModalConfirmClose}
        onConfirm={() => {
          boundArrayHelpers?.remove(selectedTicket);
          setSelectedTicket(
            selectedTicket - 1 < 0 ? selectedTicket : selectedTicket - 1
          );
        }}
      />
    </EventStep>
  );
};

export default TicketsForm;
