import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { Field, FormikProvider, useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import Card from "../../../../../components/Card";
import CustomTable from "../../../../../components/Table";
import { useReservationsService } from "../../../../../services/Admin/reservations/reservations.service";
import { TicketVariantDTO } from "../../../../../services/events/dto";
import { StyledSelectProps } from "../../../../../theme/additions/select/Select";
import { useAsync } from "../../../../../utils/useAsync";
import CancelReservationsModal from "./CancelReservationsModal";
import { useToaster } from "../../../../../utils/useToaster";

type Props = {
  variants: TicketVariantDTO[];
  variantsLoading: boolean;
  variantsError: any;
  variantsExecute: () => void;
  activeFunctionId: string;
};

type ReservationsFields = {
  variant: string;
  quantity?: number;
};

export const Reservations = (props: Props) => {
  const mappedVariants = useMemo(
    () =>
      props.variants
        .filter((variant) => variant.available > 0 && !variant.isExpired)
        .map((variant) => ({
          value: variant._id,
          label: variant.name,
          available: variant.available,
        })),
    [props.variants]
  );

  const ReservationsSchema = useMemo(
    () =>
      Yup.object().shape({
        variant: Yup.string().required("Debe elegir una categoría"),
        quantity: Yup.number()
          .min(1, "Cantidad inválida")
          .required("Debe elegir una cantidad")
          .test(
            "format",
            "No hay suficientes entradas de esta variante",
            function (quantity) {
              if (this.parent.variant) {
                const selectedVariant = mappedVariants.find(
                  (variant) => variant.value === this.parent.variant
                );
                if (selectedVariant) {
                  return selectedVariant.available >= quantity;
                }
              }
              return true;
            }
          ),
      }),
    [mappedVariants]
  );

  const { successToast, errorToast } = useToaster();

  const reservationsService = useReservationsService();

  const [reservations, , reservationsLoading, reservationsExecute] = useAsync(
    reservationsService.getRegularReservations,
    {
      defaultValue: { ticketReserved: [] },
    }
  );

  useEffect(() => {
    if (props.activeFunctionId) {
      reservationsExecute(props.activeFunctionId);
    }
  }, [props.activeFunctionId]);

  const [createReservationLoading, setCreateReservationLoading] =
    useState(false);

  const handleSubmit = async (values: ReservationsFields) => {
    try {
      setCreateReservationLoading(true);
      await reservationsService.createRegularReservation(
        props.activeFunctionId,
        {
          ticketReserved: [
            { ticketVariant: values.variant, quantity: values.quantity },
          ],
        }
      );

      successToast(
        "Reserva creada con éxito",
        `Se envió creo la reserva de tickets`
      );
    } catch {
      errorToast("Ocurrió un error", `Inténtalo de nuevo mas tarde`);
    } finally {
      props.variantsExecute();
      reservationsExecute(props.activeFunctionId);
      setCreateReservationLoading(false);
    }
  };

  const formik = useFormik<ReservationsFields>({
    initialValues: {
      variant: "",
      quantity: 1,
    },
    validationSchema: ReservationsSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const columns = [
    {
      Header: "Tipo de ticket",
      accessor: "name",

      isCentered: true,
    },
    {
      Header: "Cantidad de reservadas",
      accessor: "reserved",
      isCentered: true,
    },
    {
      Header: "Acciones",
      isCentered: true,
      accessor: "data",
      Cell: (cell) => (
        <CancelReservationsModal
          limit={Number(cell.value.quantity)}
          onSubmit={async (amount: number) => {
            try {
              await reservationsService.cancelRegularReservation(
                props.activeFunctionId,
                {
                  ticketReserved: [
                    {
                      ticketVariant: cell.value.ticketVariant,
                      quantity: amount,
                    },
                  ],
                }
              );
              successToast(
                "Reserva cancelada con éxito",
                `Se cancelaron las reservas indicadas`
              );
            } catch {
              errorToast("Ocurrió un error", `Inténtalo de nuevo mas tarde`);
            } finally {
              reservationsExecute(props.activeFunctionId);
            }
          }}
        />
      ),
    },
  ];

  const pickedVariant = useMemo(
    () =>
      mappedVariants.find((variant) => variant.value === formik.values.variant),
    [formik.values, mappedVariants]
  );

  return (
    <FormikProvider value={formik}>
      <Stack spacing={[7, 9]} p={[5, 7]}>
        <Box display={"flex"}>
          <InfoOutlineIcon mr={2} mt={1} />
          <Text flexGrow={1} fontSize={16} color={"muted.600"}>
            Al reservar tickets, estos serán retirados de los disponibles para
            la venta.
          </Text>
        </Box>
        <Grid
          templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]}
          columnGap={20}
          rowGap={10}
        >
          <GridItem rowSpan={2} colSpan={1}>
            <Stack spacing={5}>
              <Text>Cantidad a reservar</Text>
              <Field name={"quantity"}>
                {({ field, form }) => (
                  <FormControl
                    variant="floating"
                    colorScheme={"secondary"}
                    borderRadius={4}
                    id="quantity"
                    isRequired
                    isInvalid={form.errors.quantity}
                  >
                    <NumberInput {...field}>
                      <NumberInputField {...field} placeholder=" " min={1} />
                      <FormLabel>Cantidad</FormLabel>
                    </NumberInput>
                    {pickedVariant && (
                      <FormHelperText>
                        Tickets disponibles en venta:
                        <b>{" " + pickedVariant.available}</b>
                      </FormHelperText>
                    )}
                    <FormErrorMessage>{form.errors.quantity}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>
          </GridItem>
          <GridItem rowSpan={2} colSpan={1}>
            <Stack spacing={5}>
              <Text>Ticket a reservar</Text>
              <Field name={"variant"}>
                {({ field, form }) => (
                  <FormControl
                    variant="floating"
                    borderRadius={4}
                    colorScheme={"secondary"}
                    id="variant"
                    isRequired
                    isInvalid={form.errors.variant}
                  >
                    <Select
                      {...StyledSelectProps}
                      // isSearchable={false}
                      options={mappedVariants}
                      isLoading={props.variantsLoading}
                      isDisabled={props.variantsLoading}
                      placeholder=" "
                      noOptionsMessage={() => "No hay resultados"}
                      loadingMessage={() => "Cargando..."}
                      {...field}
                      value={mappedVariants.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(value: any) => {
                        formik.setFieldValue(field.name, value.value);
                      }}
                    />

                    <FormLabel>Tipo de ticket</FormLabel>
                    <FormErrorMessage>{form.errors.variant}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>
          </GridItem>
        </Grid>
      </Stack>

      <Card borderColor={"muted.400"} borderWidth={1}>
        <CustomTable
          columns={columns}
          data={reservations.ticketReserved.map((variant) => {
            return {
              name: mappedVariants.find(
                (v) => v.value === variant.ticketVariant
              ).label,
              reserved: variant.quantity,
              data: variant,
            };
          })}
          loading={props.variantsLoading || reservationsLoading}
          title="Asientos reservados"
          searchProps={{
            enabled: false,
          }}
          stylesTableContainer={{
            background: "transparent",
          }}
          paginatorOptions={{
            enabled: true,
            type: "advanced",
          }}
        />
      </Card>
      <Button
        isLoading={createReservationLoading}
        borderRadius={"full"}
        colorScheme={"primary"}
        textAlign={"center"}
        alignSelf={"center"}
        maxW={"xs"}
        w={"100%"}
        disabled={props.variantsLoading || createReservationLoading}
        my={5}
        px={10}
        onClick={formik.submitForm}
      >
        RESERVAR
      </Button>
    </FormikProvider>
  );
};
