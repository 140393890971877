import { Box, Text } from "@chakra-ui/react";
import React from "react";
import {
  EventsStatusesDict,
  EventStatus,
  EventStatusMeta,
} from "../../../../utils/eventsStatus";

type EventStatusCardProps = {
  eventStatus: EventStatus;
  eventStatusMeta: EventStatusMeta;
};

const EventStatusCard = (props: EventStatusCardProps) => {
  const statusProps = EventsStatusesDict[props.eventStatus];
  //TODO: Consider all status in order to remove this logic
  const shouldShow = ["Finished", "SoldOut", "Upcoming"].includes(
    props.eventStatus
  );

  return !shouldShow ? null : (
    <Box
      mx={{ base: "4", md: "7%" }}
      py={{ base: "5", md: "25" }}
      px={4}
      borderRadius={20}
      bgColor={statusProps.colorScheme + ".main"}
      textAlign={"center"}
    >
      <Text
        textTransform={statusProps.detailLabel ? "none" : "uppercase"}
        fontWeight={600}
        fontSize={20}
      >
        {statusProps.detailLabel ?? statusProps.label}
      </Text>
      {statusProps.message && (
        <Text fontSize={20}>
          {statusProps.message instanceof Function
            ? statusProps.message(props.eventStatusMeta)
            : statusProps.message}
        </Text>
      )}
    </Box>
  );
};

export default EventStatusCard;
