import { Text } from "@chakra-ui/react";
import { formatFullDateText } from "./dateFormatters";

export type EventStatus =
  | "Approved"
  | "Pending"
  | "Denied"
  | "Canceled"
  | "Review"
  | "Finished"
  | "Started"
  | "Upcoming"
  | "Published"
  | "Rescheduled"
  | "SoldOut"
  | "Suspended";

export type EventStatusMeta = {
  sellStartDate?: Date;
};

export const EventsStatusesDict: {
  [key in EventStatus]: {
    name: EventStatus;
    label: string;
    detailLabel?: string;
    message?:
      | string
      | JSX.Element
      | ((meta: EventStatusMeta) => string | JSX.Element);
    colorScheme: string;
  };
} = {
  Approved: {
    name: "Approved",
    label: "Aprobado",
    colorScheme: "green",
  },
  Pending: {
    name: "Pending",
    label: "Pendiente",
    colorScheme: "blue",
  },
  Denied: {
    name: "Denied",
    label: "Rechazado",
    colorScheme: "red",
  },
  Canceled: {
    name: "Canceled",
    label: "Cancelado",
    colorScheme: "red",
  },
  Review: {
    name: "Review",
    label: "En revisión",
    colorScheme: "yellow",
  },
  Finished: {
    name: "Finished",
    label: "Finalizado",
    detailLabel: "Venta FINALIZADA",
    message: "Dirígete al home para ver más funciones.",
    colorScheme: "secondary",
  },
  Started: {
    name: "Started",
    label: "En curso",
    colorScheme: "pink",
  },
  Suspended: {
    name: "Suspended",
    label: "Suspendido",
    colorScheme: "purple",
  },
  Rescheduled: {
    name: "Rescheduled",
    label: "Reprogramado",
    colorScheme: "purple",
  },
  Published: {
    name: "Published",
    label: "Publicado",
    colorScheme: "orange",
  },
  SoldOut: {
    name: "SoldOut",
    label: "Agotado",
    colorScheme: "red",
    message:
      "En este momento no contamos con entradas disponibles, dirígete al home para ver más funciones.",
  },
  Upcoming: {
    name: "Upcoming",
    label: "Próximamente",
    colorScheme: "purple",
    message: (meta) => (
      <Text>
        Las entradas para el evento estarán disponibles a partir del{" "}
        <strong>{formatFullDateText(meta.sellStartDate)}</strong>
      </Text>
    ),
  },
};
