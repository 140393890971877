import {
  Box,
  Flex,
  Skeleton,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import React from "react";
import Card from "../../../../components/Card";

export type StatCardProps = {
  loading: boolean;
  title: string;
  value: string;
  extra?: { value: string; color: string };
};

const StatCard = (props: StatCardProps) => {
  return (
    <Card minH="83px">
      <Box display={"flex"} w="full">
        <Flex flexDirection="row" align="center" justify="center" w="100%">
          <Stat me="auto">
            <Skeleton
              mb={1}
              width={props.loading ? "70%" : "unset"}
              isLoaded={!props.loading}
            >
              <StatLabel
                fontSize="sm"
                fontWeight="normal"
                pb=".1rem"
                color={"muted.600"}
              >
                {props.title}
              </StatLabel>
            </Skeleton>
            <Skeleton
              display={"flex"}
              width={props.loading ? "50%" : "unset"}
              isLoaded={!props.loading}
            >
              <StatNumber fontWeight="medium" fontSize="md" color={"muted.600"}>
                {props.value}
              </StatNumber>

              {props.extra && (
                <StatHelpText
                  alignSelf="flex-end"
                  justifySelf="flex-end"
                  m="0px"
                  color={props.extra?.color}
                  fontWeight="semibold"
                  ps="7px"
                  fontSize="sm"
                >
                  {props.extra?.value}
                </StatHelpText>
              )}
            </Skeleton>
          </Stat>
        </Flex>
      </Box>
    </Card>
  );
};

export default StatCard;
