import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useUserEventsService } from "../../../services/Admin/events/userEventsService";
import { formateDateAndHour } from "../../../utils/dateFormatters";
import { useAsync } from "../../../utils/useAsync";
import { EventAdminInfoController } from "./interfaces";
import { useToaster } from "../../../utils/useToaster";
import { useDiscountCodesService } from "../../../services/Admin/discountCodes/useDiscountCodesService";

const initialFun = { value: "all", label: "Todas las funciones" };

export const useEventAdminInfoController = (): EventAdminInfoController => {
  const { id } = useParams();

  const { successToast, errorToast } = useToaster();

  const [reportGenerating, setReportGenerating] = useState(false);

  const location = useLocation();

  const currentInfoSection = useMemo(
    () => location.pathname.split("/").at(-1),
    [location]
  );

  const userEventService = useUserEventsService();

  const [eventInfo, , eventLoading, eventExecute] = useAsync(
    userEventService.getUserEvent
  );

  useEffect(() => {
    if (id) {
      eventExecute(id);
    }
  }, [id]);

  const mappedFunctions = useMemo(() => {
    const functions = eventInfo?.functions
      ? eventInfo.functions.map((fun, index) => ({
          value: fun._id,
          label: formateDateAndHour(new Date(fun.datetime)),
          token: fun.tokens[0],
          eventFunctionChartKey: fun.eventFunctionChartKey,
        }))
      : [];
    return [initialFun, ...functions];
  }, [eventInfo]);

  const [activeFunction, setActiveFunction] = useState(initialFun);

  useEffect(() => {
    if (mappedFunctions.length === 2) {
      setActiveFunction(mappedFunctions[1]);
    }
  }, [mappedFunctions]);

  const generateReport = async () => {
    try {
      setReportGenerating(true);
      const report = await userEventService.getFunctionReport(
        activeFunction.value
      );
      const blob = new Blob([report], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", eventInfo.name + "-" + activeFunction.label);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      successToast("Descargado con éxito", "Su reporte se generó exitosamente");
    } catch (error) {
      console.error(error);
      errorToast("Ocurrió un error", "Intente más tarde");
    } finally {
      setReportGenerating(false);
    }
  };

  const [ticketVariants, , ticketVariantsLoading, ticketVariantsExecute] =
    useAsync(userEventService.getTicketVariants);

  const [createVariant, , createVariantLoading, createVariantExecute] =
    useAsync(userEventService.createNewTicketVariant, {
      onSuccess: () => {
        ticketVariantsExecute(activeFunction.value);
        successToast("Tipo de Ticket editado", "");
      },
      onError: () => {
        errorToast("Error al editar Ticket", "");
      },
    });
  const [editVariant, , editVariantLoading, editVariantExecute] = useAsync(
    userEventService.editTicketVariant,
    {
      onSuccess: () => {
        ticketVariantsExecute(activeFunction.value);
        successToast("Ticket editado", "");
      },
      onError: () => {
        errorToast("Error al editar Ticket", "");
      },
    }
  );

  const discountCodesService = useDiscountCodesService();
  const [
    discountCodesList,
    ,
    discountCodesListLoading,
    discountCodesListExecute,
  ] = useAsync(discountCodesService.getDiscountCodesList);

  useEffect(() => {
    if (activeFunction.value !== "all") {
      discountCodesListExecute(activeFunction.value);
      ticketVariantsExecute(activeFunction.value);
    }
  }, [activeFunction.value]);

  const [, , creatingLoading, createExecute] = useAsync(
    discountCodesService.postDiscountCode,
    {
      onSuccess: () => {
        discountCodesListExecute(activeFunction.value);
        successToast(
          "Código de descuento creado",
          "Tu código de descuento ha sido creado"
        );
      },
      onError: () => {
        errorToast("Error al crear código de descuento", "");
      },
    }
  );
  const [, , editLoading, editExecute] = useAsync(
    discountCodesService.editDiscountCode,
    {
      onSuccess: () => {
        discountCodesListExecute(activeFunction.value);
        successToast(
          "Código modificado exitosamente",
          "Tu código ha sido modificado exitosamente."
        );
      },
      onError: () => {
        errorToast(
          "Error al modificar código",
          "Hubo un error al modificar tu código de descuento"
        );
      },
    }
  );

  const [, , , statusToActiveExecute] = useAsync(
    discountCodesService.changeStatusToActive,
    {
      onSuccess: () => {
        discountCodesListExecute(activeFunction.value);
      },
      onError: () => {
        errorToast("Error al activar los códigos de descuento", "");
      },
    }
  );
  const [, , , statusToInactiveExecute] = useAsync(
    discountCodesService.changeStatusToInactive,
    {
      onSuccess: () => {
        discountCodesListExecute(activeFunction.value);
      },
      onError: () => {
        errorToast("Error al desactivar los códigos de descuento", "");
      },
    }
  );
  const [, , , deleteDiscountCodeExecute] = useAsync(
    discountCodesService.deleteDiscountCode,
    {
      onSuccess: () => {
        discountCodesListExecute(activeFunction.value);
        successToast(
          "Código eliminado exitosamente",
          "Tu código de desuento ha sido eliminado correctamente"
        );
      },
      onError: () => {
        errorToast(
          "Error al eliminar código",
          "Hubo un error al eliminar tu código de descuento"
        );
      },
    }
  );

  return {
    currentEventId: id,
    eventInfo,
    eventLoading,
    eventFunctions: mappedFunctions,
    currentInfoSection,
    onFunctionSelect: setActiveFunction,
    activeFunction,
    generateReport,
    reportGenerating,
    discountCodesList,
    discountCodesListLoading,
    creatingLoading,
    editLoading,
    editExecute,
    discountCodesListExecute,
    createExecute,
    statusToActiveExecute,
    statusToInactiveExecute,
    deleteDiscountCodeExecute,
    ticketVariants,
    ticketVariantsLoading,
    ticketVariantsExecute,
    createVariant,
    createVariantLoading,
    createVariantExecute,
    editVariant,
    editVariantLoading,
    editVariantExecute,
  };
};
