import {
  Box,
  Collapse,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useBreakpointValue,
  Checkbox,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import { Field, FormikProps, FormikProvider } from "formik";
import { useEffect, useState } from "react";

import { usePlacesSearchService } from "../../../../services/Admin/placesService/placesService";

import { debounce } from "../../../../utils/debounce";
import { PlaceOption } from "../interfaces";
import { EventStep } from "./step";
import {
  EventDataFormFields,
  useEventFormStore,
} from "../../../../services/createEvent/createEventStore";

import { AsyncSelect, GroupBase, Select } from "chakra-react-select";
import { PlaceResult } from "../../../../services/Admin/placesService/dto";
import { StyledSelectProps } from "../../../../theme/additions/select/Select";
import { ValidationState } from "../../../../utils/useEmailValidator";
import { EmailInput } from "../../../../components/EmailInput";

export type EventDataFormProps = {
  formik: FormikProps<EventDataFormFields>;
  validationState: ValidationState;
};

const visibilityOptions = [
  { label: "Público", value: "Público" },
  { label: "Privado", value: "Privado" },
];

const categoryOptions = [
  { label: "Concierto", value: "Concierto" },
  { label: "Fiesta", value: "Fiesta" },
  { label: "Festival", value: "Festival" },
  {
    label: "Teatro",
    value: "Teatro",
  },
  {
    label: "Deporte",
    value: "Deporte",
  },
   {
    label: "Evento Empresarial",
    value: "Evento Empresarial",
  },
];

const restrictions = ["ATP", "+18"];

const EventDataForm = ({ formik, validationState }: EventDataFormProps) => {
  const columnsResponsive = useBreakpointValue({
    xs: 1,
    md: 2,
  });

  const { setEvent, setFormData } = useEventFormStore();

  useEffect(() => {
    setEvent({
      title: formik.values.name,
      location: formik.values.placeName,
      blackTags: formik.values.restriction ? [formik.values.restriction] : [],
      grayTags: formik.values.category ? [formik.values.category] : [],
    });
  }, [formik.values]);

  const placesService = usePlacesSearchService();

  const [placesResults, setPlacesResults] = useState<PlaceOption[]>(null);

  const mapResults = (data?: PlaceResult[]) => {
    return data?.map((item) => ({
      label: item.formatted_address,
      value: item,
    }));
  };

  useEffect(() => {
    setFormData({
      eventData: formik.values,
    });
  }, [formik.values]);

  return (
    <EventStep buttonProps={{ onClick: formik.submitForm }}>
      <FormikProvider value={formik}>
        <Stack spacing={[10, 10]} p={[5, 10]} maxW="100%">
          <Text fontSize={{ base: "md", md: "lg" }} w="85%">
            ¡Ingresa los datos de tu evento!
          </Text>
          <Stack spacing={10}>
            <SimpleGrid columns={columnsResponsive} spacing={10}>
              <Field name={"name"}>
                {({ field, form }) => (
                  <FormControl
                    variant="floating"
                    borderRadius={4}
                    id="name"
                    isInvalid={form.errors.name}
                    isRequired
                  >
                    <Input {...field} placeholder=" " maxLength={50} />
                    <FormLabel>Nombre del Evento</FormLabel>
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name={"category"}>
                {({ field, form }) => (
                  <FormControl
                    variant="floating"
                    borderRadius={4}
                    id="category"
                    isInvalid={form.errors.category}
                    isRequired
                  >
                    <Select
                      {...StyledSelectProps}
                      isSearchable={false}
                      options={categoryOptions}
                      placeholder=" "
                      {...field}
                      value={categoryOptions.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(value: any) => {
                        formik.setFieldValue(field.name, value.value);
                      }}
                    />
                    <FormLabel>Categoría</FormLabel>
                    <FormErrorMessage>{form.errors.category}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"type"}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.type}
                    borderRadius={4}
                    id="type"
                    isRequired
                    h="auto"
                    variant={"floating"}
                  >
                    <Select
                      {...StyledSelectProps}
                      isSearchable={false}
                      options={visibilityOptions}
                      placeholder=" "
                      {...field}
                      value={visibilityOptions.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(value: any) => {
                        formik.setFieldValue(field.name, value.value);
                      }}
                    />
                    <FormLabel>Visibilidad</FormLabel>
                    <FormErrorMessage>{form.errors.type}</FormErrorMessage>
                    <FormHelperText>
                      *Los eventos privados, jamás serán publicados en nuestro
                      sitio y podrán ser usados por empresas o particulares para
                      administrar sus entradas, usando nuestra plataforma.
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
              <Field type={"radio"} name={"restriction"}>
                {({ field, form }) => (
                  <FormControl
                    borderRadius={4}
                    isInvalid={form.errors.restriction}
                    id="restriction"
                    isRequired
                    h="fit-content"
                  >
                    <RadioGroup
                      name="restriction"
                      defaultValue={
                        restrictions.find(
                          (restriction) =>
                            formik?.values &&
                            restriction === formik.values.restriction
                        ) ?? ""
                      }
                    >
                      <Stack direction={"row"} spacing={5}>
                        <Text>Clasificación: </Text>
                        <Stack>
                          {restrictions.map((restriction) => (
                            <Radio
                              {...field}
                              value={restriction}
                              name="restriction"
                              colorScheme={"primary"}
                              borderColor={"muted.200"}
                            >
                              {restriction}
                            </Radio>
                          ))}

                          <Radio
                            {...field}
                            name="restriction"
                            colorScheme={"primary"}
                            borderColor={"muted.200"}
                            value=""
                          >
                            Otro
                          </Radio>
                          <Collapse
                            in={
                              formik.values.restriction !== "ATP" &&
                              formik.values.restriction !== "+18"
                            }
                            animateOpacity
                          >
                            <Input
                              maxLength={20}
                              defaultValue={
                                restrictions.find(
                                  (restriction) =>
                                    formik?.values &&
                                    restriction === formik.values.restriction
                                )
                                  ? ""
                                  : formik?.values.restriction
                              }
                              variant={"flushed"}
                              borderBottomColor={"primary.main"}
                              _placeholder={{ color: "muted.200" }}
                              placeholder="Otro"
                              {...field}
                            />
                          </Collapse>
                        </Stack>
                      </Stack>
                    </RadioGroup>
                    <FormErrorMessage>
                      {form.errors.restriction}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </SimpleGrid>

            <SimpleGrid columns={columnsResponsive} spacing={10}>
              <Field name={"placeName"}>
                {({ field, form }) => (
                  <FormControl
                    variant="floating"
                    borderRadius={4}
                    id="placeName"
                    isRequired
                    isInvalid={form.errors.placeName}
                  >
                    <Input
                      placeholder=" "
                      maxLength={50}
                      {...field}
                      _autofill={{
                        boxShadow: "transparent",
                        transition: "background-color 5000s ease-in-out 0s",
                      }}
                    />
                    <FormLabel>Lugar del Evento</FormLabel>
                    <FormErrorMessage>{form.errors.placeName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name={"place"}>
                {({ field, form }) => (
                  <FormControl
                    variant="floating"
                    borderRadius={4}
                    id="place"
                    isRequired
                    isInvalid={form.errors.place}
                  >
                    <AsyncSelect<PlaceOption, true, GroupBase<PlaceOption>>
                      {...field}
                      name="place"
                      options={placesResults}
                      placeholder=" "
                      noOptionsMessage={() => "No hay resultados"}
                      loadingMessage={() => "Cargando..."}
                      closeMenuOnSelect={true}
                      size="md"
                      onChange={(value: any) => {
                        formik.setFieldValue("place", value.value);
                      }}
                      value={
                        formik?.values.place?.formatted_address
                          ? {
                              label: formik?.values.place?.formatted_address,
                              value: formik?.values.place,
                            }
                          : undefined
                      }
                      loadOptions={(inputValue, callback) => {
                        debounce(() => {
                          inputValue !== "" &&
                            placesService
                              .fetchResults(inputValue)
                              .then((res) => {
                                const resultMapped = mapResults(res.results);
                                setPlacesResults(resultMapped);
                                callback(resultMapped);
                              });
                        }, 1000);
                      }}
                      cacheOptions
                      {...StyledSelectProps}
                    />
                    <FormLabel>Dirección</FormLabel>
                    <FormErrorMessage>{form.errors.place}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </SimpleGrid>
            <Field name={"description"}>
              {({ field, form }) => (
                <FormControl
                  borderRadius={4}
                  id="description"
                  isRequired
                  h="fit-content"
                  w="100%"
                  isInvalid={form.errors.description}
                  variant={"outline"}
                >
                  <FormLabel>Descripción</FormLabel>
                  <Textarea maxLength={2000} {...field} />
                  <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Box>
              <Stack
                mx="auto"
                spacing={8}
                px={8}
                py={4}
                rounded="lg"
                shadow="lg"
                bg={"secondary.main"}
                maxW="2xl"
              >
                <Flex alignItems="start">
                  <Flex alignItems="center" h={5}>
                    <Field name="hasQueue">
                      {({ field, form }) => (
                        <Checkbox
                          colorScheme="primary"
                          borderColor={"muted.200"}
                          id="comments"
                          rounded="md"
                          defaultChecked={
                            formik.getFieldMeta("hasQueue").initialValue
                          }
                          {...field}
                        />
                      )}
                    </Field>
                  </Flex>
                  <Box ml={3} fontSize="sm">
                    <Text fontWeight="500" color="muted.500">
                      Evento con fila de espera
                    </Text>
                    <Text color="muted.400">
                      Seleccione esta opción para que los usuarios tengan que
                      realizar una fila antes de acceder a la compra de
                      entradas.
                    </Text>
                  </Box>
                </Flex>
                <Flex alignItems="start">
                  <Flex alignItems="center" h={5}>
                    <Field name="hasSeats">
                      {({ field, form }) => (
                        <Checkbox
                          colorScheme="primary"
                          borderColor={"muted.200"}
                          id="comments"
                          rounded="md"
                          defaultChecked={
                            formik.getFieldMeta("hasSeats").initialValue
                          }
                          {...field}
                        />
                      )}
                    </Field>
                  </Flex>
                  <Box ml={3} fontSize="sm">
                    <Text fontWeight="500" color="muted.500">
                      Evento con distribucion de asientos
                    </Text>
                    <Text color="muted.400">
                      Seleccione esta opción para solicitar el servicio de
                      asientos para su evento y pronto nos contactaremos contigo
                      para asesorarte sobre este paso. Mientras puedes continuar
                      creando tu evento.
                    </Text>
                  </Box>
                </Flex>
              </Stack>
              {(formik.values.hasSeats || formik.values.hasQueue) && (
                <Stack spacing={5} mt={5}>
                  <SimpleGrid columns={columnsResponsive} spacing={10}>
                    <Field name={"seatsMail"}>
                      {({ field, form }) => (
                        <FormControl
                          variant="floating"
                          borderRadius={4}
                          id="seatsMail"
                          isInvalid={form.errors.seatsMail}
                        >
                          <EmailInput
                            field={field}
                            form={form}
                            label={"Mail"}
                            validationState={validationState}
                            inputProps={{
                              maxLength: 150,
                            }}
                            inputGroupProps={{
                              borderRadius: 4,
                            }}
                          />

                          <FormErrorMessage>
                            {form.errors.seatsMail}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name={"seatsPhone"}>
                      {({ field, form }) => (
                        <FormControl
                          variant="floating"
                          borderRadius={4}
                          id="seatsPhone"
                          isInvalid={form.errors.seatsPhone}
                        >
                          <NumberInput {...field}>
                            <NumberInputField
                              value={field.value}
                              onChange={field.onChange}
                              placeholder=" "
                              maxLength={20}
                            />
                            <FormLabel>Telefono</FormLabel>
                          </NumberInput>
                          <FormErrorMessage>
                            {form.errors.seatsPhone}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </SimpleGrid>
                </Stack>
              )}
            </Box>
          </Stack>
        </Stack>
      </FormikProvider>
    </EventStep>
  );
};

export default EventDataForm;
