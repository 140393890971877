import React, { useRef, useState } from "react";
import CustomTable from "../../../../../components/Table";
import {
  Badge,
  Button,
  Circle,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { Field, FormikProvider, useFormik } from "formik";
import { useEventAdminInfoController } from "../../event-admin-info.controller";
import * as yup from "yup";
import image from "../../../../../resources/images/ilustration.png";
import { SaveIcon } from "../../../../../components/Icons/SaveIcon";
import { TicketVariantCreateDTO } from "../../../../../services/events/dto";
import { formatInTimeZone } from "date-fns-tz";
import { TIME_ZONE } from "../../../../../utils/dateFormatters";

export const TicketsTable = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState("");
  const infoController = useEventAdminInfoController();

  const columns = [
    {
      id: "name",
      Header: "Nombre",
      accessor: "name",
      meta: {
        filterOperator: "contains",
      },
      isCentered: true,
      enableColumnFilter: true,
      enableGlobalFilter: true,
      Cell: ({
        row: {
          original: { color, name },
        },
      }) => (
        <HStack>
          <Circle bgColor={color} size="4" />
          <Text>{name}</Text>
        </HStack>
      ),
    },
    {
      id: "price",
      Header: "Precio",
      accessor: "price",
      meta: {
        filterOperator: "contains",
      },
      isCentered: true,
      enableColumnFilter: true,
      enableGlobalFilter: true,
      Cell: ({
        row: {
          original: { price },
        },
      }) => <Text size={"md"}>${price.toLocaleString()}</Text>,
    },
    {
      id: "avialable",
      Header: "Disponibles",
      accessor: "available",
      meta: {
        filterOperator: "contains",
      },
      isCentered: true,
    },
    {
      id: "sold",
      Header: "Vendidos",
      accessor: "sold",
      meta: {
        filterOperator: "contains",
      },
      enableColumnFilter: true,
      enableGlobalFilter: true,
      isCentered: true,
    },
    {
      id: "edited",
      Header: "Ultima modificación",
      accessor: "",
      meta: {
        filterOperator: "contains",
      },
      enableColumnFilter: true,
      enableGlobalFilter: true,
      isCentered: true,
      Cell: ({
        row: {
          original: { created },
        },
      }) => {
        const createdDate = new Date(created).toLocaleString();
        return <Text>{createdDate}</Text>;
      },
    },
    {
      id: "isExpired",
      Header: "",
      accessor: "isExpired",
      meta: {
        filterOperator: "contains",
      },
      isCentered: true,
      enableColumnFilter: true,
      enableGlobalFilter: true,
      Cell: ({
        row: {
          original: { isExpired },
        },
      }) => (
        <Badge size={"md"} colorScheme={isExpired ? "gray" : "green"}>
          {isExpired ? "Expirado" : "Activo"}
        </Badge>
      ),
    },
  ];

  const btnRef = React.useRef();

  const validationSchema = yup.object({
    name: yup.string().required("Nombre es requerido"),
    description: yup.string(),
    price: yup
      .number()
      .typeError("Debe establecer un precio")
      .min(0, "El precio debe ser igual o mayor a 0")
      .required("Precio es requerido"),
    available: yup
      .number()
      .typeError("Debe establecer un Stock")
      .min(1, "el Stock debe ser igual o mayor a 1")
      .transform((originalValue, originalObject) =>
        originalObject === "" ? undefined : originalValue
      ),
    minimumQuantityPerUser: yup
      .number()
      .typeError("Valor Inválido")
      .test(
        "is-less-than-max",
        "La cantidad mínima por usuario debe ser menor que la máxima",
        function (value) {
          if (value < 0) {
            throw new yup.ValidationError(
              "La cantidad mínima por usuario no puede ser negativa",
              value,
              "minimumQuantityPerUser"
            );
          }

          const maxQuantity = this.parent.maxQuantityPerUser;
          return (
            value === undefined ||
            maxQuantity === undefined ||
            value <= maxQuantity
          );
        }
      )
      .transform((originalValue, originalObject) =>
        originalObject === "" ? undefined : originalValue
      ),
    maxQuantityPerUser: yup
      .number()
      .min(1, "la máxima cantidad por usuario debe ser igual o mayor a 1")
      .transform((originalValue, originalObject) =>
        Number.isNaN(originalObject) ? undefined : originalValue
      ),
    color: yup.string().required("Color es requerido"),
    sellStartDatetime: yup
      .string()
      .required("Fecha comienzo de venta es requerido"),
    sellFinishDatetime: yup
      .string()
      .required("Fecha fin de venta es requerido")
      .test(
        "is-after",
        "La fecha de fin de venta debe ser mayor a la fecha de inicio de venta",
        function (value) {
          return value > this.parent.sellStartDatetime;
        }
      )
      .test(
        "not-same",
        "Las fechas de inicio y fin de venta no pueden ser iguales",
        function (value) {
          return value !== this.parent.sellStartDatetime;
        }
      ),
  });

  function removeLastCharacter(cadena: string) {
    if (cadena && cadena.endsWith("Z")) {
      return cadena.slice(0, -1);
    }
    return cadena;
  }

  const formik = useFormik<TicketVariantCreateDTO>({
    initialValues: {
      name: "",
      place: "",
      event: "",
      function: "",
      price: 0,
      meta: {
        free: true,
      },
      capacity: 0,
      sold: 0,
      bloqued: 0,
      available: 0,
      maxQuantityPerUser: 0,
      minimumQuantityPerUser: 0,
      sellStartDatetime: new Date(),
      sellFinishDatetime: new Date(),
      description: "",
      color: "#F04438",
      invitation: 0,
      producer: "",
      isExpired: false,
    },
    validationSchema: validationSchema,
    onSubmit: (data) => {
      formik.resetForm();
      if (isEditing) {
        onClose();
        infoController.editVariantExecute(
          infoController.activeFunction.value,
          infoController.eventInfo?._id ?? "",
          infoController.eventInfo?.producer ?? "",
          editingId,
          data
        );
      } else {
        onClose();
        infoController.createVariantExecute(
          infoController.activeFunction.value,
          infoController.eventInfo?._id ?? "",
          infoController.eventInfo?.producer ?? "",
          data
        );
      }
    },
  });

  const tableRef = useRef(null);

  function handleOpenCreate(): void {
    setIsEditing(false);
    formik.resetForm();
    onOpen();
  }
  const handleOpenEdit = (original: TicketVariantCreateDTO) => {
    if (!original.isExpired) {
      setIsEditing(true);
      setEditingId(original?._id);
      formik.setValues(original);
      onOpen();
    }
  };

  function calcularNumeroDePaginas(array: any[]) {
    const longitud = array.length;
    const numeroDePaginas = Math.ceil(longitud / 5);

    return numeroDePaginas;
  }

  return (
    <Stack spacing={10}>
      {infoController.ticketVariants !== undefined && (
        <>
          <CustomTable
            ref={tableRef}
            customHeader={
              <HStack
                w="100%"
                justifyContent={["start", "space-between"]}
                flexWrap={["wrap", "wrap", "wrap", "wrap", "nowrap"]}
                gap={4}
                mt={[1, 1, 5]}
              >
                <Button
                  w={"fit-content"}
                  onClick={handleOpenCreate}
                  ref={btnRef}
                  variant="solid"
                  colorScheme="primary"
                  borderRadius="full"
                  py="8px"
                  px="24px"
                >
                  + Ticket
                </Button>
                {/* <MobileDrawer/> */}
              </HStack>
            }
            customEmptyState={
              <Flex
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                py="60px"
                gap="20px"
              >
                <Image src={image} />
                <Text noOfLines={2} textColor="muted.600">
                  Todavía no tienes tipos de Tickets creados para este evento
                </Text>
              </Flex>
            }
            editFn={handleOpenEdit}
            columns={columns}
            pageCount={calcularNumeroDePaginas(infoController.ticketVariants)}
            totalDocs={infoController.ticketVariants.length}
            data={infoController.ticketVariants.sort((a, b) => {
              if (a.isExpired !== b.isExpired) {
                return a.isExpired ? 1 : -1;
              }
              return a.created > b.created ? -1 : a.created < b.created ? 1 : 0;
            })}
            loading={false}
          />
        </>
      )}

      <Drawer
        isOpen={isOpen}
        placement={"right"}
        onClose={onClose}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <FormikProvider value={formik}>
          <DrawerContent
            color={"secondary.contrast"}
            borderLeft={"1px solid muted.200"}
          >
            <DrawerCloseButton mt={8} />
            <DrawerHeader bgColor={"secondary.main"} pt={8}>
              {isEditing ? "EDITAR" : "CREAR NUEVO"} TICKET
            </DrawerHeader>
            <DrawerBody bgColor={"secondary.main"} px={14} pt={14}>
              <Field name="name">
                {({ field, form }) => (
                  <FormControl mb="3" ml="4" isInvalid={!!formik.errors?.name}>
                    <FormLabel fontSize={14}>{"Nombre"}</FormLabel>
                    <Input
                      id="name"
                      type="text"
                      w={"80%"}
                      value={formik.values.name}
                      {...field}
                    />
                    <FormErrorMessage>{`${formik.errors.name}`}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="desciption">
                {({ field, form }) => (
                  <FormControl
                    mb="3"
                    ml="4"
                    isInvalid={!!formik.errors?.description}
                  >
                    <FormLabel fontSize={14}>{"Descripción"}</FormLabel>
                    <Textarea
                      w={"80%"}
                      id="description"
                      resize="none"
                      value={formik.values.description}
                      placeholder="Ingrese una descripción"
                      {...field}
                    />
                    <FormErrorMessage>{`${formik.errors.description}`}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <HStack w="80%" ml="4" gap="10px" alignItems="flex-start">
                <Field name="price">
                  {({ field, form }) => (
                    <FormControl
                      w="50%"
                      mb="3"
                      isInvalid={!!formik.errors?.price}
                    >
                      <FormLabel fontSize={14}>{"Precio"}</FormLabel>
                      <Input
                        id="price"
                        type="number"
                        value={formik.values.price.toLocaleString()}
                        {...field}
                      />
                      <FormErrorMessage>{`${formik.errors.price}`}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="available">
                  {({ field, form }) => (
                    <FormControl
                      w="50%"
                      mb="3"
                      isInvalid={!!formik.errors?.available}
                    >
                      <FormLabel fontSize={14}>{"Stock"}</FormLabel>
                      <Input
                        id="available"
                        type="number"
                        value={formik.values.available}
                        {...field}
                      />
                      <FormErrorMessage>{`${formik.errors.available}`}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>
              <HStack w="80%" ml="4" gap="10px" alignItems="flex-start">
                <Field name="minimumQuantityPerUser">
                  {({ field, form }) => (
                    <FormControl
                      w="50%"
                      mb="3"
                      isInvalid={!!formik.errors?.minimumQuantityPerUser}
                    >
                      <FormLabel fontSize={14}>
                        {"Cantidad minima por usuario"}
                      </FormLabel>
                      <Input
                        id="minimumQuantityPerUser"
                        type="number"
                        value={formik.values.minimumQuantityPerUser}
                        {...field}
                      />
                      <FormErrorMessage>{`${formik.errors.minimumQuantityPerUser}`}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="maxQuantityPerUser">
                  {({ field, form }) => (
                    <FormControl
                      w="50%"
                      mb="3"
                      isInvalid={!!formik.errors?.maxQuantityPerUser}
                      {...field}
                    >
                      <FormLabel fontSize={14}>
                        {"Cantidad máxima por usuario"}
                      </FormLabel>
                      <Input
                        id="maxQuantityPerUser"
                        type="number"
                        value={formik.values.maxQuantityPerUser}
                        {...field}
                      />
                      <FormErrorMessage>{`${formik.errors.maxQuantityPerUser}`}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>
              <HStack
                w="80%"
                ml="4"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Field name="color">
                  {({ field, form }) => (
                    <FormControl isInvalid={!!formik.errors.color} w="full">
                      <FormLabel fontSize={14}>Color</FormLabel>
                      <Input
                        id={`color`}
                        type={"color"}
                        value={formik.values.color}
                        {...field}
                      />
                      <FormErrorMessage>{`${formik.errors.color}`}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>
              <VStack
                w="80%"
                ml="4"
                justifyContent="space-between"
                alignItems="flex-start"
                mt="2"
              >
                <Field name="sellStartDatetime">
                  {({ field, form }) => (
                    <FormControl
                      w="full"
                      isInvalid={!!formik.errors?.sellStartDatetime}
                    >
                      <FormLabel fontSize={14}>
                        {"Fecha y hora de inicio de venta"}
                      </FormLabel>
                      <Input
                        id="sellStartDatetime"
                        type="datetime-local"
                        datatype="date"
                        {...field}
                        value={formatInTimeZone(formik.values.sellStartDatetime,TIME_ZONE,"yyyy-MM-dd HH:mm")}
                      />
                      <FormErrorMessage>{`${formik.errors.sellStartDatetime}`}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="sellFinishDatetime">
                  {({ field, form }) => (
                    <FormControl
                      w="full"
                      isInvalid={!!formik.errors?.sellFinishDatetime}
                    >
                      <FormLabel fontSize={14}>
                        {"Fecha y hora de fin de venta"}
                      </FormLabel>
                      <Input
                        id="sellFinishDatetime"
                        type="datetime-local"
                        // {...register("sellFinishDatetime")}
                        {...field}
                        value={formatInTimeZone(formik.values.sellFinishDatetime, TIME_ZONE, "yyyy-MM-dd HH:mm")}
                      />
                      <FormErrorMessage>{`${formik.errors.sellFinishDatetime}`}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </VStack>
            </DrawerBody>
            <DrawerFooter
              bgColor={"secondary.main"}
              w="full"
              borderTop="1px solid muted.200"
            >
              <HStack
                w="full"
                justifyContent="flex-end"
                alignItems="center"
                gap="24px"
              >
                <Button
                  bgColor={"primary.main"}
                  variant="solid"
                  colorScheme="primary"
                  borderRadius="full"
                  py="8px"
                  pl="24px"
                  pr="32px"
                  gap="8px"
                  onClick={() => {
                    formik.validateForm().then((data) => {
                      formik.submitForm();
                    });
                  }}
                >
                  {isEditing && <SaveIcon />}
                  {isEditing ? `GUARDAR` : "CREAR TICKET"}
                </Button>
              </HStack>
            </DrawerFooter>
          </DrawerContent>
        </FormikProvider>
      </Drawer>
    </Stack>
  );
};
