import { Box, Button, HStack, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";

import { chileTiketLogo } from "../../../../resources";
import { ArrowRightIcon } from "@chakra-ui/icons";
import { FaArrowRight, FaWhatsapp } from "react-icons/fa";

type Props = {
  onWhatsAppPress: () => void;
  isLoading: boolean;
};

const QueueInfo = (props: Props) => {
  return (
    <Stack
      spacing={8}
      maxW={["95%", "95%", "60%"]}
      borderColor={"secondary.main"}
      mx={"auto"}
      mt={[20, 20, 100]}
      borderWidth={"3px"}
      borderRadius={"xl"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      h={"full"}
      p={5}
      py={10}
    >
      <Text textAlign={"center"} fontSize={20} fontWeight={600}>
        Continuaremos por WhatsApp
      </Text>
      <HStack>
        <Image
          src={chileTiketLogo}
          w={130}
          style={{ aspectRatio: 3851 / 780 }}
        />
        <FaArrowRight color={"white"} />
        <FaWhatsapp size={40} color="white" />
      </HStack>
      <Box maxW={500}>
        <Text textAlign={"center"}>
          Gracias por elegir Chiletiket para adquirir tus tickets.
        </Text>
        <Text textAlign={"center"}>
          Al continuar, te redirigiremos a nuestro chat oficial de WhatsApp con
          un mensaje precargado. Solamente sigue los pasos y te brindaremos toda
          la información necesaria para completar tu compra.
        </Text>
      </Box>
      <Button
        w={["100%", "100%", 200]}
        borderRadius={"full"}
        colorScheme="primary"
        onClick={props.onWhatsAppPress}
        isLoading={props.isLoading}
      >
        CONTINUAR
      </Button>
    </Stack>
  );
};

export default QueueInfo;
