import { datadogRum } from "@datadog/browser-rum";
import {
  isLocalhost,
  rumApplicationId,
  rumClientToken,
  rumEnv,
} from "../config/envConf";

export const initTracking = () => {
  if (!isLocalhost) {
    datadogRum.init({
      applicationId: rumApplicationId,
      clientToken: rumClientToken,
      site: "datadoghq.com",
      service: "global-tickets",
      env: rumEnv,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sampleRate: 100,
      sessionReplaySampleRate: 100,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });
    datadogRum.startSessionReplayRecording();
  } else {
    console.warn("RUM Tracking disabled - env var is Localhost");
  }
};
