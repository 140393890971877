import { UseDisclosureProps } from "@chakra-ui/react";
import { TicketListItemProps } from "./pages/ticketList";

export const profileScreens: { name: string; label: string }[] = [
  { name: "tickets", label: "Mis Tickets" },
  { name: "edit", label: "Mi Perfil" },
  { name: "password", label: "Cambiar Contraseña" },
];

export interface ProfileController {
  tabIndex: number;
  handleTabsChange: (index: number) => void;
  profileName: string;
  isLogOutOpen: boolean;
  onLogOutOpen: () => void;
  onLogOutClose: () => void;
}

export interface ProfileProps {
  useController?: () => ProfileController;
}

export interface TicketDetailProps {
  drawerManager: UseDisclosureProps;
  selectedTicket?: TicketListItemProps;
  transferCall?: () => void;
}

export interface TicketTransferProps {
  drawerManager: UseDisclosureProps;
  selectedTicket?: TicketListItemProps;
  transferCallback: () => void;
}
