import { useCallback, useEffect, useMemo, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useUserEventsService } from "../../../../services/Admin/events/userEventsService";
import { useAsync } from "../../../../utils/useAsync";
import { AssistanceController } from "./interfaces";
import { useToaster } from "../../../../utils/useToaster";

export const useAssistanceController = (): AssistanceController => {
  const { activeFunction } = useOutletContext<{
    activeFunction: { label: string; value: string };
  }>();
  const { id: eventId } = useParams();

  const userEventsService = useUserEventsService();

  const [guestTicketsShown, setGuestTicketsShown] = useState(false);

  const [userListData, , userListLoading, userListExecute] = useAsync(
    userEventsService.getAttendees,
    {
      defaultValue: {
        totalDocs: 0,
        limit: 0,
        totalPages: 0,
        page: 0,
        pagingCounter: 0,
        hasPrevPage: false,
        hasNextPage: false,
        prevPage: 0,
        nextPage: 0,
        docs: [],
      },
      defaultLoading: true,
    }
  );

  const [attendeesCount, , attendeesCountLoading, attendeesCountExecute] =
    useAsync(userEventsService.getAttendeesCount, {
      defaultLoading: true,
      defaultValue: { used: "", notUsed: "", total: "" },
    });

  useEffect(() => {
    if (eventId && activeFunction) {
      attendeesCountExecute(eventId, activeFunction.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, activeFunction]);

  const getUserList = useCallback(
    (paginationOptions: {
      pageSize: number;
      pageIndex: number;
      globalFilter?: string;
    }) => {
      if (eventId && activeFunction) {
        userListExecute(eventId, activeFunction.value, {
          page: paginationOptions.pageIndex,
          limit: paginationOptions.pageSize,
          search: paginationOptions.globalFilter,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventId, activeFunction]
  );

  const { successToast, errorToast, closeAll } = useToaster();

  const handleAssistanceChange = useCallback(
    async (code: string, name: string, currentState: boolean) => {
      try {
        const res = await userEventsService.updateAssistance(
          activeFunction.value,
          code,
          currentState
        );
        console.log("Update RES: ", res);
        closeAll();
        successToast(
          "Se cambio la asistencia de " + name,
          "La asistencia del usuario se cambió exitosamente"
        );
        return res;
      } catch (error) {
        closeAll();
        errorToast(
          "Ocurrió un error al cambiar la asistencia de " + name,
          "La asistencia del usuario no pudo cambiarse. Inténtelo nuevamente."
        );
        throw Error("Ocurrió un error en request");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFunction]
  );

  const assistanceListData = useMemo(
    () => ({
      totalPages: userListData.totalPages,
      totalDocs: userListData.totalDocs,
      data: userListData.docs.map((user) => ({
        ...user,
        onSelect: (currentState: boolean) =>
          handleAssistanceChange(user.code, user.name, currentState),
      })),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userListData]
  );

  const getTicketPDF = async (id: string) =>
    await userEventsService.getTicketPDF(id);

  return {
    handleAssistanceChange,
    assistanceListData,
    attendeesCount,
    attendeesCountLoading,
    getUserList,
    userListLoading,
    guestTicketsShown,
    setGuestTicketsShown,
    getTicketPDF,
  };
};
