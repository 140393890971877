import { useCallback, useState } from "react";

export type UseAsyncType<T, P extends any[]> = [
  T | undefined,
  any,
  boolean,
  (...params: P) => void
];

export type UseAsyncOptions<T> = {
  defaultValue?: T;
  defaultLoading?: boolean;
  onSuccess?: (data: T) => void;
  onError?: (e: any) => void;
};

export const useAsync = <T, P extends any[]>(
  asyncFunction: (...params: P) => Promise<T>,
  options?: UseAsyncOptions<T>
): [T | undefined, any, boolean, (...params: P) => void] => {
  const defaultOptions = {
    defaultValue: undefined,
    defaultLoading: false,
  };
  const { defaultLoading, defaultValue, onSuccess, onError } = {
    ...defaultOptions,
    ...options,
  };

  const [loading, setLoading] = useState(defaultLoading);
  const [error, setError] = useState<any>(undefined);
  const [data, setData] = useState<T | undefined>(defaultValue);

  const execute = useCallback(
    async (...params: Parameters<typeof asyncFunction>) => {
      try {
        setLoading(true);
        const fetchData = await asyncFunction(...params);
        setData(fetchData);
        if (onSuccess) {
          onSuccess(fetchData);
        }
      } catch (e) {
        setError(e);
        if (onError) {
          onError(e);
        }
      }
      setLoading(false);
    },
    [asyncFunction]
  );

  return [data, error, loading, execute];
};
