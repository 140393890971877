import { datadogRum } from "@datadog/browser-rum";
import { useContext, useEffect } from "react";
import { AuthContext } from "../providers/authContext";
import { useAuthService } from "../services/auth/authService";
import { useAuthAsyncStore } from "../services/auth/authStore";

export const useRumUserSetup = () => {
  const authService = useAuthService();
  const { data, execute } = useAuthAsyncStore();
  const authContext = useContext(AuthContext);
  const { status, userId } = authContext.authState;

  useEffect(() => {
    if (!data && status === "logged") {
      execute(authService.getUserData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, status]);

  useEffect(() => {
    if (status === "logged" && data) {
      datadogRum.setUser({ email: data.email, id: userId });
    }
  }, [status, data, userId]);
};
