import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthService } from "../../../services/auth/authService";
import { useAsync } from "../../../utils/useAsync";
import { TermsController } from "./interfaces";

export const useTermsController = (): TermsController => {
  const navigate = useNavigate();

  const onBackButtonPress = () => navigate("/home");

  const authService = useAuthService();

  const [terms, error, loading, execute] = useAsync(authService.getTerms, {
    defaultValue: "",
  });

  useEffect(() => {
    execute();
  }, []);

  return { terms, onBackButtonPress, error, loading };
};
