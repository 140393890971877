import { CheckIcon, CopyIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Flex,
  Icon,
  Stack,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import NoFunctionSelected from "../../../../components/NoFunctionSelected";
import { EventDTO } from "../../../../services/events/dto";
import { TokenProps } from "./interfaces";
import { useTokenController } from "./token.controller";

export const TokenView: React.FC<TokenProps> = ({
  useController = useTokenController,
}) => {
  const controller = useController();

  const { onCopy, setValue, hasCopied } = useClipboard("");

  const { activeFunction, eventInfo } = useOutletContext<{
    activeFunction: { label: string; value: string; token: string };
    eventInfo: EventDTO;
  }>();

  useEffect(() => {
    if (activeFunction?.token) {
      setValue(activeFunction?.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFunction]);

  return activeFunction.value !== "all" ? (
    <Stack color={"backgroundContrast"} direction={"column"} spacing={7}>
      <Stack spacing={4} direction="column">
        <Text fontSize={"medium"}>
          Creá un <strong>token</strong> para poder acreditar los ingresos de tu
          evento, a través de nuestra aplicación.
        </Text>

        <Stack
          color={"muted.600"}
          fontSize={"sm"}
          spacing={0.5}
          w={["full", "full", "90%"]}
        >
          <Stack direction={"row"} align="center" color={"muted.600"}>
            <Icon />
            <Text fontSize={"sm"}>Que es un token?</Text>
          </Stack>
          <Text>
            Es un código de acceso que te permitirá visualizar la información de
            tu evento por función y mantener un seguimiento de la misma en
            tiempo real.
          </Text>
          <Text>
            Por seguridad, el token expira una vez finalizada la función. Por lo
            que deberás generar uno nuevo para cada una en particular.
          </Text>
          <Text>
            Compártelo con tu staff para llevar un ágil control al momento de
            las acreditaciones.
          </Text>
          <Text>
            Recuerda que debes indicarles <strong>descargar nuestra app</strong>
            .
          </Text>
        </Stack>
        <Button
          colorScheme={"primary"}
          variant="outline"
          rounded={"full"}
          w="fit-content"
        >
          Descargar App
        </Button>
      </Stack>
      <Center>
        <Stack direction={"column"} spacing={2}>
          <Text fontSize={"lg"}>Token</Text>
          <Stack
            direction={"row"}
            spacing={0}
            bgColor={"secondary.main"}
            w="fit-content"
            align={"stretch"}
            borderRadius={8}
            overflow="hidden"
          >
            <Text mx={12} my={3} fontSize="2xl">
              {activeFunction?.token}
            </Text>
            <Flex
              h="auto"
              w="fit-content"
              color="black"
              flex={1}
              fontWeight={600}
              transition={"0.3s linear"}
              onClick={onCopy}
              bgColor={hasCopied ? "green.200" : "muted.200"}
              px={5}
              cursor={"pointer"}
            >
              <Stack
                direction={"row"}
                w="fit-content"
                justify={"center"}
                align="center"
                h="full"
              >
                {hasCopied ? <CheckIcon /> : <CopyIcon />}
                <Text>{hasCopied ? "COPIADO!" : "COPIAR"}</Text>
              </Stack>
            </Flex>
          </Stack>
          <Stack
            direction={"row"}
            align="center"
            color={"muted.500"}
            maxW="inherit"
          >
            <Icon />
            <Text fontSize={"xs"} noOfLines={2} maxW={["full", "full", "23vw"]}>
              Válido exclusivamente para la función:
              <br /> {activeFunction.label + " - " + eventInfo.name}
            </Text>
          </Stack>
        </Stack>
      </Center>
    </Stack>
  ) : (
    <NoFunctionSelected />
  );
};
