import { Badge, Box, Button, ButtonProps } from "@chakra-ui/react";
import React from "react";
import { useCreateEventService } from "../../../../services/createEvent/createEventService";

import EventCard from "../../Home/components/eventCard";

type EventStepProps = {
  children?: React.ReactNode;
  buttonProps?: ButtonProps;
  label?: string;
  preview?: React.ReactNode;
};

export const EventStep = (props: EventStepProps) => {
  const createEventService = useCreateEventService();
  const { eventCard } = createEventService.store;

  return (
    <Box display={"flex"} flexDir={["column", "column", "row"]} my={5}>
      <Box
        flexGrow={1}
        mr={5}
        borderColor={"secondary.main"}
        borderWidth={"3px"}
        borderRadius={"xl"}
        p={5}
      >
        {props.children}
      </Box>
      <Box display={"flex"} flexDir={"column"}>
        <Box display={["none", "none", "block"]}>
          {props.preview ?? (
            <>
              <Badge
                variant={"outline"}
                borderRadius="md"
                px="2"
                py="0.5"
                mb={5}
                colorScheme={"primary"}
                // textTransform="none"
                fontSize="sm"
                fontWeight="600"
                autoCapitalize="false"
              >
                Previsualización
              </Badge>
              <EventCard {...eventCard} />
            </>
          )}
        </Box>
        {/* <Button
          borderRadius={"full"}
          colorScheme={"primary"}
          textAlign={"center"}
          my={10}
          px={10}
          {...props.buttonProps}
        >
          {props.label ?? "SIGUIENTE"}
        </Button> */}
      </Box>
    </Box>
  );
};
