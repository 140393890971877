import { useOutletContext, useParams } from "react-router-dom";
import { useUserEventsService } from "../../../../services/Admin/events/userEventsService";
import { useAsync } from "../../../../utils/useAsync";
import { TokenController } from "./interfaces";

export const useTokenController = (): TokenController => {
  const { activeFunction } = useOutletContext<{
    activeFunction: { label: string; value: string };
  }>();
  const { id: eventId } = useParams();

  const userEventsService = useUserEventsService();

  const [tokens, , tokenLoading, executeToken] = useAsync(
    userEventsService.getFunctionToken,
    {
      defaultValue: null,
    }
  );

  const getToken = () => executeToken(eventId, activeFunction.value);

  return { tokens, tokenLoading, getToken };
};
