import { AxiosResponse } from "axios";
import { useContext } from "react";
import { AxiosContext } from "../../providers/axiosContext";
import { UserData } from "./auth.dto";
import { getProducer } from "../../utils/getProducer";

export interface AuthService {
  login: (usr: string, psw: string) => Promise<AxiosResponse<any, any>>;

  register: (data: any) => Promise<AxiosResponse<any, any>>;

  forgotPassword: (email: string) => Promise<AxiosResponse<any, any>>;

  changePassword: (
    code: string,
    psw: string,
    email: string
  ) => Promise<AxiosResponse<any, any>>;

  confirmPassword: (oldPassword: string) => Promise<AxiosResponse<any, any>>;
  changePasswordLogged: (
    newPassword: string
  ) => Promise<AxiosResponse<any, any>>;
  externalLogin: (
    service: string,
    info: any
  ) => Promise<AxiosResponse<any, any>>;
  validateEmail: (email: string) => Promise<any>;
  getTerms: () => Promise<any>;
  getUserData: () => Promise<UserData>;
}

export const useAuthService = (): AuthService => {
  const axiosContext = useContext(AxiosContext);

  const producer = getProducer();

  const login = async (email: string, password: string) => {
    return axiosContext.publicAxios.post("/auth/CHILE_TICKETS/users/login", {
      email,
      password,
    });
  };

  const externalLogin = (service: string, info: any) => {
    return axiosContext.publicAxios.post(
      `/auth/CHILE_TICKETS/users/login/${service}`,
      { ...info, producer }
    );
  };

  const register = async (data: any) => {
    //console.log('Data: ', data);

    const res = await axiosContext.publicAxios.post(
      "/auth/CHILE_TICKETS/users",
      { ...data, producer }
    );
    // console.log(res);
    return res;
  };

  const forgotPassword = async (email: string) => {
    return axiosContext.publicAxios.post(
      "/auth/CHILE_TICKETS/users/forgot-password",
      {
        email,
      }
    );
  };

  const changePassword = async (
    code: string,
    newPassword: string,
    email: string
  ) => {
    return axiosContext.publicAxios.post(
      "/auth/CHILE_TICKETS/users/change-password",
      {
        code,
        newPassword,
        email,
      }
    );
  };

  const confirmPassword = async (oldPassword: string) => {
    return axiosContext.authAxios.post(
      "/auth/CHILE_TICKETS/users/confirm-password",
      {
        password: oldPassword,
      }
    );
  };

  const changePasswordLogged = async (newPassword: string) => {
    return axiosContext.authAxios.post(
      "/auth/CHILE_TICKETS/users/change-password-logged",
      {
        newPassword,
      }
    );
  };

  const validateEmail = (email: string) => {
    return axiosContext.publicAxios.get(
      `/validation-utils/emails?email=${email}`
    );
  };

  const getTerms = async () => {
    const res = await axiosContext.authAxios.get("auth/CHILE_TICKETS/terms");
    return res.data;
  };

  const getUserData = async () => {
    const res = await axiosContext.authAxios.get<UserData>("auth/valid");
    return res.data;
  };

  return {
    login,
    register,
    changePassword,
    forgotPassword,
    confirmPassword,
    changePasswordLogged,
    externalLogin,
    validateEmail,
    getTerms,
    getUserData,
  };
};
