import { useContext } from "react";
import { AxiosContext } from "../../../providers/axiosContext";
import { DiscountDTO, IDiscountCode, UseDiscountCodesService } from "./dto";

export const useDiscountCodesService = (): UseDiscountCodesService => {
  const axiosContext = useContext(AxiosContext);

  const getDiscountCodesList = async (functionId: string) => {
    const res = await axiosContext.authAxios.get<DiscountDTO>(
      `/tickets/discount-codes`,
      {
        params: {
          functionId,
        },
      }
    );
    return res.data;
  };

  const postDiscountCode = async (newDiscountCode: IDiscountCode,functionId:string) => {
    const res = await axiosContext.authAxios.post(`/tickets/discount-codes`, {
      ...newDiscountCode,functionId,
    });
    return res;
  };

  const editDiscountCode = async (
    discountCodeId: string,
    newValues: IDiscountCode
  ) => {
    const res = await axiosContext.authAxios.put(
      `/tickets/discount-codes/${discountCodeId}`,
      { ...newValues }
    );
    return res.data;
  };
  const changeStatusToActive = async (discountCodeId: string) => {
    const res = await axiosContext.authAxios.put(
      `/tickets/discount-codes/${discountCodeId}`,
      { status: "active" }
    );
    return res.data;
  };
  const changeStatusToInactive = async (discountCodeId: string) => {
    const res = await axiosContext.authAxios.put(
      `/tickets/discount-codes/${discountCodeId}`,
      { status: "inactive" }
    );
    return res.data;
  };

  const deleteDiscountCode =async (discountCodeId:string) => {
    const res = await axiosContext.authAxios.delete(
      `tickets/discount-codes/${discountCodeId}`
    );
    return res.data
  }

  return {
    getDiscountCodesList,
    editDiscountCode,
    postDiscountCode,
    changeStatusToActive,
    changeStatusToInactive,
    deleteDiscountCode,
  };
};
