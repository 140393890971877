import {
  Box,
  Button,
  Circle,
  Flex,
  IconButton,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { FormikProvider, useFormik } from "formik";

import * as Yup from "yup";
import { seatsKey } from "../../../../../config/envConf";
import { SeatsioSeatingChart } from "@seatsio/seatsio-react";
import { useEffect, useMemo, useState } from "react";
import { TicketVariantDTO } from "../../../../../services/events/dto";
import { priceFormatter } from "../../../../../utils/priceFormat";
import { ticketLabelFormatter } from "../../../../../utils/stringFormatters";
import { mapToCategoriesSummary } from "../../../../Landing/EventDetail/DetailLayouts/SeatsLayout";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import CustomTable from "../../../../../components/Table";
import Card from "../../../../../components/Card";
import { useReservationsService } from "../../../../../services/Admin/reservations/reservations.service";
import { useAsync } from "../../../../../utils/useAsync";
import { DeleteIcon } from "../../../../../components/Icons/DeleteIcon";
import { useToaster } from "../../../../../utils/useToaster";
import { useColors } from "../../../../../utils/useColors";

type Props = {
  variants: TicketVariantDTO[];
  variantsLoading: boolean;
  variantsError: any;
  eventFunctionChartKey: string;
  activeFunctionId: string;
  variantsExecute: () => void;
};

type SeatsReservationsFields = {};

const SeatsReservationsSchema = Yup.object().shape({});

export const SeatsReservations = (props: Props) => {
  const mappedVariants = useMemo(
    () =>
      props.variants.map((variant) => ({
        id: variant._id,
        category: variant.name,
        price: variant.price,
      })),
    [props.variants]
  );

  const { successToast, errorToast, warningToast } = useToaster();

  const [createReservationLoading, setCreateReservationLoading] =
    useState(false);

  const handleSubmit = async (values: SeatsReservationsFields) => {
    try {
      setCreateReservationLoading(true);
      await reservationsService.createSeatsReservation(props.activeFunctionId, {
        eventFunctionChartKey: props.eventFunctionChartKey,
        tickets: selectedSeats.map((seat) => ({
          ticketVariantId: props.variants.find(
            (variant) => variant.name === seat.category.label
          )._id,
          id: seat.id,
          objectType: seat.objectType,
          labels: { ...seat.labels, displayName: seat.labels.displayedLabel },
          numSelected: seat?.numSelected,
        })),
      });

      successToast(
        "Reserva creada con éxito",
        `Se envió creo la reserva de tickets`
      );
    } catch {
      errorToast("Ocurrió un error", `Inténtalo de nuevo mas tarde`);
    } finally {
      chartInstance.clearSelection();
      reservationsExecute(props.activeFunctionId);
      props.variantsExecute();
      setCreateReservationLoading(false);
    }
  };

  const formik = useFormik<SeatsReservationsFields>({
    initialValues: {},
    validationSchema: SeatsReservationsSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const [chartInstance, setChartInstance] = useState(null);

  const [selectedSeats, setSelectedSeats] = useState([]);

  const mappedSelectedSeats = useMemo(
    () => mapToCategoriesSummary(selectedSeats, mappedVariants),
    [selectedSeats, mappedVariants]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Ticket",
        accessor: "variant",
        isCentered: true,
      },
      {
        Header: "Sección",
        accessor: "name",
        isCentered: true,
      },
      {
        Header: "Fila",
        accessor: "row",
        isCentered: true,
      },
      {
        Header: "Asiento",
        accessor: "seat",
        isCentered: true,
      },
      {
        Header: "Acciones",
        accessor: "data",
        isCentered: true,
        Cell: (cell) => {
          const [loading, setLoading] = useState(false);
          return (
            <IconButton
              aria-label="Call Segun"
              isLoading={loading}
              disabled={loading}
              size="md"
              variant={"ghost"}
              rounded={"full"}
              alignSelf="self-start"
              colorScheme={"primary"}
              onClick={async () => {
                try {
                  setLoading(true);

                  await reservationsService.cancelSeatsReservation(
                    props.activeFunctionId,
                    {
                      eventFunctionChartKey: props.eventFunctionChartKey,
                      tickets: [cell.value],
                    }
                  );
                  successToast(
                    "Reserva cancelada con éxito",
                    `Se canceló la reserva indicada`
                  );
                } catch {
                  errorToast(
                    "Ocurrió un error",
                    `Inténtalo de nuevo mas tarde`
                  );
                } finally {
                  setLoading(false);

                  reservationsExecute(props.activeFunctionId);
                }
              }}
              icon={<DeleteIcon />}
            />
          );
        },
      },
    ],
    []
  );

  const reservationsService = useReservationsService();

  const [
    reservations,
    reservationsError,
    reservationsLoading,
    reservationsExecute,
  ] = useAsync(reservationsService.getSeatsReservations, {
    defaultValue: { tickets: [] },
  });

  const reservedSeats = useMemo(
    () => reservations.tickets.map((ticket) => ticket.id),
    [reservations]
  );

  useEffect(() => {
    if (props.activeFunctionId) {
      reservationsExecute(props.activeFunctionId);
    }
  }, [props.activeFunctionId]);

  const colors = useColors();

  return (
    <FormikProvider value={formik}>
      <Stack spacing={[7, 9]} p={[5, 7]}>
        <Box display={"flex"}>
          <InfoOutlineIcon mr={2} mt={1} />
          <Text flexGrow={1} fontSize={16} color={"muted.600"}>
            Al reservar tickets, estos serán retirados de los disponibles para
            la venta.
          </Text>
        </Box>
        <Stack spacing={3}>
          <Text>Tickets a reservar:</Text>
          <Text fontSize={14}>
            Utiliza el mapa para elegir tus asientos. Puedes navegar sobre el y
            hacer zoom sobre los espacios que desees
          </Text>
        </Stack>
        <Stack spacing={5}>
          <Box>
            <SeatsioSeatingChart
              showSectionContents={"onlyAfterZoom"}
              onRenderStarted={(chart) => {
                setChartInstance(chart);
              }}
              extraConfig={{
                reservedSeats,
              }}
              workspaceKey={seatsKey}
              objectIcon={(object, defaultIcon, extraConfig) => {
                if (
                  extraConfig &&
                  extraConfig.reservedSeats &&
                  extraConfig.reservedSeats.includes(object.id)
                  // !defaultIcon
                ) {
                  return "bookmark-o";
                }
              }}
              event={props.eventFunctionChartKey}
              showMinimap={true}
              showFullScreenButton={false}
              stylePreset="bubblegum"
              language={"es"}
              colorScheme={"dark"}
              colors={{
                colorSelected: "hsl(214, 96%, 53%)",
                colorTitle: colors.primary.main,
              }}
              cursorTooltip={{
                showActionHint: false,
                showAvailability: false,
                showCategory: false,
                showLabel: true,
                showPricing: true,
                showUnavailableNotice: true,
                stylizedLabel: true,
              }}
              pricing={mappedVariants}
              priceFormatter={(price) => {
                return "$" + priceFormatter(price as number);
              }}
              onObjectSelected={(obj) => {
                setSelectedSeats((prev) => {
                  var copyPrev = [...prev];
                  if (
                    obj?.objectType === "GeneralAdmissionArea" &&
                    prev.find((items) => items.uuid === obj?.uuid)
                  ) {
                    copyPrev[
                      prev.findIndex((items) => items.uuid === obj?.uuid)
                    ].numSelected = obj.numSelected;
                    return [...copyPrev];
                  } else {
                    return [...prev, obj];
                  }
                });
              }}
              onObjectDeselected={(obj) => {
                if (obj?.status === "reservedByToken") {
                  warningToast(
                    "Asiento deseleccionado",
                    "El asiento " +
                      ticketLabelFormatter(obj) +
                      " se quitó de tus selecciones por que ya fue adquirido por otra persona"
                  );
                }

                setSelectedSeats((prev) => {
                  var copyPrev = [...prev];
                  if (
                    obj?.numSelected > 0 &&
                    prev.find((items) => items.uuid === obj?.uuid)
                  ) {
                    copyPrev[
                      prev.findIndex((items) => items.uuid === obj?.uuid)
                    ].numSelected = obj.numSelected;
                    return [...copyPrev];
                  } else {
                    return prev.filter((element) => element.uuid !== obj.uuid);
                  }
                });
              }}
              region="sa"
            />
          </Box>
          <Box
            w={"full"}
            overflowY={"auto"}
            h="100%"
            sx={{
              "&::-webkit-scrollbar": {
                width: "8px",
                borderRadius: "10px",
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: `secondary.main`,
                borderRadius: "10px",
              },
            }}
          >
            <Text fontSize={16} mb={5} fontWeight="semibold">
              Asientos seleccionados:
            </Text>
            {Object.keys(mappedSelectedSeats).length === 0 ? (
              <Text fontSize={14} color={"muted.600"}>
                Una vez que hayas seleccionados tus asientos, podrás visualizar
                el detalle de los mismos aquí.
              </Text>
            ) : (
              <VStack spacing={1} alignItems="left" flexGrow={1}>
                {Object.keys(mappedSelectedSeats).map((category, index) => {
                  const ticketsArray = mappedSelectedSeats[category];
                  return (
                    <Box key={index}>
                      <Flex
                        justifyContent={"space-between"}
                        borderWidth={1}
                        borderColor="secondary.contrast"
                        py={1}
                        px={2}
                        borderRadius={5}
                        direction={["column", "row"]}
                        alignItems={"center"}
                      >
                        <Stack direction={"row"}>
                          <Circle
                            size={5}
                            backgroundColor={
                              props.variants.find(
                                (cat) => cat.name === category
                              ).color ?? "primary.main"
                            }
                          />
                          <Text fontSize={14}>{category}</Text>
                        </Stack>
                      </Flex>
                      {ticketsArray.map((ticket, index) => {
                        return (
                          <Flex
                            key={index}
                            justifyContent={"space-between"}
                            py={1}
                            px={2}
                            borderRadius={5}
                            direction={["column", "row"]}
                            alignItems={"center"}
                            fontSize={16}
                          >
                            <Text noOfLines={1}>
                              {ticketLabelFormatter(ticket)}
                            </Text>
                            <IconButton
                              aria-label="Delete"
                              icon={<DeleteIcon />}
                              variant="ghost"
                              colorScheme={"primary"}
                              rounded="full"
                              onClick={() => {
                                ticket.objectType === "Seat"
                                  ? chartInstance.deselectObjects([ticket.uuid])
                                  : ticket.numSelected > 1
                                  ? chartInstance.deselectObjects([
                                      {
                                        id: ticket.id,
                                        amount: 1,
                                      },
                                    ])
                                  : chartInstance.deselectObjects([
                                      { id: ticket.id },
                                    ]);
                              }}
                            />
                          </Flex>
                        );
                      })}
                    </Box>
                  );
                })}
              </VStack>
            )}
          </Box>
        </Stack>
        <Card borderColor={"muted.400"} borderWidth={1}>
          <CustomTable
            columns={columns}
            data={reservations.tickets.map((seat) => {
              const parts = seat.id.split("-");
              return {
                variant:
                  mappedVariants.find((v) => v.id === seat.ticketVariantId)
                    ?.category ?? "",
                name: parts[0],
                row: parts[1],
                seat: parts[2],
                data: seat,
              };
            })}
            loading={reservationsLoading}
            title="Asientos reservados"
            searchProps={{
              enabled: false,
            }}
            stylesTableContainer={{
              background: "transparent",
            }}
            paginatorOptions={{
              enabled: true,
              type: "advanced",
            }}
          />
        </Card>
      </Stack>

      <Button
        isLoading={createReservationLoading}
        disabled={props.variantsLoading || createReservationLoading}
        borderRadius={"full"}
        colorScheme={"primary"}
        textAlign={"center"}
        alignSelf={"center"}
        maxW={"xs"}
        w={"100%"}
        my={5}
        px={10}
        onClick={formik.submitForm}
      >
        RESERVAR
      </Button>
    </FormikProvider>
  );
};
