// Chiletiket original theme
export const ChileTiketTheme = {
  backgroundColor: "#141414",
  backgroundContrast: "#FFFFFF",
  primary: {
    main: "#23FFDB",
    50: "#d7fffc",
    100: "#abfff2",
    200: "#23FFDB",
    300: "#00e6c0",
    400: "#00ffd5",
    500: "#00CCAA",
    600: "#00b395",
    700: "#00806b",
    800: "#004e40",
    900: "#001c15",
    contrast: "#000000",
  },
  secondary: {
    main: "#262626",
    50: "#f8f0f2",
    100: "#d9d9d9",
    200: "#bfbfbf",
    300: "#a6a6a6",
    400: "#8c8c8c",
    500: "#737373",
    600: "#595959",
    700: "#404040",
    800: "#262626",
    900: "#120b0d",
    contrast: "#FFFFFF",
  },
};

// TheJazzCorner theme
const JazzCornerTheme = {
  backgroundColor: "#EEE",
  backgroundContrast: "#000000",
  primary: {
    main: "#ecd32d",
    50: "#fffadd",
    100: "#faf0b3",
    200: "#f4e787",
    300: "#f0dd59",
    400: "#ecd32d",
    500: "#d2ba13",
    600: "#a4900b",
    700: "#756705",
    800: "#463e00",
    900: "#181500",
    contrast: "#000000",
  },

  secondary: {
    main: "#ffffff",
    50: "#fbf9f9",
    100: "#f0eced",
    200: "#e3e0e1",
    300: "#d5d2d3",
    400: "#c6c3c4",
    500: "#b5b2b3",
    600: "#a29fa0",
    700: "#8b8889",
    800: "#6d6c6c",
    900: "#403f3f",
    contrast: "#000000",
  },
};

//Random testing theme
const RandomTheme = {
  backgroundColor: "#00001e",
  backgroundContrast: "#FFFFFF",
  primary: {
    main: "f62252",
    50: "#ffe2ec",
    100: "#ffb3c5",
    200: "#fc839f",
    300: "#f95278",
    400: "#f62252",
    500: "#dd0939",
    600: "#ad032c",
    700: "#7c001e",
    800: "#4d0012",
    900: "#200005",
    contrast: "#000000",
  },

  secondary: {
    main: "#06037c",
    50: "#e5eaff",
    100: "#b5c3ff",
    200: "#8395fc",
    300: "#5164f9",
    400: "#2130f7",
    500: "#080ddd",
    600: "#0606ad",
    700: "#06037c",
    800: "#01074c",
    900: "#00041e",
    contrast: "#FFFFFF",
  },
};

export const colors = {
  ...ChileTiketTheme,
  // ...JazzCornerTheme,
  // ...RandomTheme,

  muted: {
    main: "#AAA9A9",
    50: "#fceff2",
    100: "#ddd8d9",
    200: "#c2bfbf",
    300: "#a6a5a5",
    400: "#8c8c8c",
    500: "#727272",
    600: "#5a5959",
    700: "#413f3f",
    800: "#292526",
    900: "#16090d",
  },

  red: {
    main: "#FF6051",
    50: "#ffe5e0",
    100: "#ffb7b1",
    200: "#ff897f",
    300: "#FF6051",
    400: "#fe2f1b",
    500: "#e51701",
    600: "#b30f00",
    700: "#810900",
    800: "#4f0400",
    900: "#200000",
  },

  purple: {
    main: "#3E3064",
    50: "#f1edfe",
    100: "#d3cbe8",
    200: "#b5a9d6",
    300: "#9786c4",
    400: "#7a64b3",
    500: "#604a99",
    600: "#4b3a78",
    700: "#352956",
    800: "#201936",
    900: "#0c0717",
  },
};
