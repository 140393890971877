import {
  Stack,
  SimpleGrid,
  useBreakpointValue,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Button,
  Center,
  Checkbox,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { Field, FormikProvider, useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { useProfileService } from "../../../services/profile/profileService";
import { cleanRut, formatRut, validateRut } from "../../../utils/rutFormatter";
import { useEmailValidator } from "../../../utils/useEmailValidator";
import { EmailInput } from "../../EmailInput";
import { useToaster } from "../../../utils/useToaster";
import "../../../theme/toastify-custom.css";

import { cleanDate, formatDate } from "../../../utils/formDateFormatter";
import { is } from "date-fns/locale";

interface ProfileFormProps {
  onSubmitDone?: () => void;
  showDescription?: boolean;
  submitLabel?: string;
}

export interface ProfileFormData {
  firstname: string;
  lastname: string;
  idNumber: string;
  birthday: Date;
  phone: string;
  district: string;
  address: string;
  city: string;
  billingFullname: string;
  billingMail?: string;
  billingIdNumber: string;
  billingPhone: string;
  billingCity: string;
  billingDistrict: string;
  billingAddress: string;
}

const ProfileDataForm: React.FC<ProfileFormProps> = ({
  onSubmitDone = null,
  submitLabel,
  showDescription,
}) => {
  const columnsResponsive = useBreakpointValue({
    xs: 1,
    md: 2,
  });

  const profileService = useProfileService();

  const profileData = profileService.profileStore.data;

  const { validateEmail, validationState } = useEmailValidator();

  // const {validateDate, validationDateState} = useDateValidator();

  const ProfileFormSchema = useMemo(
    () =>
      Yup.object().shape({
        firstname: Yup.string()
          .required("Debe ingresar un Nombre")
          .matches(
            /^[A-Za-zÀ-ÖØ-öø-ÿ,.' -]+$/i,
            "Tu nombre posee caracteres inválidos"
          )
          .max(50, "El nombre es muy largo"),
        lastname: Yup.string()
          .required("Debe ingresar un Apellido")
          .matches(
            /^[A-Za-zÀ-ÖØ-öø-ÿ,.' -]+$/i,
            "Tu apellido posee caracteres inválidos"
          )
          .max(50, "El apellido es muy largo"),
        idNumber: Yup.string()
          .required("Debe ingresar su RUT")
          .max(12, "El RUT es muy largo")
          .test("format", "El RUT ingresado es inválido", function (rut) {
            return validateRut(rut);
          }),
        birthday: Yup.string()
          .required("Debe ingresar su fecha de nacimiento")
          .matches(/^\d{2}\/\d{2}\/\d{4}$/, "El formato debe ser DD/MM/YYYY")
          .test(
            `validate-date`,
            "La fecha no esta dentro de los limites",
            (value) => {
              if (!value) {
                return false;
              }
              const minDate = new Date();

              minDate.setFullYear(new Date().getFullYear() - 100);
              const maxDate = new Date();
              maxDate.setFullYear(new Date().getFullYear() - 10);
              const dateParts = value.split("/");
              const date = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]),
                Number(dateParts[0])
              );
              if (date >= minDate && date <= maxDate) {
                return true;
              } else {
                return false;
              }
            }
          ),
        phone: Yup.string()
          .required("Debe ingresar su teléfono")
          .matches(/^[0-9-]*$/, "Teléfono inválido")
          .max(25, "Teléfono muy largo"),
        city: Yup.string()
          .required("Debe ingresar una ciudad")
          .matches(
            /^[0-9A-Za-zÀ-ÖØ-öø-ÿ,.' -]+$/i,
            "Tu ciudad posee caracteres inválidos"
          )
          .max(100, "El nombre de la ciudad es muy largo"),
        district: Yup.string()
          .required("Debe ingresar una comuna")
          .matches(
            /^[0-9A-Za-zÀ-ÖØ-öø-ÿ,.' -]+$/i,
            "Tu comuna posee caracteres inválidos"
          )
          .max(100, "El nombre de la comuna es muy largo"),
        address: Yup.string()
          .required("Debe ingresar una dirección")
          .matches(
            /^[0-9A-Za-zÀ-ÖØ-öø-ÿ,.' -]+$/i,
            "Tu dirección posee caracteres inválidos"
          )
          .max(100, "Tu dirección es muy larga"),
        billingFullname: Yup.string()
          .required("Debe ingresar un Nombre")
          .matches(
            /^[A-Za-zÀ-ÖØ-öø-ÿ,.' -]+$/i,
            "Tu nombre posee caracteres inválidos"
          )
          .max(100, "El nombre es muy largo"),
        billingMail: Yup.string()
          .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            "Este mail contiene caracteres inválidos"
          )
          .max(150, "El mail es muy largo")
          .test("validation", "El email ingresado no es válido", validateEmail),
        billingIdNumber: Yup.string()
          .required("Debe ingresar su RUT")
          .max(12, "El RUT es muy largo")
          .test("format", "El RUT ingresado es inválido", function (rut) {
            return validateRut(rut);
          }),
        billingPhone: Yup.string()
          .required("Debe ingresar su teléfono")
          .matches(/^[0-9-]*$/, "Teléfono inválido")
          .max(25, "Teléfono muy largo"),
        billingCity: Yup.string()
          .required("Debe ingresar una ciudad")
          .matches(
            /^[0-9A-Za-zÀ-ÖØ-öø-ÿ,.' -]+$/i,
            "Tu ciudad posee caracteres inválidos"
          )
          .max(100, "El nombre de la ciudad es muy largo"),
        billingDistrict: Yup.string()
          .required("Debe ingresar una comuna")
          .matches(
            /^[0-9A-Za-zÀ-ÖØ-öø-ÿ,.' -]+$/i,
            "Tu comuna posee caracteres inválidos"
          )
          .max(100, "El nombre de la comuna es muy largo"),
        billingAddress: Yup.string()
          .required("Debe ingresar una dirección")
          .matches(
            /^[0-9A-Za-zÀ-ÖØ-öø-ÿ,.' -]+$/i,
            "Tu dirección posee caracteres inválidos"
          )
          .max(100, "Tu dirección es muy larga"),
      }),
    [validateEmail]
  );

  const { loading, execute } = profileService.profileStore;
  const { successToast, errorToast } = useToaster();

  const handleSubmit = async (values: ProfileFormData) => {
    execute(
      () =>
        profileService.updateProfile({
          firstname: values.firstname,
          lastname: values.lastname,
          idNumber: values.idNumber,
          birthday: values.birthday,
          phone: values.phone,
          city: values.city,
          district: values.district,
          address: values.address,
          billingData: {
            fullname: values.billingFullname,
            mail: values.billingMail,
            idNumber: values.billingIdNumber,
            phone: values.billingPhone,
            city: values.billingCity,
            district: values.billingDistrict,
            address: values.billingAddress,
          },
        }),
      () => {
        onSubmitDone && onSubmitDone();
        successToast("Perfil guardado con éxito!", "Carga del perfil exitosa");
      },
      () => {
        errorToast(
          "Ocurrió un error",
          "Ocurrió un error al guardar el perfil. Porfavor, inténtalo mas tarde"
        );
      }
    );
  };

  const formik = useFormik<ProfileFormData>({
    initialValues: {
      firstname: profileData?.firstname || "",
      lastname: profileData?.lastname || "",
      birthday: profileData?.birthday,
      idNumber: profileData?.idNumber || "",
      phone: profileData?.phone || "",
      city: profileData?.city || "",
      district: profileData?.district || "",
      address: profileData?.address || "",
      billingFullname: profileData?.billingData?.fullname || "",
      billingMail: profileData?.billingData?.mail || "",
      billingIdNumber: profileData?.billingData?.idNumber || "",
      billingPhone: profileData?.billingData?.phone || "",
      billingCity: profileData?.billingData?.city || "",
      billingDistrict: profileData?.billingData?.district || "",
      billingAddress: profileData?.billingData?.address || "",
    },
    validationSchema: ProfileFormSchema,
    validateOnBlur: true,
    validateOnChange: false,

    onSubmit: handleSubmit,
  });

  const [defaultBillingData, setdefaultBillingData] = useState(true);

  useEffect(() => {
    if (
      profileData &&
      profileData.billingData &&
      profileData.billingData.fullname ===
        profileData.firstname + " " + profileData.lastname &&
      profileData.billingData.idNumber === profileData.idNumber &&
      profileData.billingData.phone === profileData.phone &&
      profileData.billingData.city === profileData.city &&
      profileData.billingData.district === profileData.district &&
      profileData.billingData.address === profileData.address &&
      !profileData.billingData.mail
    ) {
      setdefaultBillingData(true);
    } else {
      setdefaultBillingData(false);
    }
  }, [profileData]);

  useEffect(() => {
    if (defaultBillingData) {
      formik.setValues({
        ...formik.values,
        billingMail: undefined,
        billingFullname: formik.values.firstname + " " + formik.values.lastname,
        billingIdNumber: formik.values.idNumber,
        billingPhone: formik.values.phone,
        billingCity: formik.values.city,
        billingDistrict: formik.values.district,
        billingAddress: formik.values.address,
      });
    }
  }, [
    defaultBillingData,
    formik.values.firstname,
    formik.values.lastname,
    formik.values.idNumber,
    formik.values.phone,
    formik.values.city,
    formik.values.district,
    formik.values.address,
  ]);

  useEffect(() => {
    if (!defaultBillingData) {
      formik.setValues({
        ...formik.values,
        billingMail: profileData?.billingData?.mail ?? "",
        billingFullname: profileData?.billingData?.fullname ?? "",
        billingIdNumber: profileData?.billingData?.idNumber ?? "",
        billingPhone: profileData?.billingData?.phone ?? "",
        billingCity: profileData?.billingData?.city ?? "",
        billingDistrict: profileData?.billingData?.district ?? "",
        billingAddress: profileData?.billingData?.address ?? "",
      });
    }
  }, [defaultBillingData]);

  const [isRutExent, setIsRutExent] = useState(false);

useEffect(() => {
  setIsRutExent(profileData?.idNumber === "55555555-5");
},[])

  useEffect(() => {
    if (isRutExent) {
      formik.setValues({
        ...formik.values,
        idNumber: "55555555-5",
        city: "Santiago",
        district: "Santiago",
        billingIdNumber: "55555555-5",
        billingCity: "Santiago",
        billingDistrict: "Santiago",
      });
    } else {
      formik.setValues({
        ...formik.values,
        idNumber: profileData?.idNumber ?? "",
        city: profileData?.city ?? "",
        district: profileData?.district ?? "",
        billingIdNumber: profileData?.billingData?.idNumber ?? "",
        billingCity: profileData?.billingData?.city ?? "",
        billingDistrict: profileData?.billingData?.district ?? "",
      });
    }
  }, [isRutExent]);

  return (
    <FormikProvider value={formik}>
      <Stack spacing={8} h='auto'> 
        {showDescription && (
          <Text fontSize={{ base: "md", md: "lg" }} w="85%">
            Para finalizar la compra deberás completar todos los campos
            obligatorios*
          </Text>
        )}

        <SimpleGrid columns={columnsResponsive} spacing={10}>
          <Field name="firstname">
            {({ field, form }) => (
              <FormControl
                variant="floating"
                borderRadius={4}
                id="firstname"
                isInvalid={form.errors.firstname}
                isRequired
              >
                <Input {...field} placeholder=" " max={50} />
                {/* It is important that the Label comes after the Control due to css selectors */}
                <FormLabel>Nombre</FormLabel>
                <FormErrorMessage>{form.errors.firstname}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="lastname">
            {({ field, form }) => (
              <FormControl
                variant="floating"
                borderRadius={4}
                id="lastname"
                isInvalid={form.errors.lastname}
                isRequired
              >
                <Input {...field} placeholder=" " max={50} />
                {/* It is important that the Label comes after the Control due to css selectors */}
                <FormLabel>Apellido</FormLabel>
                <FormErrorMessage>{form.errors.lastname}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="birthday">
            {({ field, form }) => (
              <FormControl
                variant="floating"
                borderRadius={4}
                id="birthday"
                isInvalid={form.errors.birthday}
                isRequired
              >
                <Input
                  type="text"
                  {...field}
                  placeholder="DD/MM/YYYY"
                  onChange={(e) => {
                    formik.setFieldValue(
                      field.name,
                      formatDate(e.target.value)
                    );
                  }}
                />
                {/* It is important that the Label comes after the Control due to css selectors */}
                <FormLabel>Fecha de Nacimiento</FormLabel>
                <FormErrorMessage>{form.errors.birthday}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="address">
            {({ field, form }) => (
              <FormControl
                variant="floating"
                borderRadius={4}
                id="address"
                isInvalid={form.errors.address}
                isRequired
              >
                <Input {...field} placeholder=" " max={50} />
                {/* It is important that the Label comes after the Control due to css selectors */}
                <FormLabel>Dirección</FormLabel>
                <FormErrorMessage>{form.errors.address}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="phone">
            {({ field, form }) => (
              <FormControl
                variant="floating"
                borderRadius={4}
                id="phone"
                isRequired
                isInvalid={form.errors.phone}
              >
                <Input {...field} placeholder=" " max={25} />
                {/* It is important that the Label comes after the Control due to css selectors */}
                <FormLabel>Telefono</FormLabel>
                <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </SimpleGrid>
        <Stack
        >
          {/* <Text color={"backgroundContrast"} fontSize={20} fontWeight={600}> */}
          {/* </Text> */}
          <Checkbox
            w={"max-content"}
            colorScheme={"muted"}
            color={"backgroundContrast"}
            isChecked={isRutExent}
            onChange={(e) => setIsRutExent((prev) => !prev)}
          >
            Soy extranjero
          </Checkbox> 
          {!isRutExent && (
            
          <HStack justifyContent="space-between" alignItems='flex-start' w="full" gap="30px" style={{ marginTop: "20px" }}>
            <Field name="idNumber">
              {({ field, form }) => (
                <FormControl
                  variant="floating"
                  borderRadius={4}
                  id="idNumber"
                  isInvalid={form.errors.idNumber}
                  isRequired
                >
                  <Input
                    isDisabled={isRutExent}
                    {...field}
                    onChange={(e) => {
                      formik.setFieldValue(
                        field.name,
                        formatRut(cleanRut(e.target.value))
                      );
                    }}
                    placeholder=" "
                    maxLength={12}
                  />
                  {/* It is important that the Label comes after the Control due to css selectors */}
                  <FormLabel>RUT</FormLabel>
                  <FormErrorMessage>{form.errors.idNumber}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="city">
              {({ field, form }) => (
                <FormControl
                  variant="floating"
                  borderRadius={4}
                  id="city"
                  isInvalid={form.errors.city}
                  isRequired
                >
                  <Input
                    {...field}
                    placeholder=" "
                    max={50}
                    isDisabled={isRutExent}
                  />
                  {/* It is important that the Label comes after the Control due to css selectors */}
                  <FormLabel>Ciudad</FormLabel>
                  <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="district">
              {({ field, form }) => (
                <FormControl
                  variant="floating"
                  borderRadius={4}
                  id="district"
                  isInvalid={form.errors.district}
                  isRequired
                >
                  <Input
                    {...field}
                    placeholder=" "
                    max={50}
                    isDisabled={isRutExent}
                  />
                  {/* It is important that the Label comes after the Control due to css selectors */}
                  <FormLabel>Comuna</FormLabel>
                  <FormErrorMessage>{form.errors.district}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            </HStack>
          )}
            
        </Stack>
        <Stack>
          <Text color={"backgroundContrast"} fontSize={20} fontWeight={600}>
            Facturación
          </Text>
          <Checkbox
            w={"max-content"}
            colorScheme={"muted"}
            color={"backgroundContrast"}
            isChecked={defaultBillingData}
            onChange={(e) => setdefaultBillingData(e.target.checked)}
          >
            Mis datos personales y de facturación coinciden
          </Checkbox>
        </Stack>
        {!defaultBillingData && (
          <SimpleGrid columns={columnsResponsive} spacing={10}>
            <Field name="billingFullname">
              {({ field, form }) => (
                <FormControl
                  variant="floating"
                  borderRadius={4}
                  id="billingFullname"
                  isInvalid={form.errors.billingFullname}
                  isRequired
                >
                  <Input {...field} placeholder=" " max={50} />
                  {/* It is important that the Label comes after the Control due to css selectors */}
                  <FormLabel>Nombre completo</FormLabel>
                  <FormErrorMessage>
                    {form.errors.billingFullname}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={"billingMail"}>
              {({ field, form }) => (
                <FormControl
                  variant="floating"
                  borderRadius={4}
                  id="billingMail"
                  isRequired
                  isInvalid={form.errors.billingMail}
                >
                  <EmailInput
                    field={field}
                    form={form}
                    label={"Mail"}
                    validationState={validationState}
                    inputProps={{
                      maxLength: 150,
                    }}
                    inputGroupProps={{
                      borderRadius: 4,
                    }}
                  />

                  <FormErrorMessage>{form.errors.billingMail}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
{!isRutExent && (
              <>
             
            <Field name="billingIdNumber">
              {({ field, form }) => (
                <FormControl
                  variant="floating"
                  borderRadius={4}
                  id="billingIdNumber"
                  isInvalid={form.errors.billingIdNumber}
                  isRequired
                >
                  <Input
                    {...field}
                    onChange={(e) => {
                      formik.setFieldValue(
                        field.name,
                        formatRut(cleanRut(e.target.value))
                      );
                    }}
                    placeholder=" "
                    isDisabled={isRutExent}
                    maxLength={12}
                  />
                  {/* It is important that the Label comes after the Control due to css selectors */}
                  <FormLabel>RUT</FormLabel>
                  <FormErrorMessage>
                    {form.errors.billingIdNumber}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="billingCity">
              {({ field, form }) => (
                <FormControl
                  variant="floating"
                  borderRadius={4}
                  id="billingCity"
                  isInvalid={form.errors.billingCity}
                  isRequired
                >
                  <Input
                    {...field}
                    placeholder=" "
                    max={50}
                    isDisabled={isRutExent}
                  />
                  {/* It is important that the Label comes after the Control due to css selectors */}
                  <FormLabel>Ciudad</FormLabel>
                  <FormErrorMessage>{form.errors.billingCity}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="billingDistrict">
              {({ field, form }) => (
                <FormControl
                  variant="floating"
                  borderRadius={4}
                  id="billingDistrict"
                  isInvalid={form.errors.billingDistrict}
                  isRequired
                >
                  <Input
                    {...field}
                    placeholder=" "
                    max={50}
                    isDisabled={isRutExent}
                  />
                  {/* It is important that the Label comes after the Control due to css selectors */}
                  <FormLabel>Comuna</FormLabel>
                  <FormErrorMessage>
                    {form.errors.billingDistrict}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
 </>
)}
            <Field name="billingAddress">
              {({ field, form }) => (
                <FormControl
                  variant="floating"
                  borderRadius={4}
                  id="billingAddress"
                  isInvalid={form.errors.billingAddress}
                  isRequired
                >
                  <Input {...field} placeholder=" " max={50} />
                  {/* It is important that the Label comes after the Control due to css selectors */}
                  <FormLabel>Dirección</FormLabel>
                  <FormErrorMessage>
                    {form.errors.billingAddress}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="billingPhone">
              {({ field, form }) => (
                <FormControl
                  variant="floating"
                  borderRadius={4}
                  isRequired
                  id="billingPhone"
                  isInvalid={form.errors.billingPhone}
                >
                  <Input {...field} placeholder=" " max={25} />
                  {/* It is important that the Label comes after the Control due to css selectors */}
                  <FormLabel>Telefono</FormLabel>
                  <FormErrorMessage>
                    {form.errors.billingPhone}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </SimpleGrid>
        )}
        <Center>
          <Button
            colorScheme={"primary"}
            borderRadius="full"
            disabled={loading || formik.isValidating}
            isLoading={loading || formik.isValidating}
            w={{ xs: "fit-content", md: "30%" }}
            onClick={() => {
              formik.submitForm();
            }}
          >
            {submitLabel ?? "Continuar"}
          </Button>
        </Center>
      </Stack>
    </FormikProvider>
  );
};

export default ProfileDataForm;
