export function cleanDate(inputDate) {
    return typeof inputDate === "string"
      ? inputDate.replace(/[^0-9kK]+/g, "")
      : "";
}

export function formatDate(inputDate) {
    inputDate = cleanDate(inputDate);
    let formattedValue = inputDate.replace(/\D/g, '');

    if (formattedValue.length > 2 && formattedValue.length <= 4) {
        formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2)}`;
      } else if (formattedValue.length > 4) {
        formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2, 4)}/${formattedValue.slice(4, 8)}`;
      }
    
    return formattedValue;
}

