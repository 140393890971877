import { useCallback, useState } from "react";
import { useAuthService } from "../services/auth/authService";
export type ValidationState = "pending" | "success" | "loading";

export const useEmailValidator = () => {
  const authService = useAuthService();

  const [validationState, setValidationState] =
    useState<ValidationState>("pending");
  const [editing, setEditing] = useState(false);

  const validateEmail = useCallback(async (email?: string) => {
    setEditing(true);

    if (!email) {
      setEditing(false);
      return true;
    }
    if (email.length < 5) {
      setEditing(false);
      return false;
    }
    let valid = false;
    try {
      setValidationState("loading");
      await authService.validateEmail(email);
      setValidationState("success");
      valid = true;
    } catch (error) {
      setValidationState("pending");
      valid = false;
    } finally {
      setEditing(false);
      return valid;
    }
  }, []);

  return { validateEmail, validationState, editing, setValidationState };
};
