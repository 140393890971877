import axios from 'axios';
import { SearchPlaceDTO } from './dto';

export interface PlacesSearchService {
  fetchResults: (params?: any) => Promise<SearchPlaceDTO>;
}

export const usePlacesSearchService = (): PlacesSearchService => {
  const fetchResults = async search => {
    //console.log('Params get: ', search);
    const res = await axios.get('https://api-test.bikleta.com/bikleta/api/v1/routes/places', {
      params: {
        query: search,
      },
    });
    return res.data;
  };

  return { fetchResults };
};
