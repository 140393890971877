import React, { useMemo, useRef, useState } from "react";
import CustomTable from "../../../../../components/Table";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  Select,
  Stack,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Field, FormikProvider, useFormik } from "formik";
import { useEventAdminInfoController } from "../../event-admin-info.controller";
import * as Yup from "yup";
import { IDiscountCode } from "../../../../../services/Admin/discountCodes/dto";
import { EditIcon } from "@chakra-ui/icons";
import { MobileDrawer } from "./MobileDrawer";
import { useToaster } from "../../../../../utils/useToaster";
import image from "../../../../../resources/images/ilustration.png";
import { SaveIcon } from "../../../../../components/Icons/SaveIcon";
import { DeleteDiscountIcon } from "../../../../../components/Icons/DeleteDiscountIcon";
import { DeleteModal } from "./DeleteModal";

export const DiscountCodesTable = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEditing, setIsEditing] = useState(false);
  const infoController = useEventAdminInfoController();

  const columns = [
    {
      id: "function",
      Header: "Función",
      accessor: "functionId",
      meta: {
        filterOperator: "contains",
      },
      isCentered: true,
      enableColumnFilter: true,
      enableGlobalFilter: true,
      Cell: ({
        row: {
          original: { functionId },
        },
      }) => {
        const found = infoController.eventFunctions.find(
          (el) => el.value === functionId
        );
        return <Text size={"md"}>{found.label}</Text>;
      },
    },
    {
      id: "code",
      Header: "Código",
      accessor: "code",
      meta: {
        filterOperator: "contains",
      },
      isCentered: true,
      enableColumnFilter: true,
      enableGlobalFilter: true,
    },
    {
      id: "value",
      Header: "Descuento",
      accessor: "value",
      meta: {
        filterOperator: "contains",
      },
      isCentered: true,
      enableColumnFilter: true,
      enableGlobalFilter: true,
      Cell: ({
        row: {
          original: { discountType, value },
        },
      }) => (
        <Text size={"md"}>
          {discountType === "fixed" ? `$ ${value}` : `% ${value}`}
        </Text>
      ),
    },
    {
      id: "quantity",
      Header: "Stock",
      accessor: "quantity",
      meta: {
        filterOperator: "contains",
      },
      isCentered: true,
    },
    {
      id: "status",
      Header: "Estado",
      accessor: "status",
      meta: {
        filterOperator: "contains",
      },
      enableColumnFilter: true,
      enableGlobalFilter: true,
      isCentered: true,
      Cell: ({
        row: {
          original: { status },
        },
      }) => (
        <Tag size={"md"} colorScheme={status === "active" ? "green" : "red"}>
          {status}
        </Tag>
      ),
    },
  ];

  const btnRef = React.useRef();

  const FormSchema = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string()
          .required("Debes ingresar un código")
          .max(50, "El código es muy largo")
          .matches(
            /^[a-zA-Z0-9]+$/,
            "No debe contener caracteres especiales ni espacios en blanco"
          ),
        discountType: Yup.string().required("Debes seleccionar un tipo"),
        value: Yup.number()
          .min(1, "Debe ser mayor o igual a 1")
          .required("Debes ingresar una cantidad"),
        quantity: Yup.number()
          .min(1, "Debe ser mayor o igual a 1")
          .required("Debes ingresar un stock"),
        status: Yup.string().required("Debes seleccionar un status"),
      }),
    []
  );

  const formik = useFormik<IDiscountCode>({
    initialValues: {
      code: "",
      company: infoController.currentEventId,
      discountType: "fixed",
      value: 1,
      status: "active",
      quantity: 1,
    },
    validationSchema: FormSchema,
    onSubmit: (data) => {
      formik.resetForm();
      if (isEditing) {
        onClose();
        infoController.editExecute(data._id, data);
      } else {
        onClose();
        infoController.createExecute(data, infoController.activeFunction.value);
      }
    },
  });
  const discountTypes = [
    { label: "Fijo", value: "fixed" },
    { label: "Porcentaje", value: "percentage" },
  ];
  const discountStates = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];
  const tableRef = useRef(null);

  const handleOpenCreate = () => {
    setIsEditing(false);
    formik.resetForm();
    onOpen();
  };
  const handleOpenEdit = (original: IDiscountCode) => {
    setIsEditing(true);
    formik.setValues(original);
    onOpen();
  };

  return (
    <Stack spacing={10}>
      {infoController.discountCodesList !== undefined &&
        !infoController.discountCodesListLoading &&
        infoController.discountCodesList.docs !== undefined && (
          <>
            <CustomTable
              ref={tableRef}
              customHeader={
                <HStack
                  w="100%"
                  justifyContent={["start", "space-between"]}
                  flexWrap={["wrap", "wrap", "wrap", "wrap", "nowrap"]}
                  gap={4}
                  mt={[1, 1, 5]}
                >
                  <Button
                    w={"fit-content"}
                    onClick={handleOpenCreate}
                    ref={btnRef}
                    variant="solid"
                    colorScheme="primary"
                    borderRadius="full"
                    py="8px"
                    pl="24px"
                    pr="32px"
                  >
                    + Código
                  </Button>
                  {/* <MobileDrawer/> */}
                </HStack>
              }
              customEmptyState={
                <Flex
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  py="60px"
                  gap="20px"
                >
                  <Image src={image} />
                  <Text noOfLines={2} textColor="muted.600">
                    Todavía no tienes códigos de descuento creados para este
                    evento
                  </Text>
                </Flex>
              }
              editFn={handleOpenEdit}
              columns={columns}
              data={infoController.discountCodesList.docs}
              pageCount={infoController.discountCodesList.totalPages}
              loading={infoController.discountCodesListLoading}
              totalDocs={infoController.discountCodesList.totalDocs}
            />
          </>
        )}

      <Drawer
        isOpen={isOpen}
        placement={"right"}
        onClose={onClose}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <FormikProvider value={formik}>
          <DrawerContent
            color={"secondary.contrast"}
            borderLeft={"1px solid muted.200"}
          >
            <DrawerCloseButton mt={8} />
            <DrawerHeader bgColor={"secondary.main"} pt={8}>
              {isEditing ? "Editar" : "Agregar"} código
            </DrawerHeader>
            <DrawerBody bgColor={"secondary.main"} px={14} pt={14}>
              <Stack w="full" gap={6}>
                <HStack gap="15px">
                  <FormControl w="50%">
                    <FormLabel>
                      {/* {t("posts.fields.title")} */}
                      Evento
                    </FormLabel>
                    <Input
                      id="title"
                      type="text"
                      border={"none"}
                      borderBottom="1px solid"
                      borderColor="muted.200"
                      borderRadius={0}
                      fontSize={14}
                      sx={{
                        _focus: {
                          boxShadow: "none",
                          borderBottom: "1px solid",
                          borderColor: "green.200",
                        },
                      }}
                      value={infoController?.eventInfo?.name}
                      disabled
                    />
                    {/* <FormErrorMessage>{`${errors.title?.message}`}</FormErrorMessage> */}
                  </FormControl>
                  <FormControl w="50%">
                    <FormLabel>
                      {/* {t("posts.fields.title")} */}
                      Función
                    </FormLabel>
                    <Input
                      id="title"
                      type="text"
                      border={"none"}
                      borderBottom="1px solid"
                      borderColor="muted.200"
                      fontSize={14}
                      borderRadius={0}
                      sx={{
                        _focus: {
                          boxShadow: "none",
                          borderBottom: "1px solid",
                          borderColor: "green.200",
                        },
                        _valid: {
                          borderColor: "green.200",
                        },
                      }}
                      value={infoController.activeFunction.label}
                      disabled
                    />
                    {/* <FormErrorMessage>{`${errors.title?.message}`}</FormErrorMessage> */}
                  </FormControl>
                </HStack>
                <Field name="code">
                  {({ field, form }) => (
                    <FormControl>
                      <FormLabel textColor={formik.errors.code && "red"}>
                        Código
                      </FormLabel>
                      <Input
                        id="code"
                        type="text"
                        border="1px solid"
                        size="lg"
                        borderRadius={4}
                        borderColor={formik.errors.code ? "red" : "muted.200"}
                        fontSize={14}
                        sx={{
                          _focus: {
                            boxShadow: "none",
                            borderBottom: "1px solid",
                            borderColor: formik.errors.code
                              ? "red"
                              : "green.200",
                          },
                          _hover: {
                            borderColor: formik.errors.code && "red",
                          },
                          _valid: {
                            borderColor: "green.200",
                          },
                        }}
                        placeholder="Ingrese código de descuento (ej:Chiletiket20)"
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        value={formik.values.code}
                        onChange={(e) => {
                          formik.setValues({
                            ...formik.values,
                            code: e.target.value.toUpperCase(),
                          });
                        }}
                        // {...field}
                      />
                      <Text
                        textColor="red"
                        fontSize={12}
                        lineHeight={2}
                        noOfLines={1}
                      >{`${formik.errors.code ?? ""}`}</Text>
                    </FormControl>
                  )}
                </Field>
                <HStack gap="15px" alignItems="start">
                  <Field name="discountType">
                    {({ field, form }) => (
                      <FormControl>
                        <FormLabel>Tipo</FormLabel>
                        <Select
                          borderColor="muted.200"
                          focusBorderColor="green.200"
                          fontSize={14}
                          {...field}
                        >
                          {discountTypes.map((discount) => (
                            <option value={discount.value}>
                              {discount.label}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="value">
                    {({ field }) => (
                      <FormControl>
                        <FormLabel>Descuento</FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <Text>
                              {formik.values.discountType === "fixed"
                                ? "$"
                                : "%"}
                            </Text>
                          </InputLeftElement>
                          <Input
                            type="number"
                            placeholder="Cantidad de descuento"
                            borderColor="muted.200"
                            fontSize={14}
                            sx={{
                              _focus: {
                                boxShadow: "none",
                                borderBottom: "1px solid",
                                borderColor: "green.200",
                              },
                              _valid: {
                                borderColor: "green.200",
                              },
                            }}
                            {...field}
                          />
                        </InputGroup>

                        <Text
                          textColor="red"
                          fontSize={12}
                          lineHeight={2}
                          noOfLines={1}
                        >{`${formik.errors.value ?? ""}`}</Text>
                      </FormControl>
                    )}
                  </Field>
                </HStack>
                <HStack gap="15px" alignItems="start">
                  <Field name="quantity">
                    {({ field, form }) => (
                      <FormControl>
                        <FormLabel>Stock</FormLabel>
                        <Input
                          type="number"
                          placeholder="Ingresa stock"
                          borderColor="muted.200"
                          fontSize={14}
                          sx={{
                            _focus: {
                              boxShadow: "none",
                              borderBottom: "1px solid",
                              borderColor: "green.200",
                            },
                            _valid: {
                              borderColor: "green.200",
                            },
                          }}
                          {...field}
                        />
                        <Text
                          textColor="red"
                          fontSize={12}
                          lineHeight={2}
                          noOfLines={1}
                        >{`${formik.errors.quantity ?? ""}`}</Text>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="status">
                    {({ field, form }) => (
                      <FormControl mb="3">
                        <FormLabel>
                          {/* {t("posts.fields.title")} */}
                          Estado
                        </FormLabel>
                        <Select
                          borderColor="muted.200"
                          focusBorderColor="green.200"
                          fontSize={14}
                          {...field}
                        >
                          {discountStates.map((state) => (
                            <option value={state.value}>{state.label}</option>
                          ))}
                        </Select>
                        <FormErrorMessage>{`${formik.errors.status}`}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </HStack>
              </Stack>
            </DrawerBody>
            <DrawerFooter
              bgColor={"secondary.main"}
              w="full"
              borderTop="1px solid muted.200"
            >
              <HStack
                w="full"
                justifyContent="flex-end"
                alignItems="center"
                gap="24px"
              >
                {isEditing && (
                  <DeleteModal
                    deleteFn={() => {
                      infoController.deleteDiscountCodeExecute(
                        formik.values._id
                      );
                      onClose();
                    }}
                  />
                )}
                <Button
                  bgColor={"primary.main"}
                  variant="solid"
                  colorScheme="primary"
                  borderRadius="full"
                  py="8px"
                  pl="24px"
                  pr="32px"
                  gap="8px"
                  onClick={() => {
                    formik.validateForm().then((data) => {
                      formik.submitForm();
                    });
                  }}
                >
                  {isEditing && <SaveIcon />}
                  {isEditing ? `Guardar` : " Crear código"}
                </Button>
              </HStack>
            </DrawerFooter>
          </DrawerContent>
        </FormikProvider>
      </Drawer>
    </Stack>
  );
};
