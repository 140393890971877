import { useEffect, useState } from "react";
import { useUserEventsService } from "../../../services/Admin/events/userEventsService";
import { EventDTO, TicketVariantDTO } from "../../../services/events/dto";
import { EventListController } from "./interfaces";
import {
  StoredFormData,
  useEventFormStore,
} from "../../../services/createEvent/createEventStore";
import { format } from "date-fns";
import { TicketVariantFormData } from "../../../services/createEvent/dto";
import { useNavigate } from "react-router-dom";
import { useEventsService } from "../../../services/events/eventsService";

const mapEventToForm = (
  event: EventDTO,
  tickets: TicketVariantFormData[]
): StoredFormData => {
  return {
    organizer: {
      name: event.meta.manager.name,
      mail: event.meta.manager.mail,
      phone: event.meta.manager.phone,
      bank: event.meta.manager.paymentMethod.bank,
      accountType: event.meta.manager.paymentMethod.paymentType,
      accountNumber: event.meta.manager.paymentMethod.paymentData,
    },

    eventData: {
      name: event.name,
      category: event.meta.category,
      type: event.meta.eventType,
      restriction: event.meta.restriction,
      placeName: event.placeName,
      place: undefined,
      description: event.description,
      hasSeats: false,
      hasQueue: false,
      seatsMail: undefined,
      seatsPhone: undefined,
    },

    functions: {
      functions: event.functions.map((fun) => ({
        openingDate: {
          value: fun.datetime.toString(),
          label: format(new Date(fun.datetime), "dd/MM/yyyy"),
        },
        closingDate: {
          value: fun.endDatetime.toString(),
          label: format(new Date(fun.endDatetime), "dd/MM/yyyy"),
        },
        openingHour: format(new Date(fun.datetime), "HH:mm"),
        closingHour: format(new Date(fun.endDatetime), "HH:mm"),
      })),
    },

    tickets: {
      tickets: tickets.map((ticket) => ({
        name: ticket.name,
        description: ticket.description,
        color: ticket.color,
        amount: ticket.available,
        price: ticket.price?.toString(),
        openingDate: {
          value: new Date(ticket.sellStartDatetime).toString(),
          label: format(new Date(ticket.sellStartDatetime), "dd/MM/yyyy"),
        },
        closingDate: {
          value: new Date(ticket.sellFinishDatetime).toString(),
          label: format(new Date(ticket.sellFinishDatetime), "dd/MM/yyyy"),
        },
        openingHour: format(new Date(ticket.sellStartDatetime), "HH:mm"),
        closingHour: format(new Date(ticket.sellFinishDatetime), "HH:mm"),
        maxQuantityPerUser: ticket.maxQuantityPerUser ?? undefined,
        minimumQuantityPerUser: ticket.minimumQuantityPerUser ?? undefined,
      })),
    },

    images: undefined,
    paymentMethods: { methods: event.paymentMethods },
  };
};

export const useEventListController = (): EventListController => {
  const userEventsService = useUserEventsService();

  const navigate = useNavigate();

  const emptyItem: EventDTO = {
    _id: "636e63dc0c13d668469836e5",
    created: new Date(),
    name: "Test",
    place: "string",
    company: "CHILE_TICKETS",
    capacity: 0,
    description:
      "Los Backstreet Boys vuelven a Chile después de no sé cuantos años! Deberían pagarme mas para investigar estos detalles",
    tags: ["string"],
    organizer: "testing",
    displayType: "carousel",
    placeName: "Estadio Único de Santiago",
    lowestPrice: 10000,
    flayer: {
      sm: "none",
      md: "none",
      xl: "none",
    },
    images: {
      card: { url: "none" },
      detail: { url: "none" },
      banner: { url: "none" },
      bannerMobile: { url: "none" },
    },
    meta: {
      eventType: "Música",
      category: "Música",
      majorityAge: true,
    },
    __v: 0,
    functions: [
      {
        _id: "636e63dd0c13d668469836e7",
        eventId: "636e63dc0c13d668469836e5",
        lowestPrice: 150000,
        status: "string",
        datetime: new Date(),
        endDatetime: new Date(),
        ticketVariants: [],
        tokens: ["test"],
        company: "CHILE_TICKET",
        __v: 0,
      },
    ],
    updated: new Date(),
    state: "Approved",
    isExempt: false,
  };

  const [listEvents, setListEvents] = useState<EventDTO[]>([
    emptyItem,
    emptyItem,
    emptyItem,
  ]);

  const {
    data: userEventList,
    execute,
    loading: loadingList,
  } = userEventsService.userEventsStore;

  const eventsService = useEventsService();

  useEffect(() => {
    if (!userEventList) {
      execute(userEventsService.getUserEventList);
    } else {
      setListEvents(userEventList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEventList]);

  const { setFormData, setMode } = useEventFormStore();

  const onEventDuplicate = async (event: EventDTO) => {
    setMode("duplicate");
    let tickets = event.functions.reduce(
      (prev, fun) => prev.concat(fun.ticketVariants),
      [] as TicketVariantFormData[]
    );
    if (tickets.length === 0) {
      const eventTickets = await eventsService.getTicketVariants(event._id);
      tickets = Object.values(eventTickets).reduce(
        (prev, variants) => prev.concat(variants),
        [] as TicketVariantDTO[]
      ) as undefined as TicketVariantFormData[];
    }
    setFormData(mapEventToForm(event, tickets));
    navigate("/create-event");
  };

  return {
    userEventList: listEvents,
    loadingList,
    onEventDuplicate,
  };
};
