import { useContext } from "react";
import { AxiosContext } from "../../../providers/axiosContext";
import { RegularInvitationDTO, SeatsInvitationDTO } from "./dto";

export interface InvitationsService {
  createRegularInvitation: (
    functionId: string,
    invitation: RegularInvitationDTO
  ) => Promise<void>;
  createSeatsInvitation: (
    functionId: string,
    invitation: SeatsInvitationDTO
  ) => Promise<void>;
}

export const useInvitationsService = (): InvitationsService => {
  const axiosContext = useContext(AxiosContext);

  const createRegularInvitation = async (
    functionId: string,
    invitation: RegularInvitationDTO
  ) => {
    const res = await axiosContext.authAxios.post(
      `/invitations/invitations/function/${functionId}`,
      invitation
    );
    return res.data;
  };
  const createSeatsInvitation = async (
    functionId: string,
    invitation: SeatsInvitationDTO
  ) => {
    const res = await axiosContext.authAxios.post(
      `/invitations/invitations/seats/function/${functionId}`,
      invitation
    );
    return res.data;
  };

  return {
    createRegularInvitation,
    createSeatsInvitation,
  };
};
