import { createAsyncStore } from "../../utils/createAsyncStore";
import { ShoppingSessionDTO } from "./dto";

export const useShoppingSessionAsyncStore =
  createAsyncStore<ShoppingSessionDTO>({
    defaultValue: undefined,
    persistKey: "shoppingSession",
    debugName: "shoppingSession",
    userPrivate: true,
  });
