export const StyledSelectProps = {
  classNamePrefix: "chakra-react-select",
  // Required for floating label
  // https://github.com/csandman/chakra-react-select/issues/83
  chakraStyles: {
    menu: (provided) => ({
      ...provided,
      zIndex: 3,
    }),
    control: (provided) => ({
      ...provided,
      borderColor: "backgroundContrast",
    }),
    menuList: (provided) => ({
      ...provided,
      bg: "secondary.main",
      zIndex: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      bgColor: state.isSelected ? "primary.main" : provided.bgColor,
      color: state.isSelected ? "primary.contrast" : "secondary.contrast",
      _hover: { bgColor: "secondary.300" },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      bg: "transparent",
      px: 2,
      cursor: "inherit",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
  },
};
