import { Stack, Text } from "@chakra-ui/react";
import { toast, ToastOptions } from "react-toastify";

interface ToastMethods {
  successToast: (
    title: string,
    description: string,
    options?: ToastOptions
  ) => void;
  errorToast: (
    title: string,
    description: string,
    options?: ToastOptions
  ) => void;
  defaultToast: (
    title: string,
    description: string,
    options?: ToastOptions
  ) => void;
  warningToast: (
    title: string,
    description: string,
    options?: ToastOptions
  ) => void;
  closeAll: () => void;
}

const ToastRenderer = ({ title, description }) => (
  <Stack spacing={0}>
    <Text fontSize={"md"} fontWeight={"bold"}>
      {title}
    </Text>
    {description && <Text fontSize={"sm"}>{description}</Text>}
  </Stack>
);

export const useToaster = (): ToastMethods => {
  const successToast: ToastMethods["successToast"] = (
    title,
    description,
    options
  ) => {
    toast.success(<ToastRenderer title={title} description={description} />, {
      ...options,
    });
  };

  const errorToast: ToastMethods["errorToast"] = (
    title,
    description,
    options
  ) => {
    toast.error(<ToastRenderer title={title} description={description} />, {
      ...options,
    });
  };

  const defaultToast: ToastMethods["defaultToast"] = (
    title,
    description,
    options
  ) => {
    toast(<ToastRenderer title={title} description={description} />, {
      ...options,
    });
  };

  const warningToast: ToastMethods["warningToast"] = (
    title,
    description,
    options
  ) => {
    toast.warning(<ToastRenderer title={title} description={description} />, {
      ...options,
    });
  };

  const closeAll = () => toast.dismiss();

  return {
    successToast,
    errorToast,
    defaultToast,
    warningToast,
    closeAll,
  };
};
