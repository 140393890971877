import { Stack, Box } from "@chakra-ui/react";
import React from "react";
import { CartSummary } from "../components/cart-summary";
import EventInfo from "../components/event-info";
import EventMap from "../components/eventMap";
import { TicketSelector } from "../components/ticket-selector";
import { LayoutProps } from "../interfaces";

const MapLayout: React.FC<LayoutProps> = ({ controller }) => {
  return (
    <>
      <EventInfo controller={controller} />
      {!controller.eventLoading && !controller.cartSummary.loading && (
        <Box
          display={"flex"}
          flexDir={["column-reverse", "column-reverse", "row"]}
          px={{ sm: 5, md: "7%" }}
        >
          <Stack direction={"column"} w="100%" spacing={10} px={5} mt={5}>
            <TicketSelector {...controller.eventFunctions} />
            <CartSummary {...controller.cartSummary} />
          </Stack>
          <EventMap
            eventLoading={controller.eventLoading}
            mapImageURL={controller.mapImageURL}
            purchasedTickets={controller.cartSummary.tickets}
          />
        </Box>
      )}
    </>
  );
};

export default MapLayout;
