import { useEffect, useState } from "react";
import { useMatches } from "react-router-dom";

export const useChildrenIndex = () => {
  const matches = useMatches();
  const [routeIndex, setRouteIndex] = useState(1);

  useEffect(() => {
    if (matches && matches[1] && matches[1].id) {
      setRouteIndex(
        parseInt(matches[matches.length - 1].id.split("-")[matches.length - 1])
      );
    }
  }, [matches]);

  return routeIndex;
};
