import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { Field, FormikProvider, useFormik } from "formik";
import React, { useMemo } from "react";
import * as Yup from "yup";
import { EmailInput } from "../../../../../components/EmailInput";
import { useEmailValidator } from "../../../../../utils/useEmailValidator";

interface AddAdminFormProps {
  addMethod: (email: string, role: string) => Promise<void>;
  onClose: () => void;
}

const AddAdminForm: React.FC<AddAdminFormProps> = ({ addMethod, onClose }) => {
  const { validateEmail, validationState } = useEmailValidator();
  const AddAdminFormSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email("Correo invalido")
          .required("Debe ingresar un correo")
          .test("validation", "El email ingresado no es válido", validateEmail),
        role: Yup.string().required(),
      }),
    [validateEmail]
  );

  const handleSubmit = async (values) => {
    console.log(values);

    try {
      await addMethod(values.email, values.role);
    } catch (error) {
      console.log(error);
    } finally {
      onClose();
    }
  };

  const formikInstance = useFormik({
    initialValues: {
      email: "",
      role: "admin",
    },
    validateOnChange: false,
    isInitialValid: false,
    validationSchema: AddAdminFormSchema,
    onSubmit: handleSubmit,
  });

  return (
    <FormikProvider value={formikInstance}>
      <Stack spacing={10} direction="column">
        <Field name="email">
          {({ field, form }) => (
            <FormControl isInvalid={form.errors.email}>
              <FormLabel>1. Ingresa el mail del usuario</FormLabel>
              <EmailInput
                field={field}
                form={form}
                validationState={validationState}
              />
              <FormErrorMessage>{form.errors.email}</FormErrorMessage>
            </FormControl>
          )}
        </Field>

        <Field name="role">
          {({ field, form }) => (
            <FormControl isInvalid={form.errors.role && form.touched.role}>
              <FormLabel>2. Seleccione el rol del usuario</FormLabel>
              <RadioGroup colorScheme={"primary"} {...field}>
                <Stack>
                  <Radio {...field} value="admin" borderColor={"muted.400"}>
                    Administrador
                  </Radio>
                  <Radio {...field} value="readOnly" borderColor={"muted.400"}>
                    Solo Lectura
                  </Radio>
                </Stack>
              </RadioGroup>
              <FormErrorMessage>{form.errors.role}</FormErrorMessage>
            </FormControl>
          )}
        </Field>

        <Center>
          <Button
            rounded={"full"}
            colorScheme="primary"
            textTransform={"uppercase"}
            variant="outline"
            w={["full", "50%"]}
            disabled={
              formikInstance.isSubmitting || formikInstance.isValidating
            }
            isLoading={
              formikInstance.isSubmitting || formikInstance.isValidating
            }
            onClick={() => {
              formikInstance.submitForm();
            }}
          >
            Añadir usuario
          </Button>
        </Center>
      </Stack>
    </FormikProvider>
  );
};

export default AddAdminForm;
