import { extendTheme } from "@chakra-ui/react";
import { Calendar, CalendarDefaultTheme } from "@uselessdev/datepicker";
import React from "react";
import { es } from "date-fns/locale";

export const calendarTheme = extendTheme(CalendarDefaultTheme, {
  components: {
    Calendar: {
      parts: ["calendar", "months"],

      baseStyle: {
        calendar: {
          w: "100%",
        },
        months: {
          justifyContent: "center",
          display: "flex",
        },
      },
    },

    CalendarMonth: {
      parts: ["month", "name", "week", "weekday", "days"],
      baseStyle: {
        month: {
          width: "100%",
        },
        name: {
          h: 8,
          fontSize: "md",
          lineHeight: 6,
          textAlign: "center",
          textTransform: "capitalize",
        },
        week: {
          gridTemplateColumns: "repeat(7, 1fr)",
        },
        weekday: {
          color: "gray.500",
          textAlign: "center",
          textTransform: "capitalize",
        },
        days: {
          gridTemplateColumns: "repeat(7, 1fr)",
          // bgColor: "red",
          width: "100%",
        },
      },
      defaultProps: { name: { as: "h2" } },
    },

    CalendarDay: {
      baseStyle: {
        fontSize: ["4vw", 18],
        padding: 2,
        width: "100%",
        textAlign: "center",
        alignSelf: "center",
        justifySelft: "center",
        minWidth: 0,
        display: "flex",
        bgColor: "transparent",
        // bgColor: "blue",

        _hover: { bgColor: "gray.100" },
        _disabled: {
          color: "gray.500",
          _hover: { cursor: "initial", bgColor: "transparent" },
        },
      },
      sizes: { sm: { h: 8 } },
      variants: {
        selected: {
          bgColor: "primary.main",
          color: "black",
          _hover: { bgColor: "primary.300" },
        },
        range: {
          bgColor: "primary.600",
          color: "black",
          _hover: { bgColor: "primary.200" },
          _disabled: { _hover: { bgColor: "primary.300" } },
        },
        outside: { color: "gray.300" },
        today: {
          bgColor: "primary.200",
          _hover: { bgColor: "primary.300" },
        },
      },
      defaultProps: { size: "sm" },
    },
  },
});

const ThemedCalendar = (props: Calendar) => {
  return <Calendar locale={es} weekdayFormat="EEEEE" {...props} />;
};

export default ThemedCalendar;
