import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Divider,
  HStack,
  Image,
  Skeleton,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FaMapMarkerAlt, FaUser } from "react-icons/fa";

import { EventDetailController } from "../interfaces";

const EventInfo = (props: { controller: EventDetailController }) => {
  const [imageLoaded, setImageLoaded] = useState(true);

  const buyRef = useRef(null);

  useEffect(() => {
    setImageLoaded(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [props.controller.imageURL]);

  return (
    <>
      <Box h={"max-content"} px={{ md: "7%" }} pt="2%">
        <Box bg="transparent" borderRadius={{ md: 20 }}>
          <Skeleton
            startColor="secondary.800"
            endColor="secondary.700"
            width={"100%"}
            style={{ aspectRatio: 3.96 }}
            borderRadius={{ md: 20 }}
            overflow={"hidden"}
            isLoaded={!props.controller.eventLoading && imageLoaded}
          >
            <Image
              src={props.controller.imageURL}
              onLoad={() => setImageLoaded(true)}
              onError={() => setImageLoaded(true)}
              fallback={
                <Box
                  width={"100%"}
                  overflow={"hidden"}
                  borderRadius={{ md: 20 }}
                  style={{ aspectRatio: 3.96 }}
                ></Box>
              }
              width={"100%"}
              style={{ aspectRatio: 3.96 }}
              overflow={"hidden"}
              borderRadius={{ md: 20 }}
            />
          </Skeleton>
        </Box>

        <Box p={4} borderRadius={20}>
          {props.controller.eventLoading ? (
            <Box
              display={"flex"}
              alignItems="center"
              height={"100%"}
              justifyContent={"center"}
            >
              <Spinner margin={"auto"} />
            </Box>
          ) : (
            <Stack
              direction={["column", "column", "row"]}
              alignItems={"center"}
              spacing={5}
            >
              <Stack w={["100%", "100%", "sm"]} spacing={4}>
                <HStack align={"center"} w={"full"}>
                  <Box color={"primary.main"} px={2}>
                    <FaUser size={25} />
                  </Box>
                  <Box>
                    <Text fontSize="md">{props.controller.organizer}</Text>
                    <Text color={"muted.200"}>Organizador</Text>
                  </Box>
                </HStack>
                <HStack
                  align={"center"}
                  mr={[0, 0, 30]}
                  mb={[5, 5, 0]}
                  w={"full"}
                >
                  <Box color={"primary.main"} px={2}>
                    <FaMapMarkerAlt size={25} />
                  </Box>
                  <Stack>
                    <Box alignItems={"center"} display={"flex"}>
                      <Text mr={5} fontSize="md">
                        {props.controller.location}
                      </Text>
                    </Box>
                    {/* <Text color={'muted.200'}>{props.controller.address}</Text> */}
                  </Stack>
                </HStack>
                <Stack w={"full"}>
                  <Button
                    size={"sm"}
                    maxW={"sm"}
                    borderRadius={"full"}
                    colorScheme={"primary"}
                    variant={"outline"}
                    onClick={props.controller.onLocationButtonPress}
                    isLoading={props.controller.locationLoading}
                  >
                    Como llegar
                  </Button>
                  <Button
                    size={"sm"}
                    maxW={"sm"}
                    borderRadius={"full"}
                    colorScheme={"primary"}
                    onClick={() => {
                      if (buyRef && buyRef.current) {
                        buyRef.current.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                    isLoading={props.controller.locationLoading}
                  >
                    Comprar
                  </Button>
                </Stack>
              </Stack>

              <Box
                borderColor={"muted.800"}
                borderLeftWidth={[0, 0, 1]}
                borderTopWidth={[1, 1, 0]}
                ml="auto"
                paddingLeft={[0, 0, 5]}
                paddingTop={[5, 5, 0]}
                mb={200}
                maxW={["100%", "100%", "65%"]}
              >
                <Text fontSize="lg">{props.controller.description}</Text>
              </Box>
            </Stack>
          )}
        </Box>
      </Box>
      <Box px={"7%"} mt={5} mb={5}>
        <Divider ref={buyRef} />
      </Box>
    </>
  );
};

export default EventInfo;
