/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { paymentMethodsLabels } from "../../../../config/constants";
import { VariantsTableDTO } from "../../../../services/Admin/stats/dto";
import { useStatsService } from "../../../../services/Admin/stats/stats.service";
import { getDiffPercentage } from "../../../../utils/diffPercentage";
import { priceFormatter } from "../../../../utils/priceFormat";
import { useAsync } from "../../../../utils/useAsync";
import { StatsController } from "./interfaces";
import { useColors } from "../../../../utils/useColors";
import { useUserEventsService } from "../../../../services/Admin/events/userEventsService";

const MapVariants = (variantsTable: VariantsTableDTO[]): VariantsTableDTO[] => {
  return variantsTable.map((variant) => {
    const convertToInt = (variable): number =>
      typeof variable === "string" ? parseFloat(variable) : variable;

    return {
      name: variant.name,
      sold: convertToInt(variant.sold),
      attendees: convertToInt(variant.attendees),
      availables: convertToInt(variant.availables),
      reserved: convertToInt(variant.reserved),
      stock: convertToInt(variant.stock),
      invitations: convertToInt(variant.invitations),
    };
  });
};

export const useStatsController = (): StatsController => {
  const { activeFunction } = useOutletContext<{
    activeFunction: { label: string; value: string };
  }>();
  const { id: eventId } = useParams();

  const statsService = useStatsService();
  const userEventService = useUserEventsService();

  const colors = useColors();

  const [eventStats, , eventStatsLoading, eventStatsExecute] = useAsync(
    statsService.getEventStats,
    {
      defaultValue: { diffStats: [], totalStats: undefined },
      defaultLoading: true,
    }
  );

  const [eventInfo, , , eventExecute] = useAsync(userEventService.getUserEvent);
  
  const [variantsChart, , variantsChartLoading, variantsChartExecute] =
    useAsync(statsService.getVariantsChart, {
      defaultValue: [],
      defaultLoading: true,
    });
  const [paymentsChart, , paymentsChartLoading, paymentsChartExecute] =
    useAsync(statsService.getPaymentsChart, {
      defaultValue: [],
      defaultLoading: true,
    });
  const [variantsTable, , variantsTableLoading, variantsTableExecute] =
    useAsync(statsService.getVariantsTable, {
      defaultValue: [],
      defaultLoading: true,
    });
  const [
    fixedTotalSalesValue,
    ,
    fixedTotalSalesLoading,
    fixedTotalSalesExecute,
  ] = useAsync(statsService.getFixedTotalSales, {
    defaultLoading: true,
  });

  useEffect(() => {
    if (eventId && activeFunction) {
      eventStatsExecute(eventId, activeFunction.value);
      variantsChartExecute(eventId, activeFunction.value);
      paymentsChartExecute(eventId, activeFunction.value);
      variantsTableExecute(eventId, activeFunction.value);
      fixedTotalSalesExecute(activeFunction.value);
      eventExecute(eventId);
    }
  }, [eventId, activeFunction]);

  const paymentMethodsData = useMemo(() => {
    const { labels, data }: { labels: string[]; data: number[] } =
      paymentsChart.reduce(
        (prev, method) => ({
          labels: prev.labels.concat([
            paymentMethodsLabels.find((type) => type.key === method?.name)
              ?.label ?? "Otro",
          ]),
          data: prev.data.concat([parseInt(method.quantity)]),
        }),
        {
          labels: [],
          data: [],
        }
      );

    return {
      loading: paymentsChartLoading,
      labels: labels,
      datasets: [
        {
          label: "Ventas",
          data: data,
          backgroundColor: [
            // colors.primary["100"],
            // colors.primary["200"],
            colors.primary["300"],
            colors.primary["700"],
            colors.primary["400"],
            colors.primary["800"],
            colors.primary["500"],
            colors.primary["900"],
            colors.primary["600"],
          ],
        },
      ],
    };
  }, [paymentsChart, paymentsChartLoading]);

  const ticketSellingTypeData = useMemo(() => {
    const { labels, data } = variantsChart
      .filter((variant) => parseInt(variant.quantity) > 0 && variant)
      .reduce(
        (prev, variant) => ({
          labels: prev.labels.concat([variant.name ?? ""]),
          data: prev.data.concat([parseInt(variant.quantity)]),
        }),
        {
          labels: [],
          data: [],
        }
      );
    return {
      loading: variantsChartLoading,
      labels: labels,
      datasets: [
        {
          label: "Ventas",
          data: data,
          backgroundColor: [
            // colors.primary["100"],
            // colors.primary["200"],
            colors.primary["300"],
            colors.primary["400"],
            colors.primary["500"],
            colors.primary["600"],
            colors.primary["700"],
            colors.primary["800"],
            colors.primary["900"],
          ],
        },
      ],
    };
  }, [variantsChart, variantsChartLoading]);

  const [totalSales, totalAttendants, soldTickets, dailySales] = useMemo(() => {
    const prev = eventStats.diffStats[0];
    const current = eventStats.diffStats[1];
    const total = eventStats.totalStats;

    const extras =
      current && total
        ? {
            //Diffs between yesterday totals and todays totals
            price: getDiffPercentage(
              parseInt(total.price) - parseInt(current.price),
              total.price
            ),
            availables: getDiffPercentage(
              parseInt(total.availables) - parseInt(current.availables),
              total.availables
            ),
            sold: getDiffPercentage(
              parseInt(total.sold) - parseInt(current.sold),
              total.sold
            ),
            reserved: getDiffPercentage(
              parseInt(total.reserved) - parseInt(current.reserved),
              total.reserved
            ),
            attendees: getDiffPercentage(
              parseInt(total.attendees) - parseInt(current.attendees),
              total.attendees
            ),
            invitations: getDiffPercentage(
              parseInt(total.invitations) - parseInt(current.invitations),
              total.invitations
            ),
          }
        : undefined;

    const todayExtras =
      prev && current
        ? {
            //Diffs between yesterday only and today only
            soldToday: getDiffPercentage(prev.sold, current.sold),
          }
        : undefined;

    return [
      {
        loading: eventStatsLoading,
        title: "Total Vendido",
        value: "$" + priceFormatter(parseInt(total?.price ?? "0")),
        extra: extras && extras.price,
      },
      {
        loading: eventStatsLoading,
        title: "Total cortesías",
        value: total?.invitations ?? "0",
        extra: extras && extras.invitations,
      },
      {
        loading: eventStatsLoading,
        title: "Tickets vendidos",
        value: total?.sold ?? "0",
        extra: extras && extras.sold,
      },
      {
        loading: eventStatsLoading,
        title: "Ventas hoy",
        value: current?.sold ?? "0",
        extra: todayExtras && todayExtras.soldToday,
      },
    ];
  }, [eventStatsLoading, eventStats]);

  const ticketSalesTable = useMemo(() => {
    return {
      loading: variantsTableLoading,
      data: MapVariants(variantsTable),
    };
  }, [variantsTable, variantsTableLoading]);

  const fixedTotalSales = useMemo(() => {
    return {
      loading: fixedTotalSalesLoading,
      title: "Total Vendido",
      value: "$" + priceFormatter(fixedTotalSalesValue?.amount ?? 0),
    };
  }, [fixedTotalSalesValue, fixedTotalSalesLoading]);

  return {
    eventId,
    eventInfo,
    ticketSellingTypeData,
    paymentMethodsData,
    totalAttendants,
    soldTickets,
    dailySales,
    totalSales,
    ticketSalesTable,
    fixedTotalSales,
  };
};
