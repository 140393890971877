import { useTheme } from "@emotion/react";
import { useMemo } from "react";
import { ChileTiketTheme, colors } from "../theme/colors";

export interface ColorPalette {
  backgroundColor: string;
  backgroundContrast: string;
  primary: {
    main: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    contrast: string;
  };

  secondary: {
    main: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    contrast: string;
  };

  muted: {
    main: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
}

export const useColors = () => {
  const theme: any = useTheme();

  const colorPalette = useMemo(() => {
    if (theme) {
      return theme.colors as ColorPalette;
    } else {
      return colors;
    }
  }, [theme]);

  return colorPalette;
};
