import { createIcon } from "@chakra-ui/react";

export const WarningIcon = createIcon({
  displayName: "WarningIcon",
  viewBox: "0 0 18 18",
  path: (
    <path
      fill="currentColor"
      d="M9.00033 13.1665C9.23644 13.1665 9.43435 13.0866 9.59408 12.9269C9.7538 12.7672 9.83366 12.5693 9.83366 12.3332C9.83366 12.0971 9.7538 11.8991 9.59408 11.7394C9.43435 11.5797 9.23644 11.4998 9.00033 11.4998C8.76421 11.4998 8.5663 11.5797 8.40658 11.7394C8.24685 11.8991 8.16699 12.0971 8.16699 12.3332C8.16699 12.5693 8.24685 12.7672 8.40658 12.9269C8.5663 13.0866 8.76421 13.1665 9.00033 13.1665ZM8.16699 9.83317H9.83366V4.83317H8.16699V9.83317ZM9.00033 17.3332C7.84755 17.3332 6.76421 17.1144 5.75033 16.6769C4.73644 16.2394 3.85449 15.6457 3.10449 14.8957C2.35449 14.1457 1.76074 13.2637 1.32324 12.2498C0.885742 11.2359 0.666992 10.1526 0.666992 8.99984C0.666992 7.84706 0.885742 6.76373 1.32324 5.74984C1.76074 4.73595 2.35449 3.854 3.10449 3.104C3.85449 2.354 4.73644 1.76025 5.75033 1.32275C6.76421 0.885254 7.84755 0.666504 9.00033 0.666504C10.1531 0.666504 11.2364 0.885254 12.2503 1.32275C13.2642 1.76025 14.1462 2.354 14.8962 3.104C15.6462 3.854 16.2399 4.73595 16.6774 5.74984C17.1149 6.76373 17.3337 7.84706 17.3337 8.99984C17.3337 10.1526 17.1149 11.2359 16.6774 12.2498C16.2399 13.2637 15.6462 14.1457 14.8962 14.8957C14.1462 15.6457 13.2642 16.2394 12.2503 16.6769C11.2364 17.1144 10.1531 17.3332 9.00033 17.3332ZM9.00033 15.6665C10.8614 15.6665 12.4378 15.0207 13.7295 13.729C15.0212 12.4373 15.667 10.8609 15.667 8.99984C15.667 7.13873 15.0212 5.56234 13.7295 4.27067C12.4378 2.979 10.8614 2.33317 9.00033 2.33317C7.13921 2.33317 5.56283 2.979 4.27116 4.27067C2.97949 5.56234 2.33366 7.13873 2.33366 8.99984C2.33366 10.8609 2.97949 12.4373 4.27116 13.729C5.56283 15.0207 7.13921 15.6665 9.00033 15.6665Z"
    />
  ),
});
