import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, HStack, Spinner, Stack, Text } from "@chakra-ui/react";
import React from "react";

import { TermsProps } from "./interfaces";
import { useTermsController } from "./terms.controller";

export const Terms: React.FC<TermsProps> = ({
  useController = useTermsController,
}) => {
  const controller = useController();

  return controller.loading ? (
    <Box display={"flex"} justifyContent={"center"}>
      <Spinner my={20} />
    </Box>
  ) : (
    <Stack py={10} px={[5, 5, 20]}>
      <HStack spacing={3}>
        <Text fontWeight={600}>Términos y condiciones:</Text>
      </HStack>
      {controller.error ? (
        <Text>
          Ocurrió un error cargando los términos y condiciones. Inténtalo mas
          tarde
        </Text>
      ) : (
        <Text dangerouslySetInnerHTML={{ __html: controller.terms }} />
      )}
    </Stack>
  );
};
