import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useUserEventsService } from "../../../../services/Admin/events/userEventsService";
import { useAsync } from "../../../../utils/useAsync";
import { AdminViewController } from "./interfaces";
import { useToaster } from "../../../../utils/useToaster";

export const useAdminViewController = (): AdminViewController => {
  const { id: eventId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userEventsService = useUserEventsService();

  const { successToast, errorToast } = useToaster();

  const [, , , executeUpdate] = useAsync(userEventsService.updateAdmins, null);

  const [userListData, , userListLoading, userListExecute] = useAsync(
    userEventsService.getAdmins,
    {
      defaultValue: {
        totalDocs: 0,
        limit: 0,
        totalPages: 0,
        page: 0,
        pagingCounter: 0,
        hasPrevPage: false,
        hasNextPage: false,
        prevPage: 0,
        nextPage: 0,
        docs: [],
      },
      defaultLoading: true,
    }
  );

  const getUserList = useCallback(
    (paginationOptions: { pageSize: number; pageIndex: number }) => {
      if (eventId) {
        userListExecute(eventId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventId]
  );

  const updateAdmins = async (users: {}[], action: string) => {
    try {
      await executeUpdate(users, action, eventId);
      successToast(
        "Ususarios eliminados con exito",
        "Se quitaron los usuarios de este evento"
      );
      await getUserList(null);
    } catch (error) {
      errorToast(
        "Ocurrió un error al eliminar",
        "No se pudieron eliminar los usuarios, vuelva a intentarlo mas tarde"
      );
    }
  };

  const adminListData = useMemo(
    () => ({
      totalPages: userListData.totalPages,
      totalDocs: userListData.totalDocs,
      data: userListData.docs.map((user) => ({
        ...user,
      })),
    }),
    [userListData]
  );

  const addAdmin = async (email: string, role: string) => {
    try {
      setIsSubmitting(true);
      await userEventsService.addAdmin(email, role, eventId);
      await getUserList(null);
      successToast(
        "Administrador agregado!",
        "Se agrego el usuario exitosamente"
      );
    } catch (error) {
      console.log(error);
      errorToast(
        "Error al agregar usuario",
        "Ocurrió un error al agregar usuario. Inténtelo nuevamente."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    adminListData: adminListData,
    userListLoading,
    getUserList,
    updateAdmins,
    addAdmin,
    isSubmitting,
  };
};
