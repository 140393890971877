import { useContext } from "react";
import { AxiosContext } from "../../providers/axiosContext";
import { AsyncStore } from "../../utils/createAsyncStore";
import {
  PaymentDTO,
  ShoppingCartDTO,
  ShoppingSessionDTO,
  ShoppingSessionInfoDTO,
} from "./dto";
import { useShoppingSessionAsyncStore } from "./shoppingSessionStore";
import { getProducerURL } from "../../utils/getProducer";

export interface ShoppingSessionService {
  getShoppingSessionPayment: (
    sessionId: string,
    type: string
  ) => Promise<PaymentDTO>;
  createShoppingSession: (cart: ShoppingCartDTO) => Promise<ShoppingSessionDTO>;
  getShoppingSession: (id: string) => Promise<ShoppingSessionInfoDTO>;
  storage: AsyncStore<ShoppingSessionDTO>;
  validateToken: (token: string) => Promise<ResponseData>;
  getDiscount: (
    code: string,
    shoppingSessionId: string
  ) => Promise<ShoppingSessionDTO>;
}

interface ResponseData {
  success: boolean;
  challenge_ts: string;
  hostname: string;
  score: number;
  action: string;
}

export const useShoppingSessionService = (): ShoppingSessionService => {
  const axiosContext = useContext(AxiosContext);

  const storage = useShoppingSessionAsyncStore();

  const getShoppingSessionPayment = async (
    sessionId: string,
    type: string
  ): Promise<PaymentDTO> => {
    const res = await axiosContext.authAxios.get<PaymentDTO>(
      `/tickets/shopping-sessions/payment/${sessionId}/${type}`,
      {
        params: {
          backurl: window.location.href,
        },
      }
    );
    return res.data;
  };

  const createShoppingSession = async (cart: ShoppingCartDTO) => {
    const res = await axiosContext.authAxios.post<ShoppingSessionDTO>(
      "/tickets/shopping-sessions",
      cart
    );
    return res.data;
  };

  const getShoppingSession = async (id: string) => {
    const res = await axiosContext.authAxios.get<ShoppingSessionInfoDTO>(
      `/tickets/shopping-sessions/${id}`
    );
    return res.data;
  };

  const validateToken = async (token: string) => {
    const res = await axiosContext.authAxios.get<ResponseData>(
      `/validation-utils/google/captcha`,
      { params: { response: token } }
    );
    return res.data;
  };

  const getDiscount = async (code: string, shoppingSessionId: string) => {
    const res = await axiosContext.authAxios.post<ShoppingSessionDTO>(
      `/tickets/discount-codes/payment/`,
      { code, shoppingSessionId }
    );
    return res.data;
  };

  return {
    getShoppingSessionPayment,
    createShoppingSession,
    getShoppingSession,
    storage,
    validateToken,
    getDiscount,
  };
};
