import React, { useMemo } from "react";
import {
  ArrowLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowRightIcon,
} from "@chakra-ui/icons";
import { Flex, IconButton, Tooltip, Text, Box, Stack } from "@chakra-ui/react";
import { TableInstance } from "react-table";

interface BasicTablePaginator {
  tableInstance: TableInstance;
  totalDocs: number;
}

interface PaginatorDataProps {
  initialPageElementIndex: number;
  lastPageElementIndex: number;
  totalDocs: number;
}

const BasicPaginator: React.FC<BasicTablePaginator> = ({
  tableInstance,
  totalDocs,
}) => {
  const {
    gotoPage,
    canPreviousPage,
    previousPage,
    pageCount,
    nextPage,
    rows,
    canNextPage,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const paginatorData = useMemo<PaginatorDataProps>(
    () => ({
      initialPageElementIndex: pageIndex * pageSize + 1,
      lastPageElementIndex: pageIndex * pageSize + rows.length,
      totalDocs,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageIndex, pageSize, totalDocs]
  );

  return (
    <Stack
      direction={"row"}
      justifyContent="space-evenly"
      m={4}
      alignItems="center"
    >
      <Box>
        <Tooltip label="Primera Página">
          <IconButton
            size={{ sm: "sm", md: "md" }}
            aria-label="button-first"
            onClick={() => gotoPage(0)}
            isDisabled={!canPreviousPage}
            icon={<ArrowLeftIcon h={3} w={3} />}
            mr={4}
          />
        </Tooltip>
        <Tooltip label="Página anterior">
          <IconButton
            size={{ sm: "sm", md: "md" }}
            aria-label="button-pag"
            onClick={previousPage}
            isDisabled={!canPreviousPage}
            icon={<ChevronLeftIcon h={6} w={6} />}
          />
        </Tooltip>
      </Box>

      <Flex alignItems="center">
        <Text mx={5} textAlign="center" w="full">
          {paginatorData.initialPageElementIndex} -{" "}
          {paginatorData.lastPageElementIndex} de{" "}
          <Text fontWeight="bold" as="span">
            {paginatorData.totalDocs}
          </Text>
        </Text>
      </Flex>

      <Flex ml={8}>
        <Tooltip label="Página siguiente">
          <IconButton
            size={{ sm: "sm", md: "md" }}
            aria-label="button-next"
            onClick={nextPage}
            isDisabled={!canNextPage}
            icon={<ChevronRightIcon h={6} w={6} />}
          />
        </Tooltip>
        <Tooltip label="Última Página">
          <IconButton
            size={{ sm: "sm", md: "md" }}
            aria-label="button-last"
            onClick={() => gotoPage(pageCount - 1)}
            isDisabled={!canNextPage}
            icon={<ArrowRightIcon h={3} w={3} />}
            ml={4}
          />
        </Tooltip>
      </Flex>
    </Stack>
  );
};

export default BasicPaginator;
