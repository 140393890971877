import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  HStack,
  Icon,
  VStack,
  DrawerFooter,
  Button,
  Text,
  Image,
  Grid,
  GridItem,
  Stack,
  Input,
  FormControl,
  FormLabel,
  Divider,
  Checkbox,
  FormErrorMessage,
  Link,
} from "@chakra-ui/react";
import { useState, useMemo } from "react";

import { TicketDetailProps, TicketTransferProps } from "../interfaces";
import Card from "../../../../components/Card";
import { Field, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import {
  cleanRut,
  formatRut,
  validateRut,
} from "../../../../utils/rutFormatter";
import { useEmailValidator } from "../../../../utils/useEmailValidator";
import { EmailInput } from "../../../../components/EmailInput";
import { useProfileService } from "../../../../services/profile/profileService";
import { TransferDataDTO } from "../../../../services/profile/dto";
import { Link as RouterLink } from "react-router-dom";
import { useToaster } from "../../../../utils/useToaster";
import { RiErrorWarningLine } from "react-icons/ri";

interface TransferDTO {
  mail: string;
  firstname: string;
  lastname: string;
  rut: string;
}

export const TicketTransfer: React.FC<TicketTransferProps> = ({
  drawerManager,
  selectedTicket,
  transferCallback,
}) => {
  const [confirmTerms, setConfirmTerms] = useState(false);
  const { validateEmail, validationState, setValidationState } =
    useEmailValidator();
  const profileService = useProfileService();

  const { successToast, errorToast } = useToaster();

  const TransferFormSchema = useMemo(
    () =>
      Yup.object().shape({
        firstname: Yup.string()
          .required("Debe ingresar un Nombre")
          .matches(
            /^[A-Za-zÀ-ÖØ-öø-ÿ,.' -]+$/i,
            "Tu nombre posee caracteres inválidos"
          )
          .max(50, "El nombre es muy largo"),
        lastname: Yup.string()
          .required("Debe ingresar un Apellido")
          .matches(
            /^[A-Za-zÀ-ÖØ-öø-ÿ,.' -]+$/i,
            "Tu apellido posee caracteres inválidos"
          )
          .max(50, "El apellido es muy largo"),
        rut: Yup.string()
          .required("Debe ingresar su RUT")
          .max(12, "El RUT es muy largo")
          .test("format", "El RUT ingresado es inválido", function (rut) {
            return validateRut(rut);
          }),
        mail: Yup.string()
          .required("Debe ingresar un correo")
          .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            "Este mail contiene caracteres inválidos"
          )
          .max(150, "El mail es muy largo")
          .test("validation", "El email ingresado no es válido", validateEmail),
      }),
    []
  );

  const handleSubmit = async (values: TransferDTO) => {
    try {
      const transferData: TransferDataDTO = {
        ...values,
        fullname: `${values.firstname} ${values.lastname}`,
        id: selectedTicket._id,
      };
      await profileService.transferTicket(transferData);
      transferCallback();
      onClose();
      setValidationState("pending");
      successToast(
        "Ticket transferido",
        `Se transfirió exitosamente su ticket a ${transferData.fullname}`
      );
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        errorToast(
          "Error al transferir ticket",
          `${error?.response?.data?.message}`,
          {
            position: "top-left",
          }
        );
      } else {
        errorToast(
          "Error al transferir ticket",
          `Ocurrió un error en la transferencia. Inténtelo nuevamente.`,
          {
            position: "top-left",
          }
        );
      }
    }
  };

  const form = useFormik<TransferDTO>({
    initialValues: {
      firstname: "",
      lastname: "",
      mail: "",
      rut: "",
    },
    onSubmit: handleSubmit,
    validationSchema: TransferFormSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const onClose = () => {
    drawerManager.onClose();
    form.resetForm();
    setConfirmTerms(false);
  };

  return (
    <Drawer
      isOpen={drawerManager.isOpen}
      placement="right"
      onClose={onClose}
      size={"md"}
    >
      <DrawerOverlay />
      <DrawerContent bgColor={"secondary.main"}>
        <DrawerCloseButton />
        <DrawerHeader>Transferencia de ticket</DrawerHeader>
        {selectedTicket && (
          <DrawerBody
            display={"flex"}
            flexDir={"column"}
            color={"secondary.contrast"}
            sx={{
              "&::-webkit-scrollbar": {
                height: "5px",
                borderRadius: "10px",
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
                w: "0.2rem",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "black",
                borderRadius: "10px",
                w: "0.2rem",
              },
            }}
          >
            <HStack align={"top"} fontSize={"small"}>
              <Box color={"green.400"} p={0.5}>
                <Icon
                  as={RiErrorWarningLine}
                  color={"primary.main"}
                  fontSize={"md"}
                />
              </Box>
              <VStack align={"start"} spacing={0.5}>
                <Text fontWeight={600} color={"primary.main"}>
                  IMPORTANTE
                </Text>
                <Text>
                  Estás por transferir un ticket. Esto implica que luego de la
                  transferencia este ticket ya no se encontrará vinculado a tu
                  usuario y NO podrás utilizarlo.
                </Text>
              </VStack>
            </HStack>
            <VStack my={3}>
              <Text
                textAlign={"left"}
                w="full"
                fontSize={15}
                fontWeight={"bold"}
              >
                Datos de tu ticket a transferir:
              </Text>
              <Card p={0} overflow={"hidden"} textAlign={"left"}>
                <Grid
                  templateColumns={"repeat(3, 1fr)"}
                  gap={2}
                  alignItems={"center"}
                >
                  <GridItem>
                    <Image
                      src={selectedTicket.imageURL}
                      h={"4rem"}
                      w={"full"}
                      objectFit={"cover"}
                    />
                  </GridItem>
                  <GridItem p={2}>
                    <VStack spacing={0} h="full">
                      <Text
                        w="full"
                        noOfLines={1}
                        color={"primary.main"}
                        fontWeight={600}
                      >
                        {selectedTicket.variant}
                      </Text>
                      <Text w="full" noOfLines={1}>
                        {selectedTicket.location}
                      </Text>
                    </VStack>
                  </GridItem>
                  <GridItem p={2}>
                    <VStack spacing={0} h="full">
                      <Text w="full" noOfLines={1}>
                        {selectedTicket.date.split(",")[0]}
                      </Text>
                      <Text w="full" noOfLines={1}>
                        {selectedTicket.date.split(",")[1]}
                      </Text>
                    </VStack>
                  </GridItem>
                </Grid>
              </Card>
              <Text
                pt={"3%"}
                textAlign={"left"}
                w="full"
                fontSize={15}
                fontWeight={"bold"}
              >
                Datos del usuario que recibirá la transferencia:
              </Text>
              <FormikProvider value={form}>
                <VStack spacing={5} w="full" py={3}>
                  <Stack
                    direction={["column", "row"]}
                    m={0}
                    p={0}
                    w="full"
                    spacing={5}
                  >
                    <Field name="firstname">
                      {({ field }) => (
                        <FormControl
                          variant="floating"
                          colorScheme={"secondary"}
                          id="firstname"
                          isRequired
                          isInvalid={!!form.errors.firstname}
                        >
                          <Input {...field} placeholder=" " max={50} />
                          <FormLabel>Nombre</FormLabel>
                          <FormErrorMessage fontSize={"small"}>
                            {form.errors.firstname}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="lastname">
                      {({ field }) => (
                        <FormControl
                          variant="floating"
                          id="lastname"
                          colorScheme={"secondary"}
                          isRequired
                          isInvalid={!!form.errors.lastname}
                        >
                          <Input {...field} placeholder=" " max={50} />
                          <FormLabel>Apellido</FormLabel>
                          <FormErrorMessage fontSize={"small"}>
                            {form.errors.lastname}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <Field name="rut">
                    {({ field }) => (
                      <FormControl
                        variant="floating"
                        colorScheme={"secondary"}
                        id="rut"
                        isRequired
                        isInvalid={!!form.errors.rut}
                      >
                        <Input
                          {...field}
                          placeholder=" "
                          onChange={(e) => {
                            form.setFieldValue(
                              field.name,
                              formatRut(cleanRut(e.target.value))
                            );
                          }}
                          max={50}
                        />
                        <FormLabel>RUT</FormLabel>
                        <FormErrorMessage fontSize={"small"}>
                          {form.errors.rut}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="mail">
                    {({ field }) => (
                      <FormControl
                        variant="floating"
                        colorScheme={"secondary"}
                        isRequired
                        isInvalid={!!form.errors.mail}
                      >
                        <EmailInput
                          field={field}
                          form={form}
                          label={"Email"}
                          validationState={validationState}
                          inputProps={{
                            maxLength: 150,
                          }}
                          inputGroupProps={{
                            borderRadius: 4,
                          }}
                        />
                        <FormErrorMessage fontSize={"small"}>
                          {form.errors.mail}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </VStack>
              </FormikProvider>
              <Divider />
              <Box w="full" pt={2}>
                <Checkbox
                  colorScheme={"primary"}
                  textAlign={"left"}
                  borderColor={"muted.200"}
                  onChange={() => setConfirmTerms(!confirmTerms)}
                >
                  <Text
                    noOfLines={[2, 1]}
                    title="He leido los términos y condiciones y quiero transferir mi
                    ticket."
                    fontSize={"small"}
                  >
                    He leido los{" "}
                    <Link
                      isExternal
                      textDecoration={"underline"}
                      as={RouterLink}
                      to="/terms"
                      color={"primary.main"}
                    >
                      términos y condiciones
                    </Link>{" "}
                    y quiero transferir mi ticket.
                  </Text>
                </Checkbox>
              </Box>
            </VStack>
          </DrawerBody>
        )}
        <DrawerFooter h="12%" alignItems={"center"} justifyContent={"center"}>
          <Button
            colorScheme="primary"
            rounded={"full"}
            isDisabled={!confirmTerms}
            onClick={() =>
              form.validateForm().then((res) => {
                Object.keys(res).length === 0 && form.submitForm();
              })
            }
            isLoading={form.isSubmitting || form.isValidating}
          >
            TRANSFERIR
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
