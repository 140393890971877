import React, {
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
// Chakra imports
import {
  TableContainer,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Stack,
  chakra,
  Spinner,
  InputProps,
  Checkbox,
  Center,
  Flex,
  Text,
  Image,
} from "@chakra-ui/react";

import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
} from "react-table";
import { TableActions } from "./TableActions";
import { HTMLChakraProps } from "@chakra-ui/system";
import AdvancedPaginator from "./Paginators/AdvancedPaginator";
import Card, { CardProps } from "../Card";
import BasicPaginator from "./Paginators/BasicPaginator";
import { debounce } from "../../utils/debounce";
import { colors } from "../../theme/colors";
interface CustomTableTypes extends HTMLChakraProps<"div"> {
  title?: string;
  columns: any;
  data: any[];
  loading?: boolean;
  initialHiddenColumns?: string[];
  initialPageSize?: number;
  searchProps?: {
    enabled?: boolean;
    inputProps?: InputProps;
  };
  customHeader?: any;
  disablePaginator?: boolean;
  paginatorOptions?: {
    enabled: boolean;
    type: "basic" | "advanced";
  };
  actions?: {
    addMethod: () => void;
    addLabel: string;
  } | null;
  fetchData?: ({
    pageIndex,
    pageSize,
    globalFilter,
  }: {
    pageIndex: number;
    pageSize: number;
    globalFilter?: string;
  }) => void;
  pageCount?: number;
  totalDocs?: number;
  showRowSelection?: boolean;
  ref?: any;
  stylesTableContainer?: CardProps;
  customEmptyState?: ReactNode;
  editFn?: (original: any) => void;
}

const CustomTable: React.FC<CustomTableTypes> = forwardRef(
  (
    {
      columns,
      data,
      actions = null,
      paginatorOptions = {
        enabled: true,
        type: "basic",
      },
      searchProps = {
        enabled: false,
        inputProps: null,
      },
      initialHiddenColumns = [],
      initialPageSize = 5,
      loading,
      title,
      fetchData,
      customHeader = null,
      pageCount: controlledPageCount,
      showRowSelection = false,
      stylesTableContainer = null,
      totalDocs = null,
      customEmptyState,
      disablePaginator,
      editFn,
    },
    ref
  ) => {
    const tableInstance = useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: 0,
          pageSize: initialPageSize,
          hiddenColumns: initialHiddenColumns,
          globalFilter: "",
        },
        autoResetPage: false,

        ...(fetchData
          ? {
              manualPagination: true,
              pageCount: controlledPageCount,
              manualGlobalFilter: true,
            }
          : {}),
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect,
      (hooks) => {
        showRowSelection &&
          hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <Center>
                  <Checkbox
                    {...getToggleAllPageRowsSelectedProps()}
                    colorScheme={"primary"}
                    borderColor={"muted.400"}
                    isChecked={getToggleAllPageRowsSelectedProps().checked}
                  />
                </Center>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <Checkbox
                  isChecked={row.getToggleRowSelectedProps().checked}
                  onChange={row.getToggleRowSelectedProps().onChange}
                  colorScheme={"primary"}
                  borderColor={"muted.400"}
                  {...row.getToggleRowSelectedProps()}
                />
              ),
            },
            ...columns,
          ]);
      }
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,

      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page
      gotoPage,

      // The rest of these things are super handy, too ;)
      setGlobalFilter,

      state: { globalFilter, pageIndex, pageSize },
    } = tableInstance;

    useEffect(() => {
      if (fetchData) {
        fetchData({ pageIndex: pageIndex + 1, pageSize, globalFilter });
      }
    }, [fetchData, pageIndex, pageSize]);

    useEffect(() => {
      if (fetchData) {
        gotoPage(0);
        fetchData({ pageIndex: 1, pageSize, globalFilter });
      }
    }, [globalFilter]);

    useEffect(() => {
      if (!paginatorOptions.enabled && data?.length) {
        tableInstance.setPageSize(data.length);
      }
    }, [data]);

    useImperativeHandle(ref, () => ({
      tableInstance,
    }));

    return (
      <Stack spacing={5}>
        {(searchProps.enabled || customHeader || title) && (
          <TableActions
            leftElement={customHeader ?? title ?? null}
            filter={globalFilter}
            setFilter={(value) => {
              debounce(() => setGlobalFilter(value), 1000);
            }}
            adding={actions}
            inputProps={searchProps.inputProps}
            showSearch={searchProps.enabled}
          />
        )}
        <Card p={3} as={"div"} {...stylesTableContainer}>
          {loading ? (
            <Spinner alignSelf={"center"} my={5} />
          ) : (
            <>
              <TableContainer overflowY="auto">
                <Table {...getTableProps()}>
                  <Thead
                    textAlign="center"
                    position="sticky"
                    top={0}
                    // bgColor={'secondary.main'}
                    // zIndex={20}
                    sx={{
                      "& th:first-child": {
                        borderRadius: "1em 0 0 1em",
                      },
                      "& th:last-child": {
                        borderRadius: "0 1em 1em 0",
                      },
                    }}
                  >
                    {headerGroups.map((headerGroup) => (
                      <Tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <Th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            maxW={column?.maxWidth}
                            borderColor="muted.500"
                            color={"secondary.contrast"}
                            //@ts-ignore
                            style={column?.style}
                            //@ts-ignore
                            textAlign={column?.isCentered ? "center" : "start"}
                          >
                            {column.render("Header")}
                            {column.isSorted ? (
                              <chakra.span pl="3">
                                {column.isSortedDesc ? (
                                  <TriangleDownIcon aria-label="sorted descending" />
                                ) : (
                                  <TriangleUpIcon aria-label="sorted ascending" />
                                )}
                              </chakra.span>
                            ) : null}
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </Thead>
                  {page !== undefined && page.length > 0 && (
                    <Tbody {...getTableBodyProps()}>
                      {page.map((row, i) => {
                        prepareRow(row);
                        return (
                          <Tr
                            cursor={editFn !== undefined ? "pointer" : "auto"}
                            _hover={{ bgColor: colors.secondary[500] }}
                            {...row.getRowProps()}
                            onClick={() => {
                              if (editFn !== undefined) {
                                editFn(row.original);
                              } else return;
                            }}
                          >
                            {row.cells.map((cell) => {
                              return (
                                <Td
                                  {...cell.getCellProps()}
                                  textAlign={"center"}
                                  borderColor="muted.500"
                                >
                                  {cell.render("Cell")}
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  )}
                </Table>
                {page.length === 0 &&
                  customEmptyState !== undefined &&
                  customEmptyState}
              </TableContainer>
              {!disablePaginator &&
                paginatorOptions.enabled &&
                page.length > 0 &&
                (paginatorOptions.type === "advanced" ? (
                  <AdvancedPaginator
                    tableInstance={tableInstance}
                    totalDocs={totalDocs}
                  />
                ) : (
                  <BasicPaginator
                    tableInstance={tableInstance}
                    totalDocs={totalDocs}
                  />
                ))}
            </>
          )}
        </Card>
      </Stack>
    );
  }
);

export default CustomTable;
