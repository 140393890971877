import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import React from "react";

import ProfileDataForm from "./ProfileDataForm";

interface ModalContainerProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmitDone?: () => void;
}

const ProfileDataFormModal: React.FC<ModalContainerProps> = (props) => {
  return (
    <Modal isCentered {...props} preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent
        minW={{ md: "2xl" }}
        borderRadius={20}
        backgroundColor={"backgroundColor"}
        borderWidth={2}
        borderColor={"secondary.main"}
        p={4}
      >
        <ModalBody
          justifyContent={"center"}
          maxH="70vh"
          overflowY={"auto"}
          sx={{
            "&::-webkit-scrollbar": {
              width: "8px",
              borderRadius: "10px",
              backgroundColor: `rgba(0, 0, 0, 0.05)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `secondary.main`,
              borderRadius: "10px",
            },
          }}
        >
          <ProfileDataForm
            showDescription
            onSubmitDone={() => {
              props.onSubmitDone && props.onSubmitDone();
              props.onClose();
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProfileDataFormModal;
