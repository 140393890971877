import {
  Box,
  Button,
  Divider,
  HStack,
  List,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { EventFunctionDTO } from "../../../../services/events/dto";
import { format } from "date-fns";
import es from "date-fns/locale/es";

type FunctionSelectorProps = {
  functions: EventFunctionDTO[];
  name: string;
  location: string;
  onSelectFunction: (fun: EventFunctionDTO) => void;
};

const FunctionSelector = (props: FunctionSelectorProps) => {
  return (
    <Box px={"7%"}>
      <Stack spacing={0}>
        <Text fontWeight={500} fontSize={20} color={"primary.main"}>
          {props.name}
        </Text>
        <Text>Selecciona la función a la que deseas asistir:</Text>
      </Stack>
      <Divider py={2} />
      <List>
        {props.functions.map((fun) => (
          <ListItem py={4} borderBottomColor={"grey.100"} borderBottomWidth={1}>
            <HStack
              spacing={4}
              justifyContent={"space-between"}
              alignItems={"center"}
              flexDir={"row"}
            >
              <HStack spacing={6}>
                <Stack spacing={0} alignItems={"center"}>
                  <Text
                    fontSize={16}
                    fontWeight={600}
                    textTransform={"capitalize"}
                  >
                    {format(new Date(fun.datetime), "MMM", { locale: es })}
                  </Text>
                  <Text
                    fontWeight={600}
                    fontSize={24}
                    textTransform={"capitalize"}
                  >
                    {format(new Date(fun.datetime), "dd", { locale: es })}
                  </Text>
                  <Text
                    fontSize={14}
                    fontWeight={200}
                    textTransform={"capitalize"}
                  >
                    {format(new Date(fun.datetime), "E", { locale: es })}
                  </Text>
                </Stack>
                <Stack spacing={0}>
                  <Text fontSize={16} fontWeight={600}>
                    {props.name}
                  </Text>
                  <Text>{props.location}</Text>
                </Stack>
              </HStack>
              <Button
                borderRadius={"full"}
                variant={"outline"}
                colorScheme="primary"
                onClick={() => props.onSelectFunction(fun)}
              >
                Desde ${fun.lowestPrice}
              </Button>
            </HStack>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default FunctionSelector;
