import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EventDTO } from "../../../services/events/dto";
import { useEventsService } from "../../../services/events/eventsService";
import { EventCarouselItem, EventListItem, HomeController } from "./interfaces";
import {
  formateDateRange,
  formatSingleDate,
} from "../../../utils/dateFormatters";
import { useBreakpointValue } from "@chakra-ui/react";
import { emptyEvent } from "../../../config/constants";
import { priceFormatter } from "../../../utils/priceFormat";
import { emptyEventImage } from "../../../resources";

const fillerEvent = {
  id: "",
  index: 0,
  imageURL: emptyEventImage,
  onSelect: () => {},
  blurred: false,
  title: "Festival de Música",
  location: "Estadio Nacional",
  date: "20 NOV",
  price: "85000",
  blackTags: ["+18"],
  grayTags: ["Música"],
};

const mapEventToList = (event: EventDTO, index: number): EventListItem => {
  const dates = event.functions.map((value) => new Date(value.datetime));

  let formattedDate = "";
  if (dates.length === 1) {
    formattedDate = formatSingleDate(dates[0]);
  } else if (dates.length > 1) {
    formattedDate = formateDateRange(dates[0], dates[dates.length - 1]);
  }

  return {
    id: event._id,
    index,
    imageURL: event.images?.card.url,
    title: event.name,
    location: event.placeName,
    price: priceFormatter(event.lowestPrice),
    blackTags: event.meta.restriction ? [event.meta.restriction] : [],
    grayTags: [event.meta.category],
    date: formattedDate,
    onSelect: () => {},
  };
};

export const useHomeController = (): HomeController => {
  const navigate = useNavigate();
  const [activeEvent, setActiveEvent] = useState<number>(undefined);

  const isMobile = useBreakpointValue<boolean>({
    base: true,
    md: false,
  });

  const mapEventToCarousel = useCallback(
    (event: EventDTO, index: number): EventCarouselItem => {
      if (event.images?.banner && event.images?.bannerMobile) {
        return {
          imageURL: isMobile
            ? event.images.banner.url
            : event.images.bannerMobile.url,
          id: event._id,
          index,
          onSelect: () => {},
        };
      } else {
        return undefined;
      }
    },
    [isMobile]
  );

  const [listEvents, setListEvents] = useState<EventListItem[]>([
    emptyEvent,
    emptyEvent,
    emptyEvent,
  ]);

  const [carouselEvents, setCarouselEvents] = useState<EventCarouselItem[]>([
    emptyEvent,
  ]);

  const eventsService = useEventsService();

  const mapEvents = useCallback(
    (
      events: EventDTO[]
    ): {
      mappedCarouselEvents: EventCarouselItem[];
      mappedListEvents: EventListItem[];
    } => {
      let mappedCarouselEvents = [];
      let mappedListEvents = [];

      events.forEach((event, index) => {
        const onSelect = () => {
          if (event._id) {
            navigate(`/events/${event._id}`);
          }
        };
        if (event.displayType === "carousel") {
          const mappedEvent = mapEventToCarousel(event, index);
          if (mappedEvent) {
            mappedCarouselEvents.push({ ...mappedEvent, onSelect });
          }
        }
        const mappedEvent = mapEventToList(event, index);
        mappedListEvents.push({ ...mappedEvent, onSelect });
      });
      return { mappedCarouselEvents, mappedListEvents };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );

  const {
    data: events,
    execute,
    loading: eventsLoading,
  } = eventsService.eventStore;

  useEffect(
    () => {
      if (!events) {
        execute(eventsService.getEventList);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (events) {
        const { mappedCarouselEvents, mappedListEvents } = mapEvents(events);

        setCarouselEvents(mappedCarouselEvents);
        setListEvents(mappedListEvents);

        // const mod3 = mappedListEvents.length % 3;
        // if (mod3 === 0) {
        //   setListEvents(mappedListEvents);
        // } else {
        //   const fillEvents = new Array(3 - mod3).fill({
        //     ...fillerEvent,
        //     blurred: true,
        //   });
        //   setListEvents([...mappedListEvents, ...fillEvents]);
        // }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [events]
  );

  return {
    carouselEvents,
    listEvents,
    setActiveEvent,
    activeEvent,
    eventsLoading,
  };
};
