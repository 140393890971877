import {
  ModalContent,
  ModalBody,
  Stack,
  Center,
  Button,
  Input,
  ModalFooter,
  Box,
  Text,
  Link,
  Heading,
  InputGroup,
  InputRightElement,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import React, { useContext, useMemo, useState } from "react";
import { FaEye, FaEyeSlash, FaGoogle } from "react-icons/fa";
import { AuthContext } from "../../../providers/authContext";
import { useAuthService } from "../../../services/auth/authService";

import { FormProps } from "../interfaces";
import { useGoogleLogin } from "@react-oauth/google";
import { Field, FormikProvider, getIn, useFormik } from "formik";
import * as Yup from "yup";
import { isWebview } from "../../../utils/detectWebView";
import { useEmailValidator } from "../../../utils/useEmailValidator";
import { EmailInput } from "../../EmailInput";
import { useToaster } from "../../../utils/useToaster";

const SignUpForm: React.FC<FormProps> = ({ setActiveForm, onClose }) => {
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);
  const [show, setShow] = useState(false);

  const authService = useAuthService();
  const authContext = useContext(AuthContext);

  const { successToast, errorToast } = useToaster();

  const { validateEmail, validationState } = useEmailValidator();

  const SignUpSchema = useMemo(
    () =>
      Yup.object().shape({
        firstname: Yup.string().required("Debe ingresar un Nombre"),
        lastname: Yup.string().required("Debe ingresar un Apellido"),
        email: Yup.string()
          .email("Correo invalido")
          .required("Debe ingresar un correo")
          .test("validation", "El email ingresado no es válido", validateEmail),
        password: Yup.string().required("Debe ingresar la contraseña"),
      }),
    [validateEmail]
  );

  const onSuccess = async (userInfo) => {
    setIsGoogleLoading(true);
    try {
      console.log({
        idToken: userInfo.credential,
        accessToken: userInfo.access_token,
      });
      const res = await authService.externalLogin("google", {
        idToken: userInfo.credential,
        accessToken: userInfo.access_token,
      });
      if (!res.data.accessToken || !res.data.refreshToken) {
        setIsGoogleLoading(false);
        alert("Ocurrió un error");
        return;
      }
      await authContext.login(
        res.data.accessToken,
        res.data.refreshToken,
        res.data.userId
      );
      successToast("Sesion iniciada", "Se inicio correctamente con Google.");
      onClose();
    } catch (e: any) {
      const error = e.message;
      errorToast(
        "Error al iniciar",
        "Ocurrió un error al ingresar con Google."
      );
      if (error === "Request failed with status code 400") {
      }
      setIsGoogleLoading(false);
    }
    setIsGoogleLoading(false);
  };

  const signUp = useGoogleLogin({
    onSuccess,
    flow: "implicit",
  });

  const handleSubmit = async (values) => {
    try {
      setIsRegisterLoading(true);
      const res = await authService.register({
        profile: {
          firstname: values.firstname,
          lastname: values.lastname,
        },
        email: values.email,
        password: values.password,
      });
      if (!res.data.accessToken || !res.data.refreshToken) {
        setIsRegisterLoading(false);
        return;
      }
      await authContext.login(
        res.data.accessToken,
        res.data.refreshToken,
        res.data.userId
      );
      successToast("Sesion iniciada", "Su cuenta se creo correctamente.");
      onClose();
    } catch (e: any) {
      if (e.response.status === 409) {
        errorToast(
          "Error al registrar",
          "Los datos corresponden a una cuenta existente."
        );
      } else {
        errorToast(
          "Error al registrar",
          "Ocurrió un error al ingresar con sus credenciales."
        );
      }
    } finally {
      setIsRegisterLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
    },
    validationSchema: SignUpSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  return (
    <ModalContent
      borderRadius={20}
      backgroundColor={"backgroundColor"}
      borderWidth={2}
      borderColor={"secondary.main"}
      p={10}
    >
      <ModalBody justifyContent={"center"}>
        <FormikProvider value={formik}>
          <Stack direction={"column"} spacing={5} justifyContent={"center"}>
            <Stack direction={"column"} spacing={2}>
              <Heading size={"md"} textAlign="center">
                Registrar cuenta
              </Heading>
              <Text textAlign={"center"} fontSize={"sm"}>
                ¿Ya tenes cuenta?,{" "}
                <Link
                  style={{ color: "primary.main" }}
                  onClick={() => setActiveForm("login")}
                >
                  iniciá sesión
                </Link>
              </Text>
            </Stack>
            {!isWebview && (
              <Center w="100%">
                <Button
                  colorScheme={"primary"}
                  borderRadius={4}
                  w="90%"
                  leftIcon={<FaGoogle />}
                  onClick={() => signUp()}
                  isLoading={isGoogleLoading}
                  disabled={isRegisterLoading}
                >
                  Registrarse con Google
                </Button>
              </Center>
            )}
            <Box
              borderWidth={0.5}
              borderColor="gray"
              borderRadius={"full"}
              my={5}
            />
            <Center w="100%" as={Stack} direction="column" spacing={5}>
              <FormControl
                isInvalid={getIn(formik.errors, "firstname")}
                isRequired
                w="90%"
              >
                <Input
                  variant="outline"
                  placeholder="Nombre"
                  type={"text"}
                  name="firstname"
                  autoComplete="register-firstname"
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  borderRadius={4}
                  _autofill={{
                    textFillColor: "white",
                    boxShadow: "transparent",
                    transition: "background-color 5000s ease-in-out 0s",
                  }}
                />
                {getIn(formik.errors, "firstname") ? (
                  <FormErrorMessage fontSize={"x-small"}>
                    {getIn(formik.errors, "firstname")}
                  </FormErrorMessage>
                ) : null}
              </FormControl>
              <FormControl
                isInvalid={getIn(formik.errors, "lastname")}
                isRequired
                w="90%"
              >
                <Input
                  variant="outline"
                  placeholder="Apellido"
                  type={"text"}
                  name="lastname"
                  autoComplete="register-lastname"
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  borderRadius={4}
                  _autofill={{
                    textFillColor: "white",
                    boxShadow: "transparent",
                    transition: "background-color 5000s ease-in-out 0s",
                  }}
                />
                {getIn(formik.errors, "lastname") ? (
                  <FormErrorMessage fontSize={"x-small"}>
                    {getIn(formik.errors, "lastname")}
                  </FormErrorMessage>
                ) : null}
              </FormControl>
              <Field name={"email"}>
                {({ field, form }) => (
                  <FormControl
                    variant="outline"
                    borderRadius={4}
                    id="email"
                    isInvalid={form.errors.email}
                    isRequired
                    w="90%"
                  >
                    <EmailInput
                      field={field}
                      validationState={validationState}
                      form={form}
                      inputProps={{
                        borderColor: "white",
                        placeholder: "Email",
                        borderRadius: 4,
                      }}
                      inputGroupProps={{
                        borderColor: "white",
                      }}
                    />

                    <FormErrorMessage fontSize={"x-small"}>
                      {form.errors.email}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="password">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={getIn(formik.errors, "password")}
                    isRequired
                    w="90%"
                  >
                    <InputGroup variant={"outline"}>
                      <Input
                        borderRadius={4}
                        variant={"outline"}
                        color={"white"}
                        type={show ? "text" : "password"}
                        placeholder="Contraseña"
                        {...field}
                        autoComplete="new-password"
                        onChange={async (e) => {
                          await formik.setFieldValue(
                            field.name,
                            e.target.value
                          );
                          await formik.validateForm(form.values);
                        }}
                        _autofill={{
                          textFillColor: "white",
                          boxShadow: "transparent",
                          transition: "background-color 5000s ease-in-out 0s",
                        }}
                      />
                      <InputRightElement
                        _hover={{
                          cursor: "pointer",
                        }}
                        children={
                          show ? (
                            <FaEye color="white" />
                          ) : (
                            <FaEyeSlash color="white" />
                          )
                        }
                        onClick={() => setShow(!show)}
                      />
                    </InputGroup>
                    {getIn(form.errors, "password") ? (
                      <FormErrorMessage fontSize={"x-small"}>
                        {getIn(form.errors, "password")}
                      </FormErrorMessage>
                    ) : null}
                  </FormControl>
                )}
              </Field>
            </Center>
          </Stack>
        </FormikProvider>
      </ModalBody>
      <ModalFooter justifyContent={"center"}>
        <Stack direction={"column"}>
          <Button
            borderRadius={50}
            colorScheme="primary"
            disabled={
              isGoogleLoading || isRegisterLoading || formik.isValidating
            }
            isLoading={isRegisterLoading || formik.isValidating}
            onClick={() => {
              formik.submitForm();
            }}
          >
            Registrarme
          </Button>
        </Stack>
      </ModalFooter>
    </ModalContent>
  );
};

export default SignUpForm;
