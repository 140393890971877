import { zonedTimeToUtc } from "date-fns-tz";
import { ActualFileObject } from "filepond";
import { useContext } from "react";
import { photosUrl } from "../../config/envConf";
import { AxiosContext } from "../../providers/axiosContext";
import { parseTime, TIME_ZONE } from "../../utils/dateFormatters";
import { parsePrice } from "../../utils/priceFormat";
import { EventDTO } from "../events/dto";
import {
  EventFormStoreProps,
  StoredFormData,
  useEventFormStore,
} from "./createEventStore";
import {
  ImageDTO,
  EventFormDataDTO,
  TicketVariantFormData,
  BankDTO,
} from "./dto";
import { getProducer } from "../../utils/getProducer";

export interface CreateEventService {
  store: EventFormStoreProps;
  postImage: (image: ActualFileObject) => Promise<ImageDTO>;
  postPDF: (pdf: ActualFileObject) => Promise<ImageDTO>;
  createEvent: (data: StoredFormData) => Promise<void>;
  getBanks: () => Promise<BankDTO[]>;
}

const mapFormDataToEventDTO = (data: StoredFormData): EventFormDataDTO => {
  const producer = getProducer();
  return {
    name: data.eventData.name,
    description: data.eventData.description,
    displayType:
      data.images.banner && data.images.bannerMobile ? "carousel" : "list",
    place: {
      name: data.eventData.placeName,
      location: {
        coordinates: data.eventData.place?.geometry.location,
        address: data.eventData.place.formatted_address,
      },
    },
    flayer: { xl: data.images.card },
    images: data.images,
    meta: {
      eventType: data.eventData.type,
      restriction: data.eventData.restriction,
      manager: {
        ...data.organizer,
        paymentMethod: {
          bank: data.organizer.bank,
          paymentType: data.organizer.accountType,
          paymentData: data.organizer.accountNumber,
        },
      },
      category: data.eventData.category,
    },
    functions: data.functions.functions.map((fun) => {
      const datetime = new Date(fun.openingDate.value);
      const endDatetime = new Date(fun.closingDate.value);

      const openingTime = parseTime(fun.openingHour);
      const closingTime = parseTime(fun.closingHour);

      datetime.setHours(openingTime.hours, openingTime.minutes);
      endDatetime.setHours(closingTime.hours, closingTime.minutes);

      return {
        datetime: zonedTimeToUtc(datetime, TIME_ZONE),
        endDatetime: zonedTimeToUtc(endDatetime, TIME_ZONE),
      };
    }),
    tags: [],
    admins: [],
    hasSeats: data.eventData.hasSeats,
    hasQueue: data.eventData.hasQueue,
    producer,
    paymentMethods: data.paymentMethods.methods,
  };
};

export const useCreateEventService = (): CreateEventService => {
  const axiosContext = useContext(AxiosContext);
  const eventFormStore = useEventFormStore();
  const producer = getProducer();

  const postImage = async (image: ActualFileObject) => {
    const formData = new FormData();
    formData.append("files", image);
    const res = await axiosContext.authAxios.post<ImageDTO[]>(
      photosUrl + "/photos/photos",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return res.data[0];
  };

  const postPDF = async (image: ActualFileObject) => {
    const formData = new FormData();
    formData.append("files", image);
    const res = await axiosContext.authAxios.post<ImageDTO>(
      photosUrl + "/photos/pdf",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return res.data;
  };

  const createEvent = async (data: StoredFormData) => {
    const eventForm = mapFormDataToEventDTO(data);
    const event = await axiosContext.authAxios.post<EventDTO>(
      "/events/events",
      eventForm
    );

    const ticketVariants: TicketVariantFormData[] = event.data.functions.reduce(
      (prev, fun) =>
        prev.concat(
          data.tickets.tickets.map((ticket) => {
            const sellStartDatetime = new Date(ticket.openingDate.value);
            const sellFinishDatetime = new Date(ticket.closingDate.value);

            const openingTime = parseTime(ticket.openingHour);
            const closingTime = parseTime(ticket.closingHour);

            sellStartDatetime.setHours(openingTime.hours, openingTime.minutes);
            sellFinishDatetime.setHours(closingTime.hours, closingTime.minutes);
            return {
              name: ticket.name ? ticket.name.toUpperCase() : ticket.name,
              description: ticket.description,
              color: ticket.color,
              available: ticket.amount,
              place: event.data.place,
              event: event.data._id,
              function: fun._id,
              price: parsePrice(ticket.price),
              meta: {
                free: false,
              },
              sellStartDatetime: zonedTimeToUtc(sellStartDatetime, TIME_ZONE),
              sellFinishDatetime: zonedTimeToUtc(sellFinishDatetime, TIME_ZONE),
              maxQuantityPerUser: ticket.maxQuantityPerUser,
              minimumQuantityPerUser: ticket.minimumQuantityPerUser,
              producer,
            };
          })
        ),
      []
    );

    await axiosContext.authAxios.post(
      "/tickets/ticket-variant/multiple",
      ticketVariants
    );
  };

  const getBanks = async () => {
    const res = await axiosContext.publicAxios.get<BankDTO[]>(
      "/transactions/banks"
    );

    return res.data;
  };

  return { store: eventFormStore, postImage, createEvent, getBanks, postPDF };
};
