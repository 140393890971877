import { useDisclosure } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useProfileService } from "../../../services/profile/profileService";
import { formatFullName } from "../../../utils/stringFormatters";
import { ProfileController, profileScreens } from "./interfaces";
import { useToaster } from "../../../utils/useToaster";

export const useProfileController = (): ProfileController => {
  const [params, setParams] = useSearchParams();
  const screen = params.get("screen");

  const [searchParams] = useSearchParams();

  const status = searchParams.get("status");
  const { successToast, errorToast, warningToast } = useToaster();

  useEffect(() => {
    if (status) {
      if (status === "approved" || status === "success") {
        successToast(
          "Felicidades! Compra exitosa",
          "Ya tienes disponibles tus entradas, puedes verlas en la sección Mis tickets"
        );
      } else if (status === "pending") {
        warningToast(
          "Compra pendiente de confirmación",
          `La compra esta siendo procesada. Una vez confirmada se te enviran al correo y podras verla en la sección "Mis Tickets"`
        );
      } else {
        errorToast(
          "Ups! Ha ocurrido un error",
          "Hubo un problema al realizar el pago. Por favor, inténtalo nuevamente"
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const profileService = useProfileService();

  const { data: profileData, execute } = profileService.profileStore;

  useEffect(() => {
    execute(profileService.getProfile);
  }, []);

  const profileName = useMemo(
    () => formatFullName(profileData?.firstname, profileData?.lastname),
    [profileData]
  );

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (screen) {
      setTabIndex(profileScreens.findIndex((s) => s.name === screen));
    }
  }, [screen]);

  const handleTabsChange = (index) => {
    if (index === profileScreens.length) {
      return;
    }
    setParams("screen=" + profileScreens[index].name);
  };

  const {
    isOpen: isLogOutOpen,
    onOpen: onLogOutOpen,
    onClose: onLogOutClose,
  } = useDisclosure();

  return {
    tabIndex,
    handleTabsChange,
    profileName,
    isLogOutOpen,
    onLogOutOpen,
    onLogOutClose,
  };
};
