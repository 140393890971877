import React from "react";
import { CheckIcon } from "@chakra-ui/icons";
import {
  InputGroup,
  Input,
  InputRightElement,
  Spinner,
  InputGroupProps,
  InputProps,
  FormLabel,
} from "@chakra-ui/react";
import { useColors } from "../../utils/useColors";

interface EmailInputProps {
  /**
   * `field` object of the children props of the `Field` Formik component.
   */
  field: any;

  /**
   * `form` object of the children props of the `Field` Formik component.
   */
  form: any;

  /**
   * Chaka UI Input Group component props for this custom component.
   */
  inputGroupProps?: InputGroupProps;

  /**
   * Chaka UI Input component props for this custom component.
   */
  inputProps?: InputProps;

  /**
   * Label for FormLabel component.
   */
  label?: string;

  validationState?: "pending" | "success" | "loading";

  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}
/**
 * Custom Chakra UI Input for email validation.
 */

export const EmailInput: React.FC<EmailInputProps> = ({
  field,
  form,
  inputProps,
  inputGroupProps,
  label,
  onBlur,
  ...props
}) => {
  const colors = useColors();
  return (
    <InputGroup {...inputGroupProps}>
      <Input
        placeholder=" "
        {...field}
        {...inputProps}
        value={field.value}
        type={"email"}
        onBlur={onBlur}
        sx={{
          "-webkit-text-fill-color": `${colors.backgroundContrast} !important`,
        }}
      />
      {label && <FormLabel>{label}</FormLabel>}
      {field.value && props.validationState !== "pending" && (
        <InputRightElement
          children={
            props.validationState === "loading" ? (
              <Spinner />
            ) : (
              <CheckIcon color="green.500" />
            )
          }
        />
      )}
    </InputGroup>
  );
};
