import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateEventService } from "../../../services/createEvent/createEventService";
import { EventStepperFormController } from "./interfaces";
import {
  useEventDataFormik,
  useFunctionsFormik,
  useImagesFormik,
  useOrganizerFormik,
  usePaymentsFormik,
  useTicketsFormik,
} from "./formiks";

export const useEventStepperFormController = (): EventStepperFormController => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(0);

  const createEventService = useCreateEventService();
  const { formData, mode } = createEventService.store;

  const onBackButtonPress = () => {
    navigate(-1);
  };

  const {
    isOpen: isSuccessOpen,
    onOpen: onSuccessOpen,
    onClose: onSuccessClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const onFinish = () => {
    onSuccessClose();
    navigate("/");
  };

  const [submitLoading, setSubmitLoading] = useState(false);

  const submitEvent = async () => {
    setSubmitLoading(true);
    const forms = [
      eventDataProps.formik,
      functionsProps.formik,
      imagesProps.formik,
      organizerProps.formik,
      ticketsProps.formik,
      paymentMethodsProps.formik,
    ];
    const results = await Promise.all(
      forms.map(async (form) => form.validateForm())
    );

    if (results.every((result) => Object.keys(result).length === 0)) {
      const data = formData;
      const {
        tickets,
        functions,
        images,
        eventData,
        organizer,
        paymentMethods,
      } = data;
      if (
        !(
          tickets &&
          functions &&
          images &&
          eventData &&
          organizer &&
          paymentMethods
        )
      ) {
        onErrorOpen();
      } else {
        try {
          await createEventService.createEvent(data);
          onSuccessOpen();
        } catch {
          onErrorOpen();
        }
      }
    }
    setSubmitLoading(false);
  };

  const eventDataProps = useEventDataFormik();
  const functionsProps = useFunctionsFormik();
  const imagesProps = useImagesFormik();
  const organizerProps = useOrganizerFormik();
  const ticketsProps = useTicketsFormik();
  const paymentMethodsProps = usePaymentsFormik();

  return {
    mode,
    onStepPress: setCurrentStep,
    submitEvent,
    onBackButtonPress,
    currentStep,
    submitLoading,

    isSuccessOpen,
    isErrorOpen,
    onSuccessOpen,
    onErrorOpen,
    onSuccessClose: onFinish,
    onErrorClose,

    eventDataProps,
    functionsProps,
    imagesProps,
    organizerProps,
    ticketsProps,
    paymentMethodsProps,
  };
};
