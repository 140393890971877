import React, { useState } from "react";
import { GuestTicketsProps } from "./interfaces";
import { useGuestTicketsController } from "./guest-tickets.controller";
import Card from "../../../../components/Card";
import { Box, Button, HStack, Stack, Text } from "@chakra-ui/react";
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  Icon,
  InfoOutlineIcon,
} from "@chakra-ui/icons";
import { FaUser, FaUsers } from "react-icons/fa";
import { SingleInvitation } from "./components/SingleInvitation";
import { MassiveInvitations } from "./components/MassiveInvitations";
import { SingleInvitationSeats } from "./components/SingleInvitationSeats";
import { Reservations } from "./components/Reservation";
import { SeatsReservations } from "./components/SeatsReservation";
import { TicketIcon } from "../../../../components/Icons/TicketIcon";
import { useColors } from "../../../../utils/useColors";

export const GuestTickets: React.FC<GuestTicketsProps> = ({
  useController = useGuestTicketsController,
}) => {
  const controller = useController();

  const [mode, setMode] = useState<
    "none" | "single" | "massive" | "reservations"
  >("none");

  const colors = useColors();

  return (
    <Card px={[5, 5, 20]} py={10} color={"secondary.contrast"}>
      <Text fontWeight={500} fontSize={20} mb={5}>
        TICKETS DE CORTESÍA
      </Text>
      {mode === "none" ? (
        <>
          <Box display={"flex"}>
            <InfoOutlineIcon mr={2} mt={1} />
            <Text flexGrow={1} fontSize={16} color={"muted.500"}>
              Existen dos formas de enviar cortesías: de manera manual e
              individual o por medio de un documento en formato Excel y de forma
              masiva. Si decide enviar los tickets de manera
              <b> individual</b>, deberá seleccionar esa opción y completar la
              información necesaria para generar el ticket de cortesía. En caso
              que decida enviar los tickets de forma <b>masiva</b> por medio de
              un documento en formato Excel, en el que incluya la información
              necesaria para generar los tickets.
            </Text>
          </Box>
          <Box
            display={"flex"}
            flexDir={["column", "column", "row"]}
            justifyContent={"space-between"}
            mt={16}
          >
            <Button
              flexGrow={1}
              colorScheme={"white"}
              _hover={{
                borderColor: "primary.main",
              }}
              variant="outline"
              flexDir={"row"}
              h={"max-content"}
              alignItems={"start"}
              justifyContent={"space-between"}
              py={4}
              onClick={() => setMode("single")}
            >
              <Stack>
                <HStack>
                  <FaUser fontSize={20} color={colors.primary.main} />
                  <Text fontWeight={600}>INDIVIDUAL</Text>
                </HStack>
                <Text>Enviar invitación</Text>
              </Stack>
              <Box alignSelf={"end"}>
                <ArrowForwardIcon fontSize={20} />
              </Box>
            </Button>
            <Box w={[5, 5, 20]} />
            <Button
              flexGrow={1}
              colorScheme={"white"}
              _hover={{
                borderColor: "primary.main",
              }}
              variant="outline"
              flexDir={"row"}
              h={"max-content"}
              alignItems={"start"}
              justifyContent={"space-between"}
              py={4}
              disabled
              onClick={() => setMode("massive")}
            >
              <Stack>
                <HStack>
                  <FaUsers fontSize={20} color={colors.primary.main} />
                  <Text fontWeight={600}>MASIVA</Text>
                </HStack>
                <Text>Próximamente</Text>
              </Stack>
              <Box alignSelf={"end"}>
                <ArrowForwardIcon fontSize={20} />
              </Box>
            </Button>
            <Box w={[5, 5, 20]} />
            <Button
              flexGrow={1}
              colorScheme={"white"}
              _hover={{
                borderColor: "primary.main",
              }}
              variant="outline"
              flexDir={"row"}
              h={"max-content"}
              alignItems={"start"}
              justifyContent={"space-between"}
              py={4}
              onClick={() => setMode("reservations")}
            >
              <Stack>
                <HStack>
                  <TicketIcon fontSize={20} color={"primary.main"} />
                  <Text fontWeight={600}>RESERVAR</Text>
                </HStack>
                <Text>Seleccionar tickets</Text>
              </Stack>
              <Box alignSelf={"end"}>
                <ArrowForwardIcon fontSize={20} />
              </Box>
            </Button>
          </Box>
        </>
      ) : mode === "single" ? (
        <>
          <Text mb={5}>
            <ArrowBackIcon
              mb={0.5}
              mr={2}
              cursor={"pointer"}
              transition={"opacity 200ms"}
              _hover={{ opacity: 0.3 }}
              aria-label="Call Segun"
              bgColor={"muted.main"}
              rounded={"full"}
              alignSelf="self-start"
              color="black"
              // boxSize={"1rem"}
              onClick={() => setMode("none")}
            />
            Enviar invitación individual
          </Text>

          {controller.activeFunction?.eventFunctionChartKey ? (
            <SingleInvitationSeats
              {...controller}
              eventFunctionChartKey={
                controller.activeFunction.eventFunctionChartKey
              }
              activeFunctionId={controller.activeFunction.value}
            />
          ) : (
            <SingleInvitation
              {...controller}
              activeFunctionId={controller.activeFunction.value}
            />
          )}
        </>
      ) : mode === "massive" ? (
        <>
          <Text mb={5}>
            <ArrowBackIcon
              mb={0.5}
              mr={2}
              cursor={"pointer"}
              transition={"opacity 200ms"}
              _hover={{ opacity: 0.3 }}
              aria-label="Call Segun"
              bgColor={"muted.main"}
              rounded={"full"}
              alignSelf="self-start"
              color="black"
              // boxSize={"1rem"}
              onClick={() => setMode("none")}
            />
            Enviar invitación masiva
          </Text>
          <MassiveInvitations
            {...controller}
            eventFunctionChartKey={
              controller.activeFunction.eventFunctionChartKey
            }
          />
        </>
      ) : (
        <>
          <Text mb={5}>
            <ArrowBackIcon
              mb={0.5}
              mr={2}
              cursor={"pointer"}
              transition={"opacity 200ms"}
              _hover={{ opacity: 0.3 }}
              aria-label="Call Segun"
              bgColor={"muted.main"}
              rounded={"full"}
              alignSelf="self-start"
              color="black"
              // boxSize={"1rem"}
              onClick={() => setMode("none")}
            />
            Reservar tickets
          </Text>
          {controller.activeFunction?.eventFunctionChartKey ? (
            <SeatsReservations
              {...controller}
              activeFunctionId={controller.activeFunction.value}
              eventFunctionChartKey={
                controller.activeFunction.eventFunctionChartKey
              }
            />
          ) : (
            <Reservations
              {...controller}
              activeFunctionId={controller.activeFunction.value}
            />
          )}
        </>
      )}
    </Card>
  );
};
